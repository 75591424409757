<template>
  <TransitionRoot as="template" :show="open && managedUsr !== undefined && managedUsr !== null">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div :class="['transition-all duration-200 pointer-events-auto w-screen', !this.displayDiscounts ? 'max-w-md' : 'max-w-6xl']">
              <form class="flex h-full flex-col divide-y divide-slate-700 bg-slate-600 shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-slate-900 to-slate-800 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white"> Zarządzaj użytkownikiem</DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Zmień dane użytkownika {{`${managedUsr.name} ${managedUsr.surname}`}}. </p>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col divide-y divide-slate-800 justify-between" v-if="!managedUsr.permissions.admin">
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 text-slate-200 font-medium">Dane logowania </div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-slate-100">E-mail <span></span></label>
                            <div class="mt-1 text-slate-400">
                              <span>{{managedUsr.contact.email}}</span>
                            </div>
                        </div>
                        <div class="select-none mt-2">
                            <label for="email" class="block text-sm font-medium text-slate-100">Hasło<span></span></label>
                            <div class="mt-1 text-slate-500">
                              <button type="button" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click.prevent="resetPwd">Zresetuj hasło</button>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-slate-200">Dane podstawowe użytkownika</div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-slate-100">Imię<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <input type="text" v-model="userUpdate.name" maxlength="20"  class="block w-full rounded-md border-slate-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Jan" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.name"> Pole nie może być puste.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-slate-100">Nazwisko<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="userUpdate.surname" class="block w-full rounded-md border-slate-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Kowalski" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.surname"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-slate-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="email" class="block text-sm font-medium text-slate-100">Telefon komórkowy<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <input v-model="userUpdate.phone" maxlength="12" type="text" class="block w-full rounded-md border-slate-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Numer telefonu z kierunkowym" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.phone"> Poprawny format to +XXXXXXXXXXX.</span>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Dane firmy</div>
                        <div class="select-none">
                            <label for="company-name" class="block text-sm font-medium text-slate-100">Nazwa firmy<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <input type="text" maxlength="100" name="company-name" id="company-name" v-model="userUpdate.company.name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. WB Tank Sp. z o.o." />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.name"> Pole nie może być puste.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-slate-100">NIP<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="userUpdate.company.nip" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. 8421761290" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.nip"> Poprawny format to XXXXXXXXXX.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="company-address" class="block text-sm font-medium text-slate-100">Adres<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <input type="text" maxlength="40" name="company-address" id="company-address" v-model="userUpdate.company.address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. Bytów 93" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.address"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="post-code" class="block text-sm font-medium text-slate-100">Kod pocztowy<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <input type="text" id="post-code" name="post-code" maxlength="20" v-model="userUpdate.company.postCode" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. 77-100" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.postCode"> Poprawny format to XX-XXX.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="city" class="block text-sm font-medium text-slate-100">Miejscowość<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <input type="text" id="city" name="city" maxlength="40" v-model="userUpdate.company.city" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. Bytów" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.company.city"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Grupa kart</div>
                        <div class="select-none">
                            <label for="groupOfCard" class="block text-sm font-medium text-slate-100">Wybierz grupę kart<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <select @change="setGroup(groupToSet)" v-model="groupToSet" placeholder="---" id="groupOfCard" name="groupOfCard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                  <option v-for="group in groupsOfCards" :key="group.groupName" :value="group">{{group.groupName}} ({{group.groupId}})</option>
                                </select>
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.groupOfCards"> Musisz wybrać grupę kart.</span>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Rabaty</div>
                        <button @click.prevent="displayDiscounts = !displayDiscounts" class="rounded-md mb-4 border border-slate-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">{{displayDiscounts ? 'Schowaj' :'Pokaż'}}</button>
                        <div v-if="displayDiscounts" class="grid grid-cols-4">
                          <!-- Stacja wbtank -->
                          <div class="border pb-4 border-slate-400">
                            <div class="text-slate-100 font-medium text-base border-b border-slate-400 flex justify-center py-2">Stacja WBtank</div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselWbtank" class="block text-xs font-medium text-slate-200">Shell FuelSave Diesel<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <!-- <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.diesel.wbtank[0].amount" id="discountDieselWbtank" name="discountDieselWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">ORLEN +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="0">ORLEN 0%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">ORLEN -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        <span :class="['text-xs', userUpdate.discounts.diesel.wbtank[0].amount !== null && userUpdate.discounts.diesel.wbtank[0].amount !== false ? 'text-red-500' : 'text-slate-600']">
                                          {{userUpdate.discounts.diesel.wbtank[0].amount !== null && userUpdate.discounts.diesel.wbtank[0].amount !== false ? 'ORLEN' : 'SŁUP'}}
                                        </span>
                                      </span>
                                    </div>
                                  </div> -->
                                  <div class="flex">
                                    <div class="w-7/12 relative">
                                      <select v-model="userUpdate.discounts.diesel.wbtank[0].amount" id="discountDieselWbtank" name="discountDieselWbtank" class="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option v-for="discount in 15" :key="discount" :value="discount/100">+{{(discount/100).toFixed(2)}} PLN</option>
                                        <option :value="'5%'">+5%</option>
                                        <option :value="'4.5%'">+4.5%</option>
                                        <option :value="'4%'">+4%</option>
                                        <option :value="'3.5%'">+3.5%</option>
                                        <option :value="'3%'">+3%</option>
                                        <option :value="'2.5%'">+2.5%</option>
                                        <option :value="'2%'">+2%</option>
                                        <option :value="'1.5%'">+1.5%</option>
                                        <option :value="'1%'">+1%</option>
                                        <option :value="'0.5%'">+0.5%</option>
                                        <option :value="false">0%</option>
                                        <option :value="'-0.5%'">-0.5%</option>
                                        <option :value="'-1%'">-1%</option>
                                        <option :value="'-1.5%'">-1.5%</option>
                                        <option :value="'-2%'">-2%</option>
                                        <option :value="'-2.5%'">-2.5%</option>
                                        <option :value="'-3%'">-3%</option>
                                        <option :value="'-3.5%'">-3.5%</option>
                                        <option :value="'-4%'">-4%</option>
                                        <option :value="'-4.5%'">-4.5%</option>
                                        <option :value="'-5%'">-5%</option>
                                        <option v-for="discount in 40" :key="discount" :value="-(discount/100)">-{{(discount/100).toFixed(2)}} PLN</option>
                                      </select>
                                    </div>
                                    <div class="w-5/12">
                                      <select v-model="userUpdate.discounts.diesel.wbtank[0].orlenSpot" id="discountDieselWbtank" name="discountDieselWbtank" class="block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option :value="false">SŁUP</option>
                                        <option :value="true">ORLEN</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselVPowerWbtank" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ Diesel<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.dieselVPower.wbtank[0].amount" id="discountDieselVPowerWbtank" name="discountDieselVPowerWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountAdBlueWbtank" class="block text-xs font-medium text-slate-200">AdBlue<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.adBlue.wbtank[0].amount" id="discountAdBlueWbtank" name="discountAdBlueWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 391" :key="discount" :value="(discount+9)/100">SŁUP -{{((discount+9)/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95Wbtank" class="block text-xs font-medium text-slate-200">Shell FuelSave 95<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol95.wbtank[0].amount" id="discountPetrol95Wbtank" name="discountPetrol95Wbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95VPowerWbtank" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ 95 AL<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol95VPower.wbtank[0].amount" id="discountPetrol95VPowerWbtank" name="discountPetrol95VPowerWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol100RacingWbtank" class="block text-xs font-medium text-slate-200">Shell Racing 100<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol100Racing.wbtank[0].amount" id="discountPetrol100RacingWbtank" name="discountPetrol100RacingWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountLpgWbtank" class="block text-xs font-medium text-slate-200">LPG<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.lpg.wbtank[0].amount" id="discountLpgWbtank" name="discountLpgWbtank" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <!-- Stacja ekonomiczna -->
                          <div class="border pb-4 border-slate-400">
                            <div class="text-slate-100 font-medium text-base border-b border-slate-400 flex justify-center py-2">Stacja ekonomiczna</div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselEconomy" class="block text-xs font-medium text-slate-200">Shell FuelSave Diesel<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <!-- <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.diesel.economy[0].amount" id="discountDieselEconomy" name="discountDieselEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">ORLEN +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="0">ORLEN 0%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">ORLEN -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        <span :class="['text-xs', userUpdate.discounts.diesel.economy[0].amount !== null && userUpdate.discounts.diesel.economy[0].amount !== false ? 'text-red-500' : 'text-slate-600']">
                                          {{userUpdate.discounts.diesel.economy[0].amount !== null && userUpdate.discounts.diesel.economy[0].amount !== false ? 'ORLEN' : 'SŁUP'}}
                                        </span>
                                      </span>
                                    </div>
                                  </div> -->
                                  <div class="flex">
                                    <div class="w-7/12 relative">
                                      <select v-model="userUpdate.discounts.diesel.economy[0].amount" id="discountDieselEconomy" name="discountDieselEconomy" class="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option v-for="discount in 15" :key="discount" :value="discount/100">+{{(discount/100).toFixed(2)}} PLN</option>
                                        <option :value="'5%'">+5%</option>
                                        <option :value="'4.5%'">+4.5%</option>
                                        <option :value="'4%'">+4%</option>
                                        <option :value="'3.5%'">+3.5%</option>
                                        <option :value="'3%'">+3%</option>
                                        <option :value="'2.5%'">+2.5%</option>
                                        <option :value="'2%'">+2%</option>
                                        <option :value="'1.5%'">+1.5%</option>
                                        <option :value="'1%'">+1%</option>
                                        <option :value="'0.5%'">+0.5%</option>
                                        <option :value="false">0%</option>
                                        <option :value="'-0.5%'">-0.5%</option>
                                        <option :value="'-1%'">-1%</option>
                                        <option :value="'-1.5%'">-1.5%</option>
                                        <option :value="'-2%'">-2%</option>
                                        <option :value="'-2.5%'">-2.5%</option>
                                        <option :value="'-3%'">-3%</option>
                                        <option :value="'-3.5%'">-3.5%</option>
                                        <option :value="'-4%'">-4%</option>
                                        <option :value="'-4.5%'">-4.5%</option>
                                        <option :value="'-5%'">-5%</option>
                                        <option v-for="discount in 40" :key="discount" :value="-(discount/100)">-{{(discount/100).toFixed(2)}} PLN</option>
                                      </select>
                                    </div>
                                    <div class="w-5/12">
                                      <select v-model="userUpdate.discounts.diesel.economy[0].orlenSpot" id="discountDieselEconomy" name="discountDieselEconomy" class="block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option :value="false">SŁUP</option>
                                        <option :value="true">ORLEN</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselVPowerEconomy" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ Diesel<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.dieselVPower.economy[0].amount" id="discountDieselVPowerEconomy" name="discountDieselVPowerEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountAdBlueEconomy" class="block text-xs font-medium text-slate-200">AdBlue<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.adBlue.economy[0].amount" id="discountAdBlueEconomy" name="discountAdBlueEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 391" :key="discount" :value="(discount+9)/100">SŁUP -{{((discount+9)/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95Economy" class="block text-xs font-medium text-slate-200">Shell FuelSave 95<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol95.economy[0].amount" id="discountPetrol95Economy" name="discountPetrol95Economy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95VPowerEconomy" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ 95 AL<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol95VPower.economy[0].amount" id="discountPetrol95VPowerEconomy" name="discountPetrol95VPowerEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol100RacingEconomy" class="block text-xs font-medium text-slate-200">Shell Racing 100<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol100Racing.economy[0].amount" id="discountPetrol100RacingEconomy" name="discountPetrol100RacingEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountLpgEconomy" class="block text-xs font-medium text-slate-200">LPG<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.lpg.economy[0].amount" id="discountLpgEconomy" name="discountLpgEconomy" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <!-- Stacja standardowa -->
                          <div class="border pb-4 border-slate-400">
                            <div class="text-slate-100 font-medium text-base border-b border-slate-400 flex justify-center py-2">Stacja standardowa</div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselStandard" class="block text-xs font-medium text-slate-200">Shell FuelSave Diesel<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <!-- <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.diesel.standard[0].amount" id="discountDieselStandard" name="discountDieselStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">ORLEN +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="0">ORLEN 0%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">ORLEN -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        <span :class="['text-xs', userUpdate.discounts.diesel.standard[0].amount !== null && userUpdate.discounts.diesel.standard[0].amount !== false ? 'text-red-500' : 'text-slate-600']">
                                          {{userUpdate.discounts.diesel.standard[0].amount !== null && userUpdate.discounts.diesel.standard[0].amount !== false ? 'ORLEN' : 'SŁUP'}}
                                        </span>
                                      </span>
                                    </div>
                                  </div> -->
                                  <div class="flex">
                                    <div class="w-7/12 relative">
                                      <select v-model="userUpdate.discounts.diesel.standard[0].amount" id="discountDieselStandard" name="discountDieselStandard" class="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option v-for="discount in 15" :key="discount" :value="discount/100">+{{(discount/100).toFixed(2)}} PLN</option>
                                        <option :value="'5%'">+5%</option>
                                        <option :value="'4.5%'">+4.5%</option>
                                        <option :value="'4%'">+4%</option>
                                        <option :value="'3.5%'">+3.5%</option>
                                        <option :value="'3%'">+3%</option>
                                        <option :value="'2.5%'">+2.5%</option>
                                        <option :value="'2%'">+2%</option>
                                        <option :value="'1.5%'">+1.5%</option>
                                        <option :value="'1%'">+1%</option>
                                        <option :value="'0.5%'">+0.5%</option>
                                        <option :value="false">0%</option>
                                        <option :value="'-0.5%'">-0.5%</option>
                                        <option :value="'-1%'">-1%</option>
                                        <option :value="'-1.5%'">-1.5%</option>
                                        <option :value="'-2%'">-2%</option>
                                        <option :value="'-2.5%'">-2.5%</option>
                                        <option :value="'-3%'">-3%</option>
                                        <option :value="'-3.5%'">-3.5%</option>
                                        <option :value="'-4%'">-4%</option>
                                        <option :value="'-4.5%'">-4.5%</option>
                                        <option :value="'-5%'">-5%</option>
                                        <option v-for="discount in 40" :key="discount" :value="-(discount/100)">-{{(discount/100).toFixed(2)}} PLN</option>
                                      </select>
                                    </div>
                                    <div class="w-5/12">
                                      <select v-model="userUpdate.discounts.diesel.standard[0].orlenSpot" id="discountDieselStandard" name="discountDieselStandard" class="block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option :value="false">SŁUP</option>
                                        <option :value="true">ORLEN</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselVPowerStandard" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ Diesel<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.dieselVPower.standard[0].amount" id="discountDieselVPowerStandard" name="discountDieselVPowerStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountAdBlueStandard" class="block text-xs font-medium text-slate-200">AdBlue<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.adBlue.standard[0].amount" id="discountAdBlueStandard" name="discountAdBlueStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 391" :key="discount" :value="(discount+9)/100">SŁUP -{{((discount+9)/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95Standard" class="block text-xs font-medium text-slate-200">Shell FuelSave 95<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol95.standard[0].amount" id="discountPetrol95Standard" name="discountPetrol95Standard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95VPowerStandard" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ 95 AL<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol95VPower.standard[0].amount" id="discountPetrol95VPowerStandard" name="discountPetrol95VPowerStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol100RacingStandard" class="block text-xs font-medium text-slate-200">Shell Racing 100<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol100Racing.standard[0].amount" id="discountPetrol100RacingStandard" name="discountPetrol100RacingStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountLpgStandard" class="block text-xs font-medium text-slate-200">LPG<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.lpg.standard[0].amount" id="discountLpgStandard" name="discountLpgStandard" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <!-- Stacja przy autostardzie -->
                          <div class="border pb-4 border-slate-400 select-none">
                            <div class="text-slate-100 font-medium text-base border-b border-slate-400 flex justify-center py-2">Stacja przy autostradzie</div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselHighway" class="block text-xs font-medium text-slate-200">Shell FuelSave Diesel<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <!-- <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.diesel.highway[0].amount" id="discountDieselHighway" name="discountDieselHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">ORLEN +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="0">ORLEN 0%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">ORLEN -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        <span :class="['text-xs', userUpdate.discounts.diesel.highway[0].amount !== null && userUpdate.discounts.diesel.highway[0].amount !== false ? 'text-red-500' : 'text-slate-600']">
                                          {{userUpdate.discounts.diesel.highway[0].amount !== null && userUpdate.discounts.diesel.highway[0].amount !== false ? 'ORLEN' : 'SŁUP'}}
                                        </span>
                                      </span>
                                    </div>
                                  </div> -->
                                  <div class="flex">
                                    <div class="w-7/12 relative">
                                      <select v-model="userUpdate.discounts.diesel.highway[0].amount" id="discountDieselHighway" name="discountDieselHighway" class="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option v-for="discount in 15" :key="discount" :value="discount/100">+{{(discount/100).toFixed(2)}} PLN</option>
                                        <option :value="'5%'">+5%</option>
                                        <option :value="'4.5%'">+4.5%</option>
                                        <option :value="'4%'">+4%</option>
                                        <option :value="'3.5%'">+3.5%</option>
                                        <option :value="'3%'">+3%</option>
                                        <option :value="'2.5%'">+2.5%</option>
                                        <option :value="'2%'">+2%</option>
                                        <option :value="'1.5%'">+1.5%</option>
                                        <option :value="'1%'">+1%</option>
                                        <option :value="'0.5%'">+0.5%</option>
                                        <option :value="false">0%</option>
                                        <option :value="'-0.5%'">-0.5%</option>
                                        <option :value="'-1%'">-1%</option>
                                        <option :value="'-1.5%'">-1.5%</option>
                                        <option :value="'-2%'">-2%</option>
                                        <option :value="'-2.5%'">-2.5%</option>
                                        <option :value="'-3%'">-3%</option>
                                        <option :value="'-3.5%'">-3.5%</option>
                                        <option :value="'-4%'">-4%</option>
                                        <option :value="'-4.5%'">-4.5%</option>
                                        <option :value="'-5%'">-5%</option>
                                        <option v-for="discount in 40" :key="discount" :value="-(discount/100)">-{{(discount/100).toFixed(2)}} PLN</option>
                                      </select>
                                    </div>
                                    <div class="w-5/12">
                                      <select v-model="userUpdate.discounts.diesel.highway[0].orlenSpot" id="discountDieselHighway" name="discountDieselHighway" class="block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                        <option :value="false">SŁUP</option>
                                        <option :value="true">ORLEN</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountDieselVPowerHighway" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ Diesel<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.dieselVPower.highway[0].amount" id="discountDieselVPowerHighway" name="discountDieselVPowerHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountAdBlueHighway" class="block text-xs font-medium text-slate-200">AdBlue<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.adBlue.highway[0].amount" id="discountAdBlueHighway" name="discountAdBlueHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 391" :key="discount" :value="(discount+9)/100">SŁUP -{{((discount+9)/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95Highway" class="block text-xs font-medium text-slate-200">Shell FuelSave 95<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol95.highway[0].amount" id="discountPetrol95Highway" name="discountPetrol95Highway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol95VPowerHighway" class="block text-xs font-medium text-slate-200">Shell V-Power Nitro+ 95 AL<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol95VPower.highway[0].amount" id="discountPetrol95VPowerHighway" name="discountPetrol95VPowerHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountPetrol100RacingHighway" class="block text-xs font-medium text-slate-200">Shell Racing 100<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.petrol100Racing.highway[0].amount" id="discountPetrol100RacingHighway" name="discountPetrol100RacingHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="px-4 pt-3">
                              <label for="discountLpgHighway" class="block text-xs font-medium text-slate-200">LPG<span class="text-red-500">*</span></label>
                              <div>
                                  <div class="mt-1 relative">
                                    <select v-model="userUpdate.discounts.lpg.highway[0].amount" id="discountLpgHighway" name="discountLpgHighway" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                      <option v-for="discount in 15" :key="discount" :value="discount/100">SŁUP +{{(discount/100).toFixed(2)}} PLN</option>
                                      <option :value="'5%'">SŁUP +5%</option>
                                      <option :value="'4.5%'">SŁUP +4.5%</option>
                                      <option :value="'4%'">SŁUP +4%</option>
                                      <option :value="'3.5%'">SŁUP +3.5%</option>
                                      <option :value="'3%'">SŁUP +3%</option>
                                      <option :value="'2.5%'">SŁUP +2.5%</option>
                                      <option :value="'2%'">SŁUP +2%</option>
                                      <option :value="'1.5%'">SŁUP +1.5%</option>
                                      <option :value="'1%'">SŁUP +1%</option>
                                      <option :value="'0.5%'">SŁUP +0.5%</option>
                                      <option :value="false">SŁUP 0%</option>
                                      <option :value="'-0.5%'">SŁUP -0.5%</option>
                                      <option :value="'-1%'">SŁUP -1%</option>
                                      <option :value="'-1.5%'">SŁUP -1.5%</option>
                                      <option :value="'-2%'">SŁUP -2%</option>
                                      <option :value="'-2.5%'">SŁUP -2.5%</option>
                                      <option :value="'-3%'">SŁUP -3%</option>
                                      <option :value="'-3.5%'">SŁUP -3.5%</option>
                                      <option :value="'-4%'">SŁUP -4%</option>
                                      <option :value="'-4.5%'">SŁUP -4.5%</option>
                                      <option :value="'-5%'">SŁUP -5%</option>
                                      <option v-for="discount in 40" :key="discount" :value="-(discount/100)">SŁUP -{{(discount/100).toFixed(2)}} PLN</option>
                                    </select>
                                    <div class="absolute top-1/2 right-8 transform -translate-y-1/2 pointer-events-none">
                                      <span class="text-xxs text-slate-400">
                                        
                                        <span class="text-xs text-slate-600">
                                          SŁUP
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Czas płatności faktury</div>
                        <div class="select-none">
                            <label for="invoicePaymentTime" class="block text-sm font-medium text-slate-100">Wybierz czas płatności faktury<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <select v-model="userUpdate.invoicePaymentTime" placeholder="---" id="invoicePaymentTime" name="invoicePaymentTime" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                  <option v-for="days in 40" :key="days" :value="days">{{days}} dni</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Wysyłka faktur</div>
                        <div class="select-none">
                            <label for="invoiceEmail" class="block text-sm font-medium text-slate-100">Adres e-mail do wysyłki faktur</label>
                            <div class="mt-1">
                                <input type="text" id="invoiceEmail" name="invoiceEmail" maxlength="35" v-model="userUpdate.invoiceEmail" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. faktury@gmail.com" />
                            </div>
                            <span class="block text-xs text-slate-100 mt-1"><span class="text-red-500">*</span>Pole nieobowiązkowe, uzupełnij w przypadku innego adresu.</span>
                            <span class="text-xs select-none text-red-500" v-if="errors.invoiceEmail"> Sprawdź poprawność pola, bądź zostawe je puste.</span>
                            <span class="text-xs select-none text-red-500" v-if="errors.invoiceEmailRepeat"> Adres musi być inny od podanego adresu logowania.</span>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Tryb fakturowania</div>
                        <div class="select-none">
                            <label for="invoicePaymentTime" class="block text-sm font-medium text-slate-100">Wybierz tryb fakturowania<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <select v-model="userUpdate.activeInvoices" placeholder="---" id="invoicePaymentTime" name="invoicePaymentTime" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                  <option :value="false">TESTOWE</option>
                                  <option :value="true">AKTYWNE</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- <div v-if="!this.newUser.worker" class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Ilość faktur w miesiącu</div>
                        <div class="select-none">
                            <label for="amountOfInvoicesInMonth" class="block text-sm font-medium text-slate-100">Wybierz ilość*</label>
                            <div class="mt-1">
                                <select v-model="newUser.amountOfInvoicesInMonth" placeholder="---" id="amountOfInvoicesInMonth" name="amountOfInvoicesInMonth" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                  <option :value="1">1</option>
                                  <option :value="2">2</option>
                                  <option :value="4">4</option>
                                </select>
                            </div>
                        </div>
                    </div> -->
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Ilość faktur w miesiącu</div>
                        <div class="select-none">
                            <label for="amountOfInvoicesInMonth" class="block text-sm font-medium text-slate-100">Wybierz ilość<span class="text-red-500">*</span></label>
                            <div class="mt-1">
                                <select v-model="userUpdate.amountOfInvoicesInMonth" placeholder="---" id="amountOfInvoicesInMonth" name="amountOfInvoicesInMonth" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                                  <option :value="1">1</option>
                                  <option :value="2">2</option>
                                  <option :value="4">4</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-gray-200">Umowa</div>
                        <div class="select-none">
                            <label for="pickedDateOfAgreement" class="block text-sm font-medium text-slate-100">Wybierz datę zakończenia ostatniej umowy</label>
                            <div class="mt-1">
                                <input type="date" @change="pickDateOfAgreement($event)" v-model="userUpdate.agreement.pickedDateOfAgreement" ref="pickedDateOfAgreement"  name="pickedDateOfAgreement" id="pickedDateOfAgreement" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" />
                            </div>
                        </div>
                        <div v-if="pdfOption === 'upload'" class="mt-2 select-none" ref="PDF">
                          <label :class="{'block text-sm font-medium text-gray-200 mb-2': true}">Umowa w wersji PDF (do 6MB)</label>
                          <div class="flex items-center text-slate-100" v-if="pdfHolder[0] === undefined || pdfHolder[0].length === 0">
                            <DocumentIcon :class="{'h-4 w-4 mr-1 opacity-40': true}" aria-hidden="true" />
                            <span :class="{'text-xs opacity-40': true}">Plik PDF nie został wybrany..</span>
                            <span :class="['transition ease-in-out duration-300 text-xs ml-4 text-blue-500 underline hover:text-blue-700 cursor-pointer']" @click.prevent="triggerPDFUploaderClick(`pdfUpldr`)">Załącz plik</span>
                          </div>
                          <div class="" v-for="(pdf,pdfIndx) in pdfHolder[0]" :key="pdfIndx">
                            <div class="flex items-center">
                              <DocumentIcon class="h-4 w-4 text-slate-100 mr-1 opacity-60" aria-hidden="true" />
                              <span class="text-xs text-slate-100 opacity-60">{{pdf.file.name.substring(0,40)}} {{pdf.file.name.length > 40 ? '...' : ''}}</span>
                              <!-- <span @click.prevent="log(pdf.file)">xd</span> -->
                              <span class="transition ease-in-out duration-300 text-xs ml-4 text-blue-500 underline hover:text-blue-700 cursor-pointer" @click.prevent="pdfHolder[0].splice(pdfIndx,1), this.$refs[`pdfUpldr`].value = ''">Usuń</span>
                            </div>
                            <div class="mt-4 select-none">
                              <label for="pdfName" class="block text-sm font-medium text-slate-100">Nazwa pliku do wyświetlenia<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <input :disabled="uploadingPdf" type="text" id="pdfName" name="pdfName" maxlength="100" v-model="pdf.displayName" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. Umowa współpracy" />
                              </div>
                              <!-- <span class="text-xs select-none text-red-500" v-if="errors.company.city"> Pole nie może być puste.</span> -->
                              <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                            </div>
                            <div class="mt-4 select-none">
                              <label for="pdfDesc" class="block text-sm font-medium text-slate-100">Opis pliku<span class="text-red-500">*</span></label>
                              <div class="mt-1">
                                  <input :disabled="uploadingPdf" type="text" id="pdfDesc" name="pdfDesc" maxlength="100" v-model="pdf.desc" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" placeholder="np. Umowa obowiązująca do 30.06.2024r" />
                              </div>
                              <!-- <span class="text-xs select-none text-red-500" v-if="errors.company.city"> Pole nie może być puste.</span> -->
                              <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                            </div>
                          </div>
                          <!-- <div class="text-red-500 text-xs pl-1 pt-2 pb-2" v-if="errorsStep1Text[index].pdf.length > 0">
                            {{ errorsStep1Text[index].pdf }}
                          </div> -->
                        </div>
                        <div class="mt-3" v-if="pdfOption === 'currentFiles'">
                          <span class="block text-sm font-medium text-slate-100">Pliki klienta</span>
                          <ul v-if="this.managedUsr.files.length > 0" role="list" class="mt-1 divide-y divide-slate-300 rounded-md border border-slate-300">
                            <li v-for="file in this.managedUsr.files" :key="file" class="flex items-center justify-between py-2 pl-3 pr-4 text-sm leading-6">
                              <div class="flex w-0 flex-1 items-center">
                                <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-slate-200" aria-hidden="true" />
                                <div class="ml-2 flex min-w-0 flex-1 gap-2">
                                  <!-- {{file}} -->
                                  <span v-tooltip="`${file.displayName}  |  Opis: ${file.description}`" class="truncate cursor-default text-slate-200 font-medium">{{file.displayName}}</span>
                                  <span class="flex-shrink-0 text-slate-400">{{(file.size / 1024 / 1024).toFixed(2)}}mb</span>
                                </div>
                              </div>
                              <div class="ml-4 flex flex-shrink-0 space-x-4">
                                <button type="button" @click.prevent="openUrl(file.url)" class="transition ease-in-out duration-200 rounded-md font-medium text-blue-400 hover:text-blue-500">Otwórz</button>
                                <span class="text-slate-200" aria-hidden="true">|</span>
                                <button @click.prevent="this.askForDeletePdf = true, this.pdfToRemove = file" type="button" class="transition ease-in-out duration-200 rounded-md font-medium text-red-400 hover:text-red-500">Usuń</button>
                              </div>
                            </li>
                          </ul>
                          <EmptyState class="mt-1" v-if="this.managedUsr.files.length === 0"></EmptyState>
                        </div>
                        <div class="flex flex-col mt-1 sm:flex-row sm:justify-end">
                          <input :disabled="uploadingPdf" type="file" accept="application/pdf" v-show="false" ref="pdfUpldr" @change="addToPDFHolder($event, '0')"> 
                          <button :disabled="uploadingPdf" v-if="pdfHolder[0] !== undefined" ref="pdfButton" type="button" @click.prevent="uploadPdf()" :class="{'transition ease-in-out duration-300 mt-3 sm:mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2': true, 'opacity-40 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500 pointer-events-none' : pdfHolder[0][0].displayName.length === 0 || pdfHolder[0][0].desc.length === 0, 'text-green-700 hover:bg-green-200 bg-green-100 focus:ring-green-500': pdfHolder[0][0].displayName.length !== 0 && pdfHolder[0][0].desc.length !== 0, 'mr-2': true, 'opacity-50 pointer-events-none cursor-default': uploadingPdf}"><DocumentIcon class="h-4 w-4 mr-1" aria-hidden="true" /><span v-if="!uploadingPdf">Wgraj wybrany plik</span><LoaderData v-if="uploadingPdf"></LoaderData></button>
                          <button v-if="pdfOption === null || pdfOption === 'upload'" ref="pdfButton" type="button" @click.prevent="pdfOption = 'currentFiles', pdfHolder = {}, this.$refs[`pdfUpldr`].value = ''" :class="{'transition ease-in-out duration-300 mt-3 sm:mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30' : false, 'mr-2': pdfOption !== 'upload'}"><DocumentIcon class="h-4 w-4 mr-1" aria-hidden="true" />Zobacz dostępne pliki</button>
                          <button v-if="pdfOption === null || pdfOption === 'currentFiles'" ref="pdfButton" type="button" @click.prevent="pdfOption = 'upload'" :class="{'transition ease-in-out duration-300 mt-3 sm:mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30' : false}"><DocumentIcon class="h-4 w-4 mr-1" aria-hidden="true" />Wgraj nowy PDF</button>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 pt-4 pb-5">
                        <div class="mb-3 font-medium text-red-500">Usuń użytkownika</div>
                        <div class="flex items-center mt-1 mb-4">
                          <input v-model="deleteUserOnSave" type="checkbox" class="h-4 w-4 rounded border-slate-300 text-red-500 focus:ring-red-500">
                          <span class="text-xs ml-2 select-none text-slate-100">Chcę usunąć to konto - wiem co robię.</span>
                        </div>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between" v-if="managedUsr.permissions.admin">
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">
                          <span class="text-slate-200 font-normal text-xs">To konto jest administratorem. Nie możesz zarządzać tym kontem.</span>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <button @click.prevent="emitClose" class="rounded-md border border-slate-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" v-if="!loading">Zamknij</button>
                  <button v-if="!managedUsr.permissions.admin && !deleteUserOnSave && !loading" @click.prevent="validateUserDataBeforeUpdate" class="ml-2 rounded-md border border-slate-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Aktualizuj</button>
                  <button type="button" @click.prevent="deleteUser" v-if="!managedUsr.permissions.admin && deleteUserOnSave && !loading" class="ml-2 inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Usuń konto</button>
                  <span class="text-xs text-slate-100" v-if="loading">Zapisywanie..</span>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
        <askFor v-if="this.askForDeletePdf" @accept="removePdf(this.pdfToRemove)" :modalData="this.modalDeletePdf" @closeModal="this.askForDeletePdf = false, this.pdfToRemove = null"></askFor>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { QuestionMarkCircleIcon } from '@heroicons/vue/solid';
    import { DocumentIcon, PaperClipIcon } from '@heroicons/vue/outline'
    import axios from 'axios';
    import moment from 'moment'
    import {db, storage, firebase} from "@/firebase/gfbconf.js";
    import EmptyState from '@/components/EmptyState.vue';
    import LoaderData from '../components/LoaderData.vue';
    import askFor from '@/components/AlertModal.vue';
    export default {
        name: "ClientsModal",
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            QuestionMarkCircleIcon,
            DocumentIcon,
            PaperClipIcon,
            EmptyState,
            LoaderData,
            askFor
        },
        props: ['managedUsr'],
        data: function () {
            return {
              displayDiscounts: false,
              modalDeletePdf: {
                headerText: "Usuwanie pliku",
                bodyText: `Czy na pewno chcesz usunąć ten plik z systemu?`,
                acceptButtonText: "Usuń"
              },
              pdfToRemove: null,
              askForDeletePdf: false,
              uploadingPdf: false,
              pdfOption: null,
              pdfHolder: {},
              perPDFSizeMB: 6,
              loading: false,
              groupToSet: null,
              deleteUserOnSave: false,
              open: false,
              userUpdate: {
                name: "",
                surname: "",
                phone: "",
                invoiceEmail: "",
                company:{
                    name: "",
                    nip: "",
                    address: "",
                    postCode: "",
                    city: "",
                  },
                groupOfCards: "",
                groupOfCardsId: null,
                discounts:{
                    diesel:{
                      economy: [],
                      standard: [],
                      highway: [],
                      wbtank: []
                    },
                    adBlue:{
                      economy: [],
                      standard: [],
                      highway: [],
                      wbtank: []
                    },
                    dieselVPower:{
                      economy: [],
                      standard: [],
                      highway: [],
                      wbtank: []
                    },
                    petrol100Racing:{
                      economy: [],
                      standard: [],
                      highway: [],
                      wbtank: []
                    },
                    petrol95:{
                      economy: [],
                      standard: [],
                      highway: [],
                      wbtank: []
                    },
                    petrol95VPower:{
                      economy: [],
                      standard: [],
                      highway: [],
                      wbtank: []
                    },
                    lpg:{
                      economy: [],
                      standard: [],
                      highway: [],
                      wbtank: []
                    }
                  },
                invoicePaymentTime: null,
                activeInvoices: null,
                amountOfInvoicesInMonth: null,
                agreement: {
                  pickedDateOfAgreementUnix: null,
                  pickedDateOfAgreement: null
                }
              },
              errors: {
                name: false,
                surname: false,
                phone: false,
                groupOfCards: false,
                company: {
                    name: false,
                    nip: false,
                    address: false,
                    postCode: false,
                    city: false,
                  },
                invoiceEmail: false,
                invoiceEmailRepeat: false
              },
              downloadError: false,
              groupsOfCards: []
                // If you change something bellow, edit it also in NewComplaint.vue when creating new client
            }
        },
        created()
        {
          this.createUserUpdateObj()
          this.downloadGroupsOfCards()
        },
        methods: {
          openUrl(url){
            window.open(url, '_blank');
          },
          filenameGenerator(length, systemId)
            {
                let result           = `${systemId}_pdfFile_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
          async removePdf(pdfToRemove){
            await db.collection("UsersData").doc(this.managedUsr.id).update({
                    'files':  firebase.firestore.FieldValue.arrayRemove(pdfToRemove)
                  });
            this.$emit("reloadUser",this.managedUsr.id);
            // BRAK USUWANIA ZE FIREBASE STORAGE (JAKO BACKUP W RAZIE PRZYPADKOWEGO USUNIECIA PLIKU)
          },
          async uploadPdf(){
            this.uploadingPdf = true
            const storageRef = storage.ref();
                  const filename = "pdf_" + this.filenameGenerator(10, this.managedUsr.id);
                  const originalName = this.pdfHolder[0][0].file.name
                  const fileSize = this.pdfHolder[0][0].file.size
                  const type = this.pdfHolder[0][0].file.type
                  const pdfPath = `usersPdf/${this.managedUsr.id}/${filename}`
                  const pdfRef = storageRef.child(pdfPath);
                  await pdfRef.put(this.pdfHolder[0][0].file);
                  const fileUrl = await pdfRef.getDownloadURL();
                  let preparedData = {
                    name: filename,
                    originalName: originalName,
                    type: type,
                    size: fileSize,
                    url: fileUrl,
                    path: pdfPath,
                    displayName: this.pdfHolder[0][0].displayName,
                    description: this.pdfHolder[0][0].desc,
                    uploadDate: moment().unix()
                  }
                  await db.collection("UsersData").doc(this.managedUsr.id).update({
                    'files':  firebase.firestore.FieldValue.arrayUnion(preparedData)
                  });
                  this.$emit("reloadUser",this.managedUsr.id);
            this.uploadingPdf = false
            this.pdfOption = 'currentFiles'
            this.pdfHolder = {}
            this.$refs[`pdfUpldr`].value = ''
          },
          log(log){
            console.log(log);
          },
          addToPDFHolder(event,holderId)
            {
              this.pdfHolder[holderId] = [];
              if(event.target.files.length === 0) return;
              const file = event.target.files[0];
              const sizeInMb = file.size / 1024 / 1024;
              if(sizeInMb <= this.perPDFSizeMB)
              {
                this.pdfHolder[holderId].push(
                  {
                    file: file,
                    desc: "",
                    displayName: ""
                  });
                this.$store.commit('setNotification',{
                  show: true,
                  head: "PDF file has been added!",
                  subheader: ``,
                  success: true
                }); 
              }
              else
              {
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Cannot add this PDF!",
                    subheader: `File size is more than ${this.perPDFSizeMB} MB.`,
                    success: false
                  });                
              }
            },
          triggerPDFUploaderClick(uploaderRefAsText)
            {
              this.$refs[uploaderRefAsText].click();
            },
          pickDateOfAgreement(event){
                let mDate = moment(event.target.value)
                if(!mDate.isValid())
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Popraw datę!",
                        subheader: `Wykryto, że data jest niepoprawna.`,
                        success: false
                    });
                    this.userUpdate.agreement.pickedDateOfAgreement = null;
                    this.userUpdate.agreement.pickedDateOfAgreementUnix = null
                }
                else
                {
                    this.userUpdate.agreement.pickedDateOfAgreementUnix = mDate.unix()
                    // this.pickStatus()
                }
            },
          setGroup(group){
            this.userUpdate.groupOfCards = group.groupName
            this.userUpdate.groupOfCardsId = group.groupId
          },
          async resetPwd()
          {
            const res = await axios.post(`${this.$store.state.apiLink}/resetPasswordViaLink`, {
              email: this.managedUsr.contact.email
            })
            if(res.data.success)
            {
              window.open(res.data.link, '_blank').focus();
            }
          },
          sortDiscounts()
            {
              for (let x = 0; x < Object.keys(this.userUpdate.discounts).length; x++) {
                const product = Object.keys(this.userUpdate.discounts)[x];
                for (let y = 0; y < Object.keys(this.userUpdate.discounts[product]).length; y++) {
                  const typeOfStation = Object.keys(this.userUpdate.discounts[product])[y];
                  this.userUpdate.discounts[product][typeOfStation].sort((a,b)=>
                  {
                    if(a.active > b.active) return 1;
                    if(a.active < b.active) return -1;
                    return 0;
                  })
                  this.userUpdate.discounts[product][typeOfStation].reverse();
                }
              }
            },
          async createUserUpdateObj()
          {
            this.userUpdate.name = this.managedUsr.name.toString();
            this.userUpdate.surname = this.managedUsr.surname.toString();
            this.userUpdate.phone = this.managedUsr.contact.phone.toString();
            this.userUpdate.invoiceEmail = this.managedUsr.contact.invoiceEmail.toString();
            this.userUpdate.groupOfCards = this.managedUsr.groupOfCards.toString();
            this.userUpdate.groupOfCardsId = this.managedUsr.groupOfCardsId;
            this.userUpdate.company.name = this.managedUsr.company.name.toString();
            this.userUpdate.company.nip = this.managedUsr.company.nip.toString();
            this.userUpdate.company.address = this.managedUsr.company.address.toString();
            this.userUpdate.company.postCode = this.managedUsr.company.postCode.toString();
            this.userUpdate.invoicePaymentTime = this.managedUsr.invoicePaymentTime;
            this.userUpdate.activeInvoices = this.managedUsr.activeInvoices;
            this.userUpdate.amountOfInvoicesInMonth = this.managedUsr.amountOfInvoicesInMonth;
            this.userUpdate.agreement.pickedDateOfAgreement = this.managedUsr.agreement.pickedDateOfAgreement
            this.userUpdate.agreement.pickedDateOfAgreementUnix = this.managedUsr.agreement.pickedDateOfAgreementUnix
            this.userUpdate.company.city = this.managedUsr.company.city.toString();this.userUpdate.company.city = this.managedUsr.company.city.toString();
            for (let x = 0; x < Object.keys(this.managedUsr.discounts).length; x++) {
                const product = Object.keys(this.managedUsr.discounts)[x];
                for (let y = 0; y < Object.keys(this.managedUsr.discounts[product]).length; y++) {
                  const typeOfStation = Object.keys(this.managedUsr.discounts[product])[y];
                  this.managedUsr.discounts[product][typeOfStation].forEach(discount => {
                    let prepared = {}
                    if(product === 'diesel'){
                      prepared = {
                        active: discount.active,
                        amount: discount.amount,
                        dateFrom: discount.dateFrom,
                        dateTo: discount.dateTo,
                        orlenSpot: discount.orlenSpot
                      }
                    }else{
                      prepared = {
                        active: discount.active,
                        amount: discount.amount,
                        dateFrom: discount.dateFrom,
                        dateTo: discount.dateTo
                      }
                    }
                    this.userUpdate.discounts[product][typeOfStation].push(prepared)
                    if(discount.active){
                      let preparedActive = {}
                      if(product === 'diesel'){
                        preparedActive = {
                        active: discount.active,
                        amount: discount.amount,
                        dateFrom: discount.dateFrom,
                        dateTo: discount.dateTo,
                        orlenSpot: discount.orlenSpot
                      }
                      }else{
                        preparedActive = {
                        active: discount.active,
                        amount: discount.amount,
                        dateFrom: discount.dateFrom,
                        dateTo: discount.dateTo
                      }
                      }
                      this.userUpdate.discounts[product][typeOfStation].push(preparedActive)
                    }
                  })
                }
              }
            await this.sortDiscounts()
            for (let x = 0; x < Object.keys(this.userUpdate.discounts).length; x++) {
                const product = Object.keys(this.userUpdate.discounts)[x];
                for (let y = 0; y < Object.keys(this.userUpdate.discounts[product]).length; y++) {
                  const typeOfStation = Object.keys(this.userUpdate.discounts[product])[y];
                  this.userUpdate.discounts[product][typeOfStation][0].active = "new"
                }
              }
          },
          async deleteUser()
          {
            if(this.managedUsr.id === undefined || this.managedUsr.id === null) return;
            if(this.managedUsr.id.length === 0) return;
            this.loading = true;
            try{
              const res = await axios.post(`${this.$store.state.apiLink}/deleteUser`, {
                id: this.managedUsr.id,
                worker: this.managedUsr.worker
              });
              if(res.data.success)
              {
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona sukcesem!",
                  subheader: "Użytkownik został usunięty.",
                  success: true
                });
                this.loading = false;                
              }
              else
              {
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: "Użytkownik nie został usunięty.",
                  success: false
                });
                this.loading = false; 
              }       
            }
            catch(error)
            {
                this.$emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: "Użytkownik nie został usunięty.",
                  success: false
                });
                this.loading = false;              
            }
          },
          async updateUser()
          {
            if(this.managedUsr.id === undefined || this.managedUsr.id === null) return;
            if(this.managedUsr.id.length === 0) return;
            this.loading = true;
            try{
              const res = await axios.post(`${this.$store.state.apiLink}/updateUser`, {
                update: this.userUpdate,
                id: this.managedUsr.id,
                worker: this.managedUsr.worker
              })
              if(res.data.success)
              {
                this.emitClose();
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona sukcesem!",
                  subheader: "Użytkownik został zmodyfikowany.",
                  success: true
                });
                this.loading = false;
              }
              else
              {
                this.emitClose();
                this.loading = false;
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Akcja zakończona niepowodzeniem!",
                  subheader: "Coś poszło nie tak..",
                  success: false
                });
              }
            }
            catch(error)
            {
              console.log(error);
              this.emitClose();
              this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: "Coś poszło nie tak..",
                success: false
              });
              this.loading = false;
            }
          },
          clearErrors()
          {
            this.errors.name = false;
            this.errors.surname = false;
            this.errors.phone = false;
            this.errors.groupOfCards = false;
            this.errors.company = {
                    name: false,
                    nip: false,
                    address: false,
                    postCode: false,
                    city: false,
                  };
            this.errors.invoiceEmail = false;
            this.errors.invoiceEmailRepeat = false;
          },
          userValidator()
          {
            let errorsAmount = 0;
            this.clearErrors();
            if(this.userUpdate.name.length === 0)
            {
              errorsAmount++;
              this.errors.name = true;
            }
            if(this.userUpdate.surname.length === 0)
            {
              errorsAmount++;
              this.errors.surname = true;
            }
            if(/^\+?[1-9][0-9]{7,14}$/.test(this.userUpdate.phone) === false)
            {
              errorsAmount++;
              this.errors.phone = true;
            }
            if(this.userUpdate.groupOfCards.length === 0)
              {
                errorsAmount++;
                this.errors.groupOfCards = true;
              }
            if(this.userUpdate.company.name.length === 0)
              {
                errorsAmount++;
                this.errors.company.name = true;
              }
            if(/^[0-9]{10}$/.test(this.userUpdate.company.nip) === false)
              {
                errorsAmount++;
                this.errors.company.nip = true;
              }
            if(this.userUpdate.company.address.length === 0)
              {
                errorsAmount++;
                this.errors.company.address = true;
              }
            if(/^[0-9]{2}-[0-9]{3}/.test(this.userUpdate.company.postCode) === false)
              {
                errorsAmount++;
                this.errors.company.postCode = true;
              }
            if(this.userUpdate.company.city.length === 0)
              {
                errorsAmount++;
                this.errors.company.city = true;
              }
            if(this.userUpdate.invoiceEmail.length !== 0)
              {
                if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.userUpdate.invoiceEmail.toLowerCase()) === false)
                {
                  errorsAmount++;
                  this.errors.invoiceEmail = true;
                }
                if(this.managedUsr.contact.email === this.userUpdate.invoiceEmail){
                  errorsAmount++;
                  this.errors.invoiceEmailRepeat = true;
                }
              }
            return errorsAmount;

          },
          validateUserDataBeforeUpdate()
          {
            this.clearErrors();
            if(this.userValidator() > 0) return;
            this.updateUser();    
          },
          emitClose()
          {
            this.clearErrors();
            this.$emit("closeClientModal");
          },
          async downloadGroupsOfCards(){
            const res = await axios.post(`${this.$store.state.apiLink}/getGroupsOfCards`, {})
            if(res.data.success){
                this.groupsOfCards = res.data.groups
                this.groupsOfCards.forEach(group => {
                  if(group.groupName === this.managedUsr.groupOfCards.toString() && group.groupId === this.managedUsr.groupOfCardsId){
                    this.groupToSet = group
                  }
                });
                this.downloadError = false
            }else{
                this.downloadError = true
            }
          },
          // Create new client
        },
        computed:{

        }
    };
</script>