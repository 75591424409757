<template>
    <div>
        <div class="flex justify-between">
            <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                        <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span class="sr-only">Home</span>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <router-link to="/dashboard/gainsLosses">
                            <div class="ml-4 text-xs font-medium text-slate-100 select-none" aria-current="page">Zyski i straty</div>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Rabaty</div>
                        </div>
                    </li>
                </ol>
                <!-- <div @click.prevent="testInvoice()" class="ml-4 cursor-pointer flex items-center justify-center px-4 bg-red-200">TEST FAKTUR</div> -->
            </nav>
            <div class="flex items-center sm:hidden">
                <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-slate-300 hover:text-slate-400" aria-current="page">&larr; Cofnij</router-link>
            </div>
        </div>
    <SetGainLossesDiscountsModal v-if="modalVisible" @closeModal="modalVisible = false, downloadPeriods()"/>
    <div class="flex justify-end items-center">
        <button type="button" @click.prevent="modalVisible = true" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Dodaj rabat</button>
    </div>
    <div class="mt-4 flex flex-col">
        <div class="overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden min-h-64 shadow border border-slate-700 rounded-lg">
                    <table class="min-w-full divide-y divide-slate-700">
                        <thead class="bg-slate-700">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">Okres</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Shell FuelSave Diesel</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Shell V-Power Nitro+ Diesel</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">AdBlue</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Shell FuelSave 95</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Shell V-Power Nitro+ 95 AL</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Shell Racing 100</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Gaz samochodowy LPG</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Inne</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100"></th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-slate-600 bg-slate-500">
                            <tr v-for="(period, index) in periods" :key="index">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-semibold text-slate-100 sm:pl-6 gap-1">
                                    <div class="flex items-center">
                                        <div>{{periodDateFormatToText([period.start, period.end])}}</div> 
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs" :class="['text-slate-300 font-bold']">{{period.products['Shell FuelSave Diesel'].toFixed(3)}} PLN</td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs" :class="['text-slate-300 font-bold']">{{period.products['Shell V-Power Nitro+ Diesel'].toFixed(3)}} PLN</td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs" :class="['text-slate-300 font-bold']">{{period.products['AdBlue'].toFixed(3)}}/{{period.products['AdBlueBuy'].toFixed(3)}} PLN</td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs" :class="['text-slate-300 font-bold']">{{period.products['Shell FuelSave 95'].toFixed(3)}} PLN</td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs" :class="['text-slate-300 font-bold']">{{period.products['Shell V-Power Nitro+ 95 AL'].toFixed(3)}} PLN</td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs" :class="['text-slate-300 font-bold']">{{period.products['Shell Racing 100'].toFixed(3)}} PLN</td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs" :class="['text-slate-300 font-bold']">{{period.products['Gaz samochodowy LPG'].toFixed(3)}} PLN</td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs" :class="['text-slate-300 font-bold']">{{period.products['Inne'].toFixed(3)}} PLN</td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs" :class="['text-slate-300 font-bold']">
                                    <button v-tooltip="'Usuń rabat.'" @click="deleteDiscount(index)" class="group group-hover:text-red-400 transition ease-in-out duration-300 bg-slate-600 hover:bg-slate-700 text-white font-semibold py-1.5 px-2 rounded">
                                        <TrashIcon class="w-5 h-5 text-gray-200 group-hover:text-red-400 transition-all duration-300" />
                                    </button> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <EmptyState v-if="!downloadingPeriods && periods.length === 0"></EmptyState>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import EmptyState from './EmptyState.vue'
import { HomeIcon, DownloadIcon, ArrowNarrowRightIcon, ChevronDownIcon, TrashIcon, ExclamationCircleIcon } from '@heroicons/vue/solid'
import { FilterIcon,DocumentTextIcon,DocumentReportIcon, PencilAltIcon } from '@heroicons/vue/outline'
import {db} from "@/firebase/gfbconf.js";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import SetGainLossesDiscountsModal from './SetGainLossesDiscountsModal.vue'
import axios from 'axios';
import moment from 'moment'

export default {
    name: "invoices",
    data(){
        return{
            periods: [],
            modalVisible: false,
            downloadingPeriods: true,
        }
    },
    components: {
        HomeIcon, PencilAltIcon, TrashIcon,
        DownloadIcon,
        ArrowNarrowRightIcon,
        ChevronDownIcon,
        FilterIcon,
        EmptyState,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        SetGainLossesDiscountsModal,
        DocumentTextIcon,
        DocumentReportIcon,
        ExclamationCircleIcon
    },
    methods: {
        deleteDiscount(index) {
            this.periods.splice(index, 1)
            db.collection('GainsLossesPeriods').doc('periods').update({
                periods: this.periods
            })
        },
        periodDateFormatToText(period) {
            let date = '';
            if(period != null && period.length > 0) {
                date = `${moment.unix(period[0]).format('DD.MM.YYYY')} - ${moment.unix(period[1]).format('DD.MM.YYYY')}`
            }
            return date;
        },
        async downloadPeriods() {
            this.downloadingPeriods = true
            let periods = await db.collection('GainsLossesPeriods').doc('periods').get()
            this.periods = periods.data().periods
            this.downloadingPeriods = false
        },
    },
    mounted(){
        this.downloadPeriods()
    },
    beforeUnmount() {
    }
}

</script>
<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>