<template>
  <div>
    <div class="flex justify-between">
        <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                <li class="flex">
                    <div class="flex items-center">
                    <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Zyski i straty</div>
                    </div>
                </li>
            </ol>
            <!-- <div @click.prevent="testInvoice()" class="ml-4 cursor-pointer flex items-center justify-center px-4 bg-red-200">TEST FAKTUR</div> -->
        </nav>
        <div class="flex items-center sm:hidden">
            <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-slate-300 hover:text-slate-400" aria-current="page">&larr; Cofnij</router-link>
        </div>
    </div>
    <div class="mt-4">
        <h3 class="text-lg font-medium leading-6 text-slate-300">Podsumowanie</h3>
        <dl class="mt-3 grid grid-cols-1 gap-5 xl:grid-cols-3">
            <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                <dt class="truncate text-sm font-medium text-slate-700">Zysk</dt>
                <dd v-if="!queryLoading" class="mt-1 text-xl font-semibold tracking-tight" :class="total.income > 0 ? 'text-green-500' : total.income == 0 ? 'text-yellow-500' : 'text-red-500'">{{total.income.toFixed(2)}} PLN</dd>
                <dd v-if="queryLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
            </div>
            <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                <dt class="truncate text-sm font-medium text-slate-700">Cena sprzedaży</dt>
                <dd v-if="!queryLoading" class="mt-1 text-xl font-semibold tracking-tight text-slate-900">{{total.sellPrice.toFixed(2)}} PLN</dd>
                <dd v-if="queryLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
            </div>
            <div class="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow ">
                <dt class="truncate text-sm font-medium text-slate-700">Cena zakupu</dt>
                <dd v-if="!queryLoading" class="mt-1 text-xl font-semibold tracking-tight text-slate-900">{{total.purchasePrice.toFixed(2)}} PLN</dd>
                <dd v-if="queryLoading" class="mt-1 text-xl font-semibold tracking-tight pt-3 pb-1 text-slate-900"><LoaderData></LoaderData></dd>
            </div>
        </dl>
    </div>
    <div class="flex gap-4 my-5">
        <div>
            <div class="block text-sm font-medium leading-6 text-slate-200">Wybierz datę</div>
            <VueDatePicker :format="datePickerFormat" @update:model-value="getGainsLosses" v-model="selectedMonth" hide-input-icon auto-apply :month-picker="true" :clearable="false" locale="pl" :min-date="moment('01.04.2024', 'DD.MM.YYYY').toDate()" :disabled-dates="[moment().add(1,'month').toDate()]" :max-date="moment().add(1,'month').toDate()"/>
        </div>
        <div class="flex items-end justify-end w-full">
            <router-link to="/dashboard/gainsLossesDiscounts" :class="{'inline-flex transition ease-in-out duration-200 items-center rounded border border-slate-600 bg-slate-500 px-2.5 py-1.5 text-xs font-medium text-slate-100 shadow-sm h-10': true, 'hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none': true, 'opacity-50 pointer-events-none select-none focus outline-none': false}">Zarządzanie rabatami</router-link>
        </div>

    </div>
    <div class="flex flex-col mx-4 lg:px-0" >
        <div class="-my-2 -mx-4 overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                    <div class="block">
                        <nav class="isolate flex divide-x divide-slate-700 rounded-t-lg shadow" aria-label="Tabs">
                            <div @click.prevent="viewType = 'invoices'" :class="[viewType === 'invoices' ? 'text-slate-100 bg-slate-900 pointer-events-none' : 'text-slate-300 bg-slate-800 hover:bg-slate-900 hover:text-slate-100 cursor-pointer', 'transition ease-in-out duration-200 rounded-tl-lg group select-none relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10']">
                            <span>Widok wg klientów i faktur</span>
                            <span aria-hidden="true" :class="[viewType === 'invoices' ? 'bg-indigo-300' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                            </div>
                            <div @click.prevent="viewType = 'clients'" :class="[viewType === 'clients' ? 'text-slate-100 bg-slate-900 pointer-events-none' : 'text-slate-300 bg-slate-800 hover:bg-slate-900 hover:text-slate-100 cursor-pointer', 'transition ease-in-out duration-200 rounded-tr-lg group select-none relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10']">
                            <span> Widok wg klientów i produktów</span>
                            <span aria-hidden="true" :class="[viewType === 'clients' ? 'bg-indigo-300' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                            </div>
                        </nav>
                    </div>
                    <table v-if="!queryLoading && viewType === 'invoices'" class="min-w-full divide-y divide-slate-700">
                        <thead class="bg-slate-700">
                            <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">
                                <div class="flex">
                                    ID
                                </div>
                                </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                <div class="flex">
                                    Firma
                                </div>
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                <div class="flex">
                                    Imię Nazwisko/Numer faktury 
                                </div>
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Zysk</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Cena sprzedaży</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                <div class="flex">
                                    Cena zakupu
                                </div>
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                
                            </th>
                            </tr>
                        </thead>

                        <tbody v-for="user in users" class="divide-y divide-slate-700 bg-slate-500">
                            <tr>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex items-center text-xs text-slate-100">
                                        <!-- {{user.id}} -->
                                        <span v-tooltip="`${user.id}`" @click.prevent="copyToClipboard(user.id)" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                    <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                        <div class="flex items-center">
                                            <!-- {{userList[user.userId].company.name}} -->
                                            <div v-tooltip="user.company.name">{{user.company.name.length > 0 ? user.company.name.length < 20 ? user.company.name : user.company.name.substring(0,20) + '...' : '--'}}</div>
                                        </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                    {{user.name}} {{user.surname}} 
                                    <!-- <span class="text-xs py-1 px-2 bg-red-100 border border-red-300 rounded-lg text-red-500 font-medium" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                    <!-- {{userList[user.userId].name}} {{userList[user.userId].surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="userList[user.userId].permissions.admin">(ADMINISTRATOR)</span> -->
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs font-medium" :class="user.income > 0 ? 'text-green-400' : user.income == 0 ? 'text-yellow-400' : 'text-red-400'">
                                    {{user.income.toFixed(2)}} PLN
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                    {{user.sellPrice.toFixed(2)}} PLN
                                    
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                    {{user.purchasePrice.toFixed(2)}} PLN
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none flex justify-end">
                                    <button v-if="!queryLoading" :disabled="user.invoices.length === 0" @click="user.showInvoices = !user.showInvoices" :class="user.invoices.length === 0 ? 'opacity-30 cursor-not-allowed' : 'hover:bg-slate-700'" class="transition ease-in-out duration-300 bg-slate-600 text-white font-semibold py-2 px-4 rounded w-48 flex justify-center">{{user.showInvoices ? `Ukryj szczegóły (${user.invoices.length})` : `Pokaż szczegóły (${user.invoices.length})`}}</button>
                                    <button v-if="queryLoading" :disabled="true" :class="queryLoading ? '' : 'hover:bg-slate-700 cursor-not-allowed'" class="transition ease-in-out duration-300 bg-slate-600 text-white font-semibold py-2 px-4 rounded w-48 flex justify-center">
                                        <div class="spinner"></div>
                                        {{queryLoading}}
                                    </button>
                                </td>
                            </tr>
                            <tr v-if="user.showInvoices" v-for="invoice in user.invoices" class="bg-gray-500">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6 ">
                                    <!-- <div class="flex items-center text-xs text-slate-100">
                                        <span v-tooltip="`test1`" @click.prevent="copyToClipboard('test1')" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                    </div> -->
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                    
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                    {{invoice.invoiceName}}
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs font-medium" :class="invoice.income > 0 ? 'text-green-400' : invoice.income == 0 ? 'text-yellow-400' : 'text-red-400'">
                                    <!-- {{moment(`${transaction.date} ${transaction.time}`, "YYYYMMDD hh:mm:ss").format('LLL')}} -->
                                    {{invoice.income.toFixed(2)}} PLN
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                    <div>
                                        <!-- {{transaction.productName}} -->
                                        {{invoice.sellPrice.toFixed(2)}} PLN
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                    <!-- {{transaction.WbTankNetValue}} {{transaction.currency}} -->
                                    {{invoice.purchasePrice.toFixed(2)}} PLN
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 flex justify-end text-sm font-medium sm:pr-6 select-none">
                                   
                                </td>
                            </tr>
                        </tbody>

                    </table>
                    <table v-if="!queryLoading && viewType === 'clients'" class="min-w-full divide-y divide-slate-700">
                        <thead class="bg-slate-700">
                            <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">
                                <div class="flex">
                                    ID
                                </div>
                                </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                <div class="flex">
                                    Firma/Stacja
                                </div>
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                <div class="flex">
                                    Imię Nazwisko/Nazwa produktu 
                                </div>
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-sm font-semibold whitespace-nowrap text-slate-100 text-right">Zysk</th>
                            <th scope="col" class="px-3 py-3.5 text-sm font-semibold whitespace-nowrap text-slate-100 text-right">Cena sprzedaży</th>
                            <th scope="col" class="px-3 py-3.5 text-sm font-semibold whitespace-nowrap text-slate-100">
                                <div class="flex justify-end">
                                    Cena zakupu
                                </div>
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                
                            </th>
                            </tr>
                        </thead>

                        <tbody v-for="user in users" class="divide-y divide-slate-700 bg-slate-500">
                            <tr>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex items-center text-xs text-slate-100">
                                        <!-- {{user.id}} -->
                                        <span v-tooltip="`${user.id}`" @click.prevent="copyToClipboard(user.id)" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                    <!-- {{user.name}} {{user.surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                        <div class="flex items-center">
                                            <!-- {{userList[user.userId].company.name}} -->
                                            <div v-tooltip="user.company.name">{{user.company.name.length > 0 ? user.company.name.length < 20 ? user.company.name : user.company.name.substring(0,20) + '...' : '--'}}</div>
                                        </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                    {{user.name}} {{user.surname}} 
                                    <!-- <span class="text-xs py-1 px-2 bg-red-100 border border-red-300 rounded-lg text-red-500 font-medium" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                    <!-- {{userList[user.userId].name}} {{userList[user.userId].surname}} <span class="text-xs font-light py-1 px-2 bg-red-200 border border-red-400 rounded-lg text-red-500" v-if="userList[user.userId].permissions.admin">(ADMINISTRATOR)</span> -->
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-right" :class="user.income > 0 ? 'text-green-400' : user.income == 0 ? 'text-yellow-400' : 'text-red-400'">
                                    {{user.income.toFixed(2)}} PLN
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium text-right">
                                    {{user.sellPrice.toFixed(2)}} PLN
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium text-right">
                                    {{user.purchasePrice.toFixed(2)}} PLN
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none flex justify-end">
                                    <button v-if="!queryLoading" :disabled="user.products.length === 0" @click="user.showProducts = !user.showProducts" :class="user.products.length === 0 ? 'opacity-30 cursor-not-allowed' : 'hover:bg-slate-700'" class="transition ease-in-out duration-300 bg-slate-600 text-white font-semibold py-2 px-4 rounded w-48 flex justify-center">{{user.showProducts ? `Ukryj szczegóły (${user.products.length})` : `Pokaż szczegóły (${user.products.length})`}}</button>
                                    <button v-if="queryLoading" :disabled="true" :class="queryLoading ? '' : 'hover:bg-slate-700 cursor-not-allowed'" class="transition ease-in-out duration-300 bg-slate-600 text-white font-semibold py-2 px-4 rounded w-48 flex justify-center">
                                        <div class="spinner"></div>
                                        {{queryLoading}}
                                    </button>
                                </td>
                            </tr>
                            <tr v-if="user.showProducts" v-for="product in user.products" :class="product.stationName.length > 0 ? 'bg-slate-700' : 'bg-gray-500'">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <!-- <div class="flex items-center text-xs text-slate-100">
                                        <span v-tooltip="`test1`" @click.prevent="copyToClipboard('test1')" class="transition ease-in-out duration-300 cursor-pointer text-blue-300 hover:text-blue-400">Kopiuj</span>
                                    </div> -->
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100" :class="product.stationName.length > 0 ? 'font-semibold' : ''">
                                    {{product.stationName.length > 0 ? product.stationName : ''}} {{product.liters.toFixed(2)}} L
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100" >
                                    {{product.name}}
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs font-medium text-right" :class="[product.stationName.length > 0 ? 'font-semibold' : '', product.income > 0 ? 'text-green-400' : product.income == 0 ? 'text-yellow-400' : 'text-red-400']">
                                    <!-- {{moment(`${transaction.date} ${transaction.time}`, "YYYYMMDD hh:mm:ss").format('LLL')}} -->
                                    {{product.income.toFixed(2)}} PLN
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium text-right" :class="product.stationName.length > 0 ? 'font-semibold' : ''">
                                    <div>
                                        <!-- {{transaction.productName}} -->
                                        {{product.sellPrice.toFixed(2)}} PLN
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium text-right" :class="product.stationName.length > 0 ? 'font-semibold' : ''">
                                    <!-- {{transaction.WbTankNetValue}} {{transaction.currency}} -->
                                    {{product.purchasePrice.toFixed(2)}} PLN
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 flex justify-end text-sm font-medium sm:pr-6 select-none">
                                    
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
    <transition name="fade">
      <div v-if="generating">
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gradient-to-r from-slate-600 via-slate-700 to-slate-600 opacity-85 flex flex-col items-center justify-center">
            <h2 class="text-center text-slate-200 text-lg font-medium">Trwa generowanie zysków i strat</h2>
            <p class="w-1/3 text-center mb-2.5 text-sm text-red-400">Nie wyłączaj tej strony do momentu zapełnienia paska progresu.</p>
            <div class="w-1/4 bg-gray-200 rounded-full h-2.5 relative">
                <div v-tooltip="'Umowy własne'" :class="'z-20'" class="bg-blue-500 h-2.5 rounded-full transition-all duration-1000 absolute" :style="`width: ${((currentGenerated*100)/usersList.length).toFixed(2)}%`"></div>
            </div>
            <p class="w-1/3 mt-1 text-center text-sm font-medium text-slate-100">{{currentGenerated+'/'+usersList.length}} ({{((currentGenerated*100)/usersList.length).toFixed(2)}}%)</p>
            <p class="w-1/3 text-center text-xs font-medium mt-4 text-red-500">W przypadku wyłączenia tej podstrony, bądź utraty połączenia z internetem generowanie faktur zostanie przerwane i rozpocznie się od początku.</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {db, storage} from "@/firebase/gfbconf.js";
import { HomeIcon, DownloadIcon, ArrowNarrowRightIcon, ChevronDownIcon, TrashIcon } from '@heroicons/vue/solid'
import LoaderData from '../components/LoaderData.vue';
import axios from 'axios';
import moment from 'moment'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: "gainLosses",
    data(){
        return{
            users: [],
            queryLoading: true,
            selectedMonth: null,
            usersList: [],
            viewType: 'clients',
            total: {
                income: 0,
                purchasePrice: 0,
                sellPrice: 0
            },
            currentGenerated: 0,
            generating: false,
            moment: moment
        }
    },
    components: {
      HomeIcon, DownloadIcon, ArrowNarrowRightIcon, ChevronDownIcon, TrashIcon, VueDatePicker, LoaderData
    },
    methods: {
        async downloadUsers() {
            let getUsers = await db.collection('UsersData').where('activeInvoices', '==', true).get()
            getUsers.forEach(doc => {
                let userData = doc.data()
                userData.id = doc.id
                this.usersList.push(userData)
            })
        },
        datePickerFormat(date) {
        let dateFormat = moment(date).format("MMMM YYYY")
        return dateFormat.charAt(0).toUpperCase() + dateFormat.slice(1);
        },
        async getGainsLosses(date) {
            this.queryLoading = true
            this.users = []
            let selectedDate = date == null ? moment(`01-${this.selectedMonth.month + 1}-${this.selectedMonth.year}`, "DD-MM-YYYY").unix() : moment(`01-${date.month + 1}-${date.year}`, "DD-MM-YYYY").unix()
            this.total.income = 0
            this.total.purchasePrice = 0
            this.total.sellPrice = 0
            const res = await axios.post(`${this.$store.state.apiLink}/getGainLosses`, {
                selectedDate: selectedDate,
            })
            if(res.data.success) {
                this.users.push(...res.data.usersData)
                this.total.income = res.data.total.income
                this.total.purchasePrice = res.data.total.purchasePrice
                this.total.sellPrice = res.data.total.sellPrice
            }
            
            this.queryLoading = false
        }
    },
    async mounted(){
        moment.locale('pl')
        const currentMonth = moment().month()
        const currentYear = moment().year()
        this.selectedMonth = {
            month: currentMonth,
            year: currentYear
        }
        await this.downloadUsers()
        this.getGainsLosses()
    },
    beforeUnmount() {
    }
}

</script>
<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

    .spinner {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3.8px solid #d6d6d6;
        animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
            spinner-oaa3wk 2.2399999999999998s infinite linear;
    }

    @keyframes spinner-bulqg1 {
        0% {
            clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
        }

        12.5% {
            clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
        }

        25% {
            clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
        }

        50% {
            clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
        }

        62.5% {
            clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
        }

        75% {
            clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
        }

        100% {
            clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
        }
    }

    @keyframes spinner-oaa3wk {
        0% {
            transform: scaleY(1) rotate(0deg);
        }

        49.99% {
            transform: scaleY(1) rotate(135deg);
        }

        50% {
            transform: scaleY(-1) rotate(0deg);
        }

        100% {
            transform: scaleY(-1) rotate(-135deg);
        }
    }
</style>