<template>
    <div>
        <div class="flex justify-between">
            <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                        <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span class="sr-only">Home</span>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <router-link to="/dashboard/interestNotes" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <div class="ml-4 text-xs font-medium" aria-current="page">Noty odsetkowe</div>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Utwórz notę</div>
                        </div>
                    </li>
                </ol>
                <!-- <div @click.prevent="testInvoice()" class="ml-4 cursor-pointer flex items-center justify-center px-4 bg-red-200">TEST FAKTUR</div> -->
            </nav>
            <div class="flex items-center sm:hidden">
                <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-slate-300 hover:text-slate-400" aria-current="page">&larr; Cofnij</router-link>
            </div>
        </div>
    <div v-if="!loading" class="flex w-full mt-4 overflow-y-auto">
        <div v-if="periodDate.length > 0 && pickedUser != null" class="bg-white w-2/3 p-12 flex flex-col">
            <div class="flex w-full mb-4">
                <div class="w-1/3 flex items-center justify-center">
                    <img class="w-full" src="https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2Fpobrane-min.png?alt=media&token=901b0405-351d-4e67-b415-33b1e336baab" />
                </div>
                <div class="w-2/3 flex flex-col pl-16">
                    <div class="flex text-xs w-full justify-end mb-8">
                        <div>Płotowo dn. {{this.documentData.interestNoteDeliveryDate}}</div>
                    </div>
                    <!-- <div class="flex flex-col items-end">
                        <div class="flex line">
                            <div class="flex-col text-smPDF text-slate-900 font-bold">
                                <div class="text-right">
                                    Okres naliczania odsetek
                                </div>
                                <div class="text-right">
                                    Stopy procentowe
                                </div>
                            </div>
                            <div class="flex-col text-smPDF text-black font-medium pl-4">
                                <div class="text-right">
                                    {{this.periodDateFormat()}}
                                </div>
                                <div class="text-right">
                                    15.75%
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="w-full font-medium flex mb-8">
                <div class="w-1/2 flex flex-col">
                    <div class="text-base text-slate-900 font-bold">Wierzyciel</div>
                    <div class="text-smPDF">LAURON SP. Z O.O.</div>
                    <div class="text-smPDF">PŁOTOWO 3A, 77-131 REKOWO</div>
                    <div class="text-smPDF">NIP: 8421772106</div>
                </div>
                <div class="w-1/2">
                    <div class="text-base text-slate-900 font-bold">Nazwa i adres dłużnika</div>
                    <div class="text-smPDF">{{this.documentData.payerName}}</div>
                    <div class="text-smPDF">{{this.pickedUser.company.address}} {{this.pickedUser.company.postCode}} {{this.pickedUser.company.city}}</div>
                    <div class="text-smPDF">NIP: {{this.pickedUser.company.nip}}</div>
                </div>
            </div>
            <div class="w-full">
                <div :class="['flex flex-col items-center justify-center gap-2 mb-6']">
                    <div class="font-bold uppercase text-xl">Nota odsetkowa nr {{documentData.interestNoteName}}</div>
                    <!-- <div class="font-medium mb-4 text-lg">oryginał/kopia</div> -->
                    <div class="font-medium w-full text-left">Nota odsetkowa została wygenerowana automatycznie na podstawie opłaconych faktur z okresu {{periodDateFormat()}}.</div>
                    <div class="font-medium w-full text-left">Na podstawie art. 481 ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny, wzywamy Państwa do uregulowania, tytułem odsetek za opóźnienia handlowe w zapłacie, kwoty: {{calculateInterestNote().toFixed(2)}} zł</div>
                    <div class="font-medium w-full text-left">(słownie: {{priceInPolish}}),</div>
                    <div class="font-medium w-full text-left">zgodnie z podanym niżej obliczeniem:</div>
                </div>
                <div class="grid grid-cols-17">
                    <div class="col-span-4 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Nr faktury</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Data faktury</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Data wpłaty</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Kwota faktury</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Wpłacono</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Termin płatności</span>
                        </div>
                    </div>
                    <div class="col-span-1 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Ilość dni zwłoki</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Kwota odsetek</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="grid grid-cols-17 relative" :ref="`invoice${index}`" v-for="(invoice, index) in documentData.invoices" :key="index">
                    <div class="col-span-4 h-full border-gray-200 border-b">
                      <div class="h-full py-2 px-2 w-full flex items-center bg-white border-gray-200 border-r">
                          <span class="w-full text-right text-xxs flex gap-2">{{invoice.invoiceNumber}}</span>
                      </div>
                    </div>
                    <div class="col-span-2 h-full border-gray-200 border-b">
                      <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                          <span class="w-full text-center text-xxs">{{invoice.invoiceDate}}</span>
                      </div>
                    </div>
                    <div class="col-span-2 h-full border-gray-200 border-b">
                      <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                          <span class="w-full text-center text-xxs">{{invoice.depositDate}}</span>
                      </div>
                    </div>
                    <div class="col-span-2 h-full border-gray-200 border-b">
                      <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                          <span class="w-full text-center text-xxs">{{invoice.invoiceValue.toFixed(2)}}</span>
                      </div>
                    </div>
                    <div class="col-span-2 h-full border-gray-200 border-b">
                      <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                          <span class="w-full text-center text-xxs">{{invoice.depositAmount.toFixed(2)}}</span>
                      </div>
                    </div>
                    <div class="col-span-2 h-full border-gray-200 border-b">
                      <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                        <span class="w-full text-center text-xxs">{{invoice.invoicePaymentDate}}</span>
                      </div>
                    </div>
                    <div class="col-span-1 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">{{invoice.dayDelay}}</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">{{invoice.interestValue.toFixed(2)}}</span>
                        </div>
                    </div>
                    <!-- <div class="absolute -right-6 top-1/2 transform -translate-y-1/2">
                        <XIcon v-tooltip="`Usuń pozycję`" @click.prevent="removePosition(index)" class="transition ease-in-out duration-200 h-5 w-5 ml-1 text-red-500 cursor-pointer hover:text-red-700" aria-hidden="true" />
                    </div> -->
                </div>
                <div class="grid grid-cols-17 relative">
                    <div class="col-span-15 h-full border-gray-200 border-b">
                      <div class="h-full py-2 px-2 w-full flex items-center bg-white border-gray-200 border-r">
                          <span class="w-full text-right text-xxs">RAZEM:</span>
                      </div>
                    </div>
                    <div class="col-span-2 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">{{calculateInterestNote().toFixed(2)}}</span>
                        </div>
                    </div>
                </div>
                <EmptyState v-if="documentData.invoices.length === 0"></EmptyState>
                <!-- <div class="flex justify-center mt-3">
                    <button @click="addPosition()" type="button" :class="[isPositionAdded ? 'ring-red-500 bg-red-500 text-white hover:bg-red-800' : 'ring-gray-300 hover:bg-gray-50 bg-white text-gray-900 ', 'flex items-center rounded-full px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset transition-all duration-300']">
                        Dodaj pozycję
                        <PlusIcon class="h-3 w-3 ml-1" aria-hidden="true" />
                    </button>
                </div> -->
                <div :class="['flex flex-col items-center justify-center gap-2 mt-6']">
                    <div class="font-medium w-full text-left">Wymienioną sumę prosimy przekazać na nasz rachunek bankowy</div>
                    <div class="font-medium w-full text-left">nr 42 1020 1068 0000 1102 0371 8830 w ciągu {{documentData.paymentDayAmount}} dni od daty otrzymania niniejszego wezwania.</div>
                </div>
                <div class="mt-6 text-xs">
                    Dokument wygenerowany elektronicznie, nie wymaga pieczęci ani podpisu.
                </div>
            </div>
        </div>
        <div v-else class="bg-white w-2/3 p-12 flex flex-col h-screen9/10">
            <div class="font-bold text-lg text-center">Wybierz okres, aby móc wygenerować notę odsetkową</div>
        </div>
        <div class="p-4 w-1/3 bg-slate-500">
            <div class="w-full">
                <div class="block text-sm font-medium leading-6 text-slate-200">Typ dokumentu:</div>
                <select v-model="documentData.typeOfInterestNote" placeholder="---" id="typeOfInterestNote" name="typeOfInterestNote" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                    <option value="standard">Nota odsetkowa</option>
                </select>
                <div class="block text-sm font-medium leading-6 text-slate-200 mt-2">Numer noty odsetkowej:</div>
                <input type="text" v-model="documentData.interestNoteName" disabled class="block w-full rounded-md opacity-60 border-slate-300 cursor-not-allowed shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Jan" />
                <div class="block text-sm font-medium leading-6 text-slate-200 mt-2">Data wystawienia noty odsetkowej:</div>
                <VueDatePicker placeholder="Wybierz datę wystawienia noty..." :format="documentData.interestNoteDeliveryDate" auto-apply locale="pl" v-model="date" :enable-time-picker="false" :clearable="false" @open="checkDate('open', 'delivery')" @closed="checkDate('closed', 'delivery')" @cleared="checkDate('cleared', 'delivery')" />
                <div class="block text-sm font-medium leading-6 text-slate-200 mt-2 ring-red-500">Okres:</div>
                <VueDatePicker placeholder="Wybierz okres noty..." :format="periodDateFormat" auto-apply locale="pl" @update:model-value="downloadUsers" v-model="periodDate" :enable-time-picker="false" :clearable="false" range @open="checkDate('open', 'period')" @closed="checkDate('closed', 'period')" @cleared="checkDate('cleared', 'period')" :class="datePickerClass" />
                <!-- <div class="block text-sm font-medium leading-6 text-slate-200 mt-2 ring-red-500">Okres:</div>
                <VueDatePicker placeholder="Wybierz okres noty..." :format="periodDateFormat" auto-apply locale="pl" v-model="periodDate" :enable-time-picker="false" :clearable="false" range @open="checkDate('open', 'period')" @closed="checkDate('closed', 'period')" @cleared="checkDate('cleared', 'period')" :class="datePickerClass" /> -->
                <div v-if="pickedUser !== null" :class="['mt-2', 'block text-sm font-medium leading-6 text-slate-200']">Czas płatności noty odsetkowej:</div>
                <select v-if="pickedUser !== null" v-model="documentData.paymentDayAmount" @change="changePayentDate()" placeholder="---" id="invoicePaymentTime" name="invoicePaymentTime" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                    <option v-for="days in 40" :key="days" :value="days">{{days}} dni</option>
                </select>
                <Listbox v-if="pickedUser !== null" class="mt-2" as="div" v-model="pickedUser">
                    <div class="block text-sm font-medium text-slate-200">Dłużnik:</div>
                    <div class="relative">
                    <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span class="flex items-center">
                        <span class="font-medium text-red-500">({{pickedUser.invoices.length}})</span>
                        <span class="ml-3 block truncate">{{ pickedUser.company.name }}</span>
                        </span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>
                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ListboxOption @click.stop="changeCompanyOnInvoice(pickedUser)" as="template" v-for="person in usersList" :key="person.id" :value="person" v-slot="{ active, pickedUser }">
                                <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', !person.activeInvoices ? 'opacity-30 pointer-events-none' : '', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <div :class="['flex items-center']">
                                        <span class="font-medium text-red-500">({{person.invoices.length}})</span>
                                        <span :class="[pickedUser ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                                        {{ person.company.name }}
                                        <span class="sr-only"> is {{ person.activeInvoices ? 'Aktywny' : 'Nieaktywny' }}</span>
                                        </span>
                                    </div>
                                    <span v-if="pickedUser" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                    </div>
                </Listbox>
                <div class="relative mt-4 mb-6">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                    </div>
                </div>
                <button @click.prevent="interestNoteGenerate()" type="button" :disabled="documentData.invoices.length === 0 || downloadingUsers" :class="[documentData.invoices.length === 0 ? 'pointer-events-none opacity-40 select-none' : '', 'w-full flex justify-center relative items-center gap-x-1.5 trasition-all duration-500 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md border border-indigo-700 hover:border-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm mt-4']">
                    <DocumentAddIcon v-if="periodDate.length > 0" class="-ml-0.5 h-5 w-5 text-white" aria-hidden="true" />
                    <span v-if="periodDate.length > 0 && !downloadingUsers">Utwórz notę</span>
                    <span v-if="periodDate.length === 0 && !downloadingUsers">Wybierz okres</span>
                    <span v-if="downloadingUsers">Pobieranie użytkowników...</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import EmptyState from '../components/EmptyState.vue'
import { XIcon, PlusIcon, ReplyIcon } from '@heroicons/vue/outline'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { HomeIcon, CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid'
import { DocumentAddIcon } from '@heroicons/vue/outline'
import {db} from "@/firebase/gfbconf.js";
// import axios from 'axios';
import moment, { relativeTimeRounding } from 'moment'
import currency from 'currency.js'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios from 'axios';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: "CreateInterestNote",
    components: {
        ReplyIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, CheckIcon, ChevronDownIcon, HomeIcon, DocumentAddIcon, XIcon, PlusIcon, EmptyState, VueDatePicker
    },
    data() {
        return {
            priceInPolish: "",
            loading: true,
            date: new Date(),
            periodDate: [],
            usersList: [],
            pickedUser: null,
            vatRates: {},
            products: [],
            errors: [],
            isPositionAdded: false,
            dbListener: null,
            downloadingUsers: false,
            documentData: {
                createdFor: {
                    interestNote: '',
                    date: new Date(),
                    errors: {
                        interestNote: false,
                        date: false
                    }
                },
                note: '',
                typeOfInterestNote: 'standard',
                active: false,
                created: null,
                firstDownload: null,
                interestNoteDeliveryDate: "",
                interestNoteName: "",
                netValueOfInterestNote: 0,
                paid: false,
                paidAmount: 0,
                payerId: "",
                payerNIP: "",
                payerName: "",
                paymentDate: "",
                paymentDayAmount: "",
                pdf: {
                    link: null,
                    ref: null
                },
                period: 'Brak',
                receiver: "",
                testInterestNote: false,
                invoices: [],
                valueOfInterestNote: 0,
                vatValueOfInterestNote: 0
            }
        }
    },
    methods: {
        periodDateFormat()
        {
            let date = '';
            let dateForFile = '';
            if(this.periodDate.length > 0)
            {
                date = `${moment(this.periodDate[0]).format('DD.MM.YYYY')} - ${moment(this.periodDate[1]).format('DD.MM.YYYY')}`
                dateForFile = `${moment(this.periodDate[0]).format('DD-MM-YYYY')} - ${moment(this.periodDate[1]).format('DD-MM-YYYY')}`
                this.documentData.period = dateForFile;
            }
            return date;
        },
        dateFormat()
        {
            let date = ''
            date = `${moment(this.documentData.createdFor.date).format('DD.MM.YYYY')}`
            return date;
        },
        calculateInterestNote() {
            let interestNote = 0;
            for(let i = 0; i < this.documentData.invoices.length; i++) {
                interestNote += this.documentData.invoices[i].interestValue;
            }
            return interestNote
        },
        async generateInterestNote(type)
        {
            try {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Generowanie noty odsetkowej...",
                    subheader: `Nie zamykaj okna.`,
                });
                if(this.documentData.invoices.length === 0)
                {
                    throw "Nie dodano żadnej pozycji do faktury.";
                };
                if(this.documentData.note.length === 0)
                {
                   this.documentData.note = "Brak uwag.";
                };
                this.documentData.valueOfInterestNote = this.calculateInterestNote();
                let statementData = {
                    type: type,
                    documentData: this.documentData,
                    vatRates: this.vatRates,
                    priceInPolish: this.priceInPolish,
                    user: this.pickedUser,
                };
                if(this.$store.state.transactions.uninvoiced != null && this.$store.state.transactions.uninvoiced.length > 0)
                {
                    statementData.transactionData = this.$store.state.transactions.uninvoiced;
                };
                if(this.documentData.createdFor.interestNote.length > 0)
                {
                    statementData.documentData.createdFor = {
                        invoice: this.documentData.createdFor.interestNote,
                        date: moment(this.documentData.createdFor.date).unix()
                    }
                };
                const generate = await axios.post(`${this.$store.state.apiLink}/generateInterestNote`, statementData);
                if(generate.data.success)
                {
                    // this.periodDate = [];
                    this.documentData.createdFor.interestNote = ""
                    this.documentData.createdFor.date = new Date()
                    this.vatRates = {}
                    this.priceInPolish = ""
                    this.documentData.netValueOfInterestNote = 0
                    this.documentData.paidAmount = 0
                    this.documentData.valueOfInterestNote = 0
                    this.documentData.vatValueOfInterestNote = 0
                    this.documentData.invoices = []
                    this.isPositionAdded = false
                    this.errors = [];
                    this.pickedUser = null
                    this.downloadUsers(this.periodDate)
                    // setTimeout(() => {
                    //     this.$router.push('/dashboard/interestNotes')
                    // }, 500);
                    setTimeout(() => {
                        this.$store.commit('resetGlobalLoader');
                    }, 1000);
                    setTimeout(() => {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Nota odsetkowa została wygenerowana.",
                            subheader: "Plik PDF został zapisany na serwerze.",
                            success: true
                        }); 
                    }, 1500);
                }
                else
                {
                    throw "Wystąpił błąd podczas generowania faktury.";
                }
            } catch (error)
            {
                console.log(error);
                setTimeout(() => {
                    this.$store.commit('resetGlobalLoader');
                }, 1000);
                setTimeout(() => {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: error,
                        subheader: "Spróbuj ponownie.",
                        success: false
                    }); 
                }, 1500);
            }
        },
        async interestNoteGenerate()
        {
            await this.generateInterestNote()
        },
        grossPriceInWord() {
            let grossPrice = Math.abs(this.calculateInterestNote()); // Use Math.abs to get the absolute value
            let zloty = Math.floor(grossPrice);
            let grosze = currency(currency((grossPrice - zloty) * 100, {precision: 4}).value, {precision: 2}).value;

            let wynikZloty = this.convertToWords(zloty);
            let wynikGrosze = this.convertToWords(grosze);

            if (zloty === 0) {
                wynikZloty = "zero złotych";
            } else if (zloty === 1) {
                wynikZloty += ' złoty';
            } else if (zloty > 1 && zloty < 5) {
                wynikZloty += ' złote';
            } else {
                wynikZloty += ' złotych';
            }

            if (grosze === 0) {
                wynikGrosze = "zero groszy";
            } else if (grosze === 1) {
                wynikGrosze += ' grosz';
            } else if (grosze > 1 && grosze < 5) {
                wynikGrosze += ' grosze';
            } else {
                wynikGrosze += ' groszy';
            }

            if (zloty % 10 > 1 && zloty % 10 < 5 && (zloty % 100 < 10 || zloty % 100 > 20)) {
                wynikZloty = wynikZloty.replace('złotych', 'złote');
            }

            if (grosze % 10 > 1 && grosze % 10 < 5 && (grosze % 100 < 10 || grosze % 100 > 20)) {
                wynikGrosze = wynikGrosze.replace('groszy', 'grosze');
            }

            this.priceInPolish = (this.documentData.valueOfInterestNote < 0 ? 'minus ' : '') + wynikZloty + ' ' + wynikGrosze;
        },
        convertToWords(amount) {
            let grossPrice = Math.abs(amount); // Use Math.abs to get the absolute value
            const jednosci = ['', 'jeden', 'dwa', 'trzy', 'cztery', 'pięć', 'sześć', 'siedem', 'osiem', 'dziewięć'];
            const nastki = ['', 'jedenaście', 'dwanaście', 'trzynaście', 'czternaście', 'piętnaście', 'szesnaście', 'siedemnaście', 'osiemnaście', 'dziewiętnaście'];
            const dziesiatki = ['', 'dziesięć', 'dwadzieścia', 'trzydzieści', 'czterdzieści', 'pięćdziesiąt', 'sześćdziesiąt', 'siedemdziesiąt', 'osiemdziesiąt', 'dziewięćdziesiąt'];
            const setki = ['', 'sto', 'dwieście', 'trzysta', 'czterysta', 'pięćset', 'sześćset', 'siedemset', 'osiemset', 'dziewięćset'];
            const tysiac = ['tysiąc', 'tysiące', 'tysięcy'];
            const milion = ['milion', 'miliony', 'milionów'];

            let wynik = '';
            let j = Math.floor(grossPrice % 10);
            let n = Math.floor((grossPrice % 100 - j) / 10);
            let d = Math.floor((grossPrice % 1000 - 10 * n - j) / 100);
            let t = Math.floor((grossPrice % 10000 - 100 * d - 10 * n - j) / 1000);
            let tt = Math.floor((grossPrice % 100000 - 1000 * t - 100 * d - 10 * n - j) / 10000);
            let tm = Math.floor((grossPrice % 1000000 - 10000 * tt - 1000 * t - 100 * d - 10 * n - j) / 100000);
            let tmn = Math.floor(grossPrice / 1000000);

            if (n === 1 && j > 0) {
                wynik = nastki[j];
            } else {
                wynik = dziesiatki[n] + ' ' + jednosci[j];
            }

            wynik = setki[d] + ' ' + wynik;

            if (tt === 1 && t > 0) {
                wynik = nastki[t] + ' ' + tysiac[2] + ' ' + wynik;
            } else {
                if (tt > 0) {
                    if (t > 0) {
                        if (t > 1 && t < 5) {
                            wynik = dziesiatki[tt] + ' ' + jednosci[t] + ' ' + tysiac[1] + ' ' + wynik;
                        } else {
                            wynik = dziesiatki[tt] + ' ' + jednosci[t] + ' ' + tysiac[2] + ' ' + wynik;
                        }
                    } else {
                        wynik = dziesiatki[tt] + ' ' + tysiac[2] + ' ' + wynik;
                    }
                } else if (t > 0) {
                    if (t === 1) {
                        wynik = jednosci[t] + ' ' + tysiac[0] + ' ' + wynik;
                    } else if (t > 1 && t < 5) {
                        wynik = jednosci[t] + ' ' + tysiac[1] + ' ' + wynik;
                    } else {
                        wynik = jednosci[t] + ' ' + tysiac[2] + ' ' + wynik;
                    }
                }
            }

            if (tm > 0) {
                wynik = setki[tm] + ' ' + wynik;
            }

            if (tmn > 0) {
                if (tmn === 1) {
                    wynik = jednosci[tmn] + ' ' + milion[0] + ' ' + wynik;
                } else if (tmn > 1 && tmn < 5) {
                    wynik = jednosci[tmn] + ' ' + milion[1] + ' ' + wynik;
                } else {
                    wynik = jednosci[tmn] + ' ' + milion[2] + ' ' + wynik;
                }
            }

            return wynik.trim();
        },
        resetErrors(type)
        {
            this.isPositionAdded = false;
            for(let i=0; i<this.documentData.invoices.length; i++)
            {
                if(type === 'standard')
                {
                    this.errors[i].name = false;
                    this.errors[i].quantity = false;
                    this.errors[i].unit = false;
                    this.errors[i].vatRate = false;
                    this.errors[i].netUnitPrice = false;
                }
            }
            this.documentData.createdFor.errors.invoice = false;
            this.documentData.createdFor.errors.date = false;
        },
        log(log){
            console.log(log)
        },
        async pickInterestNoteNumber(){
            let currentYear = moment().format("YYYY")

            if(this.dbListener !== null){
                this.dbListener()
            };
            this.dbListener = db.collection('InterestNoteGenerator').doc('interestNoteNumber')
            .onSnapshot((querySnapshot) => {
                let numbersData = querySnapshot.data()
                if(this.documentData.typeOfInterestNote === 'standard'){
                    let lastInvoiceNumber = null
                    if(numbersData[`${currentYear}`] !== undefined){
                        lastInvoiceNumber = numbersData[`${currentYear}`]
                    }else{
                        lastInvoiceNumber = 0
                    }
                    lastInvoiceNumber++
                    let currentInvoiceNumber = lastInvoiceNumber
                    let invoiceNumberToName = ""
                    if(currentInvoiceNumber.toString().length === 1){
                        invoiceNumberToName = "00" + currentInvoiceNumber.toString()
                    }
                    else if(currentInvoiceNumber.toString().length === 2){
                        invoiceNumberToName = "0" + currentInvoiceNumber.toString()
                    }
                    else{
                        invoiceNumberToName = currentInvoiceNumber.toString()
                    }
                    this.documentData.interestNoteName = `${invoiceNumberToName}`
                }
            });
        },
        changePayentDate(){
            this.documentData.paymentDate = moment(this.documentData.interestNoteDeliveryDate, "DD.MM.YYYY").add(this.documentData.paymentDayAmount, 'days').format("DD.MM.YYYY")
        },
        checkDate(val, type)
        {
            this.documentData.interestNoteDeliveryDate = moment(this.date).format("DD.MM.YYYY")
        },
        changeCompanyOnInvoice(user){
            this.documentData.payerId = user.id
            this.documentData.receiver = user.id
            this.documentData.payerNIP = user.company.nip
            this.documentData.payerName = user.company.name
            this.documentData.paymentDayAmount = user.invoicePaymentTime
            this.documentData.paymentDate = moment(this.documentData.interestNoteDeliveryDate, "DD.MM.YYYY").add(this.documentData.paymentDayAmount, 'days').format("DD.MM.YYYY")
            this.documentData.invoices = []
            this.documentData.invoices = user.invoices
            this.grossPriceInWord()
        },
        async downloadUsers(period){
            try {
                this.downloadingUsers = true
                const res = await axios.post(`${this.$store.state.apiLink}/downloadDebtors`, {
                    period: [moment(period[0]).unix(), moment(period[1]).unix()]
                })
                this.usersList = res.data.usersList
                if(this.usersList.length === 0) {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Nie można utworzyć żadnych not odsetkowych.",
                        subheader: "Brak spóźnionych faktur",
                        success: false
                    });
                    this.$router.push('/dashboard/interestNotes') 
                } else {
                    if(this.$store.state.transactions.user == null)
                    {
                        this.pickedUser = this.usersList[0]
                        this.changeCompanyOnInvoice(this.pickedUser)
                    }
                    else
                    {
                        this.pickedUser = this.$store.state.transactions.user
                        this.changeCompanyOnInvoice(this.pickedUser)
                    }
                }
                this.downloadingUsers = false
            } catch(error) {
                this.downloadingUsers = false
                console.log(error);
            }
        },
        nettoTotal() {
        let nettoTotal = 0
        for(let i = 0; i < this.documentData.invoices.length; i++) {
            let position = this.documentData.invoices[i]
            nettoTotal += position.netValue
        }
        return nettoTotal
        },
        vatTotal() {
        let vatTotal = 0
        for(let i = 0; i < this.documentData.invoices.length; i++) {
            let position = this.documentData.invoices[i]
            vatTotal += position.vatValue
        }
        return vatTotal
        },
        bruttoTotal() {
        let bruttoTotal = 0
        for(let i = 0; i < this.documentData.invoices.length; i++) {
            let position = this.documentData.invoices[i]
            bruttoTotal += position.grossValue
        }
        return bruttoTotal
        },
        addPosition() {
            this.isPositionAdded = false;
            let position = {
              id: '',
              invoiceNumber: '',
              invoiceDate: '',
              invoiceValue: '',
              invoicePaymentDate: '',
              dayDelay: '',
              interestRate: '',
            }
            this.documentData.invoices.push(position)
        },
        removePosition(positionIndex, type) {
            this.documentData.invoices.splice(positionIndex, 1)
            this.errors.splice(positionIndex, 1)
            // create for loop to change index of all positions
            for(let i = 0; i < this.documentData.invoices.length; i++) {
                if(this.documentData.invoices[i].index > positionIndex){
                    this.documentData.invoices[i].index -= 1
                }
            }
        },
    },
    async mounted(){
        this.loading = true
        // this.date = new Date();
        this.documentData.interestNoteDeliveryDate = moment(this.date).format("DD.MM.YYYY")
        this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Pobieram dane użytkowników..",
            subheader: `Nie zamykaj okna.`,
            success: false
        });
        this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Pobieram numer noty..",
            subheader: `Nie zamykaj okna.`,
            success: false
        });
        await this.pickInterestNoteNumber()
        // this.addPosition()
        this.$store.commit('resetGlobalLoader');
        this.loading = false
    },
    computed: {
        datePickerClass() {
            return this.documentData.createdFor.errors.date ? 'date-picker-border-while-error' : '';
        },
    },
    
    beforeUnmount() { 
        if(this.dbListener !== null){
            this.dbListener()
        }
    }
}

</script>
<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

    .date-picker-border-while-error {
        border: 2px solid;
        border-color: rgba(239, 68, 68);
        border-radius: 5px;
    }
</style>