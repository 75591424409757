<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div :class="['relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6']">
            <div v-if="invoicePeriods.length > 0">
                <div class="overflow-auto h-96">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Okres</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Ilość faktur</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                            <span class="sr-only">Edit</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white">
                        <tr v-for="(invoicePeriod, invoicePeriodIdx) in invoicePeriods" :key="invoicePeriod" :class="invoicePeriodIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{{ invoicePeriod[0] }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ invoicePeriod[1].length }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><input v-model="invoicePeriod[3]" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600" /></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="button" :disabled="preventSpam" :class="[preventSpam ? 'opacity-30 pointer-events-none' : '', 'items-center gap-x-1.5 transition ease-in-out duration-300 bg-blue-600 text-base font-medium hover:bg-blue-700 focus:ring-blue-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm']" @click.prevent="generateCSVStatement()">
                        <DocumentReportIcon class="-ml-0.5 h-6 w-6" aria-hidden="true" />
                        Generuj CSV
                    </button>
                    <button type="button" :disabled="preventSpam" :class="[preventSpam ? 'opacity-30 pointer-events-none' : '', 'items-center gap-x-1.5 transition ease-in-out duration-300 bg-blue-600 text-base font-medium hover:bg-blue-700 focus:ring-blue-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm']" @click.prevent="generateStatement()">
                        <DocumentDuplicateIcon class="-ml-0.5 h-6 w-6" aria-hidden="true" />
                        Generuj PDF
                    </button>
                    <button type="button" class="items-center mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Zamknij</button>
                </div>
            </div>
          <LoadingSpinnerHub class="h-96" v-else />
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import {db} from "@/firebase/gfbconf.js";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { DocumentReportIcon, DocumentDuplicateIcon } from '@heroicons/vue/outline';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
import currency from 'currency.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  components: {
    LoadingSpinnerHub, DocumentReportIcon, DocumentDuplicateIcon, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
  },
  props: ["modalData"],
  data() {
    return {
        invoicePeriods: {},
        preventSpam: false,
    }
  },
  methods: {
    generateStatement(){
        this.preventSpam = true;
        let invoicesToGenerate = []
        let pickedPeriods = []
        let allNetValue = 0
        let allVatValue = 0
        let allGrossValue = 0
        let paidAmount = 0

        for (let i = 0; i < this.invoicePeriods.length; i++) {
            let period = this.invoicePeriods[i]
            if(period[3]){
                pickedPeriods.push(period[0])
                for (let f = 0; f < period[1].length; f++) {
                    let invoice = period[1][f]
                    invoice.grossValue = invoice.valueOfInvoice
                    invoice.vatValue = invoice.vatValueOfInvoice
                    invoice.netValue = invoice.netValueOfInvoice
                    paidAmount += invoice.paidAmount
                    paidAmount = Math.round(paidAmount * 100) / 100

                    invoicesToGenerate.push(invoice)
                    // for (let t = 0; t < invoice.transactions.length; t++) {
                    //     let transaction = invoice.transactions[t]
                    //     invoice.grossValue += transaction.grossValue
                    //     invoice.vatValue += transaction.vatValue
                    //     invoice.netValue += transaction.netValue
                    //     // invoice.grossValue = Math.round(invoice.grossValue * 100) / 100
                    //     // invoice.vatValue = Math.round(invoice.vatValue * 100) / 100
                    //     // invoice.netValue = Math.round(invoice.netValue * 100) / 100

                    //     allNetValue += transaction.netValue
                    //     allVatValue += transaction.vatValue
                    //     allGrossValue += transaction.grossValue
                    //     // allNetValue = Math.round(allNetValue * 100) / 100
                    //     // allVatValue = Math.round(allVatValue * 100) / 100
                    //     // allGrossValue = Math.round(allGrossValue * 100) / 100
                    // }
                    // allNetValue = currency(currency(allGrossValue / (parseFloat(0.23) * 100 + 100) * 100,  {precision: 4}).value, {precision: 2}).value
                    // allVatValue = currency(currency(allGrossValue - allNetValue,  {precision: 4}).value, {precision: 2}).value
                    allNetValue += invoice.netValueOfInvoice
                    allVatValue += invoice.vatValueOfInvoice
                    allGrossValue += invoice.valueOfInvoice
                }
            }
        }
        if(invoicesToGenerate.length > 0){
        let invoicesTable = [
                    [
                        {
                        text: 'Lp.',
                        border: [false, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Faktura',
                        fillColor: '#eaf2f5',
                        alignment: 'center',
                        border: [true, true, true, true],
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Firma',
                        fillColor: '#eaf2f5',
                        alignment: 'center',
                        border: [true, true, true, true],
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Okres',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Wartość netto',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Wartość vat',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Wartość brutto',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Data płatności',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 5, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Opłacona',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        },
                        {
                        text: 'Opłacona kwota',
                        border: [true, true, true, true],
                        alignment: 'center',
                        fillColor: '#eaf2f5',
                        margin: [-9, 10, -9, 5],
                        textTransform: 'uppercase',
                        fontSize: 8,
                        }
                    ]
                    ]


                    for (let t = 0; t < invoicesToGenerate.length; t++) {
                        let crntInvoice = invoicesToGenerate[t]
                        invoicesTable.push([
                            {
                            text: `${t+1}`,
                            border: [false, true, true, true],
                            
                            margin: [-9, 5, -5, 5],
                            alignment: 'right',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntInvoice.invoiceName}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntInvoice.payerName}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntInvoice.period}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntInvoice.netValue.toFixed(2)} PLN`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntInvoice.vatValue.toFixed(2)} PLN`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntInvoice.grossValue.toFixed(2)} PLN`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntInvoice.paymentDate}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${!crntInvoice.paid && crntInvoice.paidAmount > 0 ? 'Częściowo' : crntInvoice.paid ? 'Tak' : 'Nie'}`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            },
                            {
                            border: [true, true, true, true],
                            text: `${crntInvoice.paidAmount.toFixed(2)} PLN`,
                            fillColor: '#f5f5f5',
                            margin: [-9, 5, -9, 5],
                            alignment: 'center',
                            fontSize: 8,
                            }
                        ])
                    }

        let docDefinition = {
                pageSize: 'A4',
                pageOrientation: 'landscape',
            content: [
                {
                columns: [
                    [
                    {
                        text: 'Zestawienie faktur',
                        color: '#333333',
                        width: '*',
                        fontSize: 16,
                        bold: true,
                        alignment: 'center',
                        margin: [0, 10, 0, 10],
                    }
                    ],
                ],
                },
                {
                columns: [
                    {
                    text: 'Wybrane okresy',
                    color: '#aaaaab',
                    bold: true,
                    fontSize: 12,
                    alignment: 'left',
                    margin: [0, 15, 0, 2],
                    }
                ],
                },
                {
                columns: [
                    {
                    text: `${pickedPeriods.toString().replaceAll(',', '\n')}`,
                    bold: true,
                    fontSize: 10,
                    color: '#333333',
                    alignment: 'left',
                    }
                ],
                },
                '\n',
                {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function(i, node) {
                    return 1;
                    },
                    vLineWidth: function(i, node) {
                    return 1;
                    },
                    hLineColor: function(i, node) {
                    if (i === 1 || i === 0) {
                        return '#bfdde8';
                    }
                    return '#eaeaea';
                    },
                    vLineColor: function(i, node) {
                    return '#eaeaea';
                    },
                    hLineStyle: function(i, node) {
                    // if (i === 0 || i === node.table.body.length) {
                    return null;
                    //}
                    },
                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    paddingLeft: function(i, node) {
                    return 10;
                    },
                    paddingRight: function(i, node) {
                    return 10;
                    },
                    paddingTop: function(i, node) {
                    return 2;
                    },
                    paddingBottom: function(i, node) {
                    return 2;
                    },
                    fillColor: function(rowIndex, node, columnIndex) {
                    return '#fff';
                    },
                },
                table: {
                    headerRows: 1,
                    dontBreakRows: true,
                    widths: [1,70,'*',80,45,45,45,30,25,45],
                    body: invoicesTable,
                },
                },
                { stack:[
                {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function(i, node) {
                    return 1;
                    },
                    vLineWidth: function(i, node) {
                    return 1;
                    },
                    hLineColor: function(i, node) {
                    return '#eaeaea';
                    },
                    vLineColor: function(i, node) {
                    return '#eaeaea';
                    },
                    hLineStyle: function(i, node) {
                    // if (i === 0 || i === node.table.body.length) {
                    return null;
                    //}
                    },
                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    paddingLeft: function(i, node) {
                    return 10;
                    },
                    paddingRight: function(i, node) {
                    return 10;
                    },
                    paddingTop: function(i, node) {
                    return 3;
                    },
                    paddingBottom: function(i, node) {
                    return 3;
                    },
                    fillColor: function(rowIndex, node, columnIndex) {
                    return '#fff';
                    },
                },
                table: {
                    headerRows: 1,
                    dontBreakRows: true,
                    widths: ['66%', '34%'],
                    body: [
                    [
                        {
                        text: 'Wartość netto',
                        border: [false, true, false, true],
                        alignment: 'right',
                        margin: [0, 5, 0, 5],
                        },
                        {
                        border: [false, true, false, true],
                        text: `${allNetValue.toFixed(2)} PLN`,
                        alignment: 'right',
                        fillColor: '#f5f5f5',
                        margin: [0, 5, 0, 5],
                        },
                    ],
                    [
                        {
                        text: 'Wartość VAT',
                        border: [false, false, false, true],
                        alignment: 'right',
                        margin: [0, 5, 0, 5],
                        },
                        {
                        text: `${allVatValue.toFixed(2)} PLN`,
                        border: [false, false, false, true],
                        fillColor: '#f5f5f5',
                        alignment: 'right',
                        margin: [0, 5, 0, 5],
                        },
                    ],
                    [
                        {
                        text: 'Wartość brutto',
                        border: [false, false, false, true],
                        alignment: 'right',
                        margin: [0, 5, 0, 5],
                        },
                        {
                        text: `${allGrossValue.toFixed(2)} PLN`,
                        border: [false, false, false, true],
                        fillColor: '#f5f5f5',
                        alignment: 'right',
                        margin: [0, 5, 0, 5],
                        },
                    ],
                    [
                        {
                        text: 'Pozostało do opłacenia',
                        bold: true,
                        fontSize: 16,
                        alignment: 'right',
                        border: [false, false, false, true],
                        margin: [0, 5, 0, 5],
                        },
                        {
                        text: `${(Math.round((allGrossValue - paidAmount) * 100) / 100).toFixed(2)} PLN`,
                        bold: true,
                        fontSize: 16,
                        alignment: 'right',
                        border: [false, false, false, true],
                        fillColor: '#f5f5f5',
                        margin: [0, 5, 0, 5],
                        },
                    ],
                    ],
                },
                },
                ],
                unbreakable: true
                }
            ],
            styles: {
            },
            defaultStyle: {
                columnGap: 20,
                //font: 'Quicksand',
            },
        };

        pdfMake.createPdf(docDefinition).download(`Zestawienie_faktur.pdf`);
        setTimeout(() => {
            this.close()
        }, 500);
        }else{
            alert("Aby wygenerować zestawienie do pliku PDF, musisz wybrać okres rozliczeniowy")
        }

    },
    generateCSVStatement()
    {
        this.preventSpam = true;
        let invoicesToGenerate = []
        let pickedPeriods = []
        let allNetValue = 0
        let allVatValue = 0
        let allGrossValue = 0
        let paidAmount = 0

        let statementHeaders = {
            index: 'Lp.',
            invoiceName: 'Faktura',
            companyName: 'Firma',
            timePeriod: 'Okres',
            netValue: 'Wartość netto',
            vatValue: 'Wartość vat',
            gross: 'Wartość brutto',
            paymentDate: 'Data płatności',
            isPaid: 'Opłacona',
            paidValue: 'Opłacona kwota'
        };

        const statementCSV = [
            statementHeaders,
        ];

        for (let i = 0; i < this.invoicePeriods.length; i++) {
            let period = this.invoicePeriods[i]
            if(period[3]){
                pickedPeriods.push(period[0])
                for (let f = 0; f < period[1].length; f++) {
                    let invoice = period[1][f]
                    invoice.grossValue = invoice.valueOfInvoice
                    invoice.vatValue = invoice.vatValueOfInvoice
                    invoice.netValue = invoice.netValueOfInvoice
                    paidAmount += invoice.paidAmount
                    paidAmount = Math.round(paidAmount * 100) / 100

                    invoicesToGenerate.push(invoice)
                    // for (let t = 0; t < invoice.transactions.length; t++) {
                    //     let transaction = invoice.transactions[t]
                    //     invoice.grossValue += transaction.grossValue
                    //     invoice.vatValue += transaction.vatValue
                    //     invoice.netValue += transaction.netValue
                    //     // invoice.grossValue = Math.round(invoice.grossValue * 100) / 100
                    //     // invoice.vatValue = Math.round(invoice.vatValue * 100) / 100
                    //     // invoice.netValue = Math.round(invoice.netValue * 100) / 100

                    //     allNetValue += transaction.netValue
                    //     allVatValue += transaction.vatValue
                    //     allGrossValue += transaction.grossValue
                    //     // allNetValue = Math.round(allNetValue * 100) / 100
                    //     // allVatValue = Math.round(allVatValue * 100) / 100
                    //     // allGrossValue = Math.round(allGrossValue * 100) / 100
                    // }
                    // allNetValue = currency(currency(allGrossValue / (parseFloat(0.23) * 100 + 100) * 100,  {precision: 4}).value, {precision: 2}).value
                    // allVatValue = currency(currency(allGrossValue - allNetValue,  {precision: 4}).value, {precision: 2}).value
                    allNetValue += invoice.netValueOfInvoice
                    allVatValue += invoice.vatValueOfInvoice
                    allGrossValue += invoice.valueOfInvoice
                }
            }
        }
        
        if(invoicesToGenerate.length > 0)
        {
            for (let t = 0; t < invoicesToGenerate.length; t++)
            {
                let crntInvoice = invoicesToGenerate[t]
                let statementLine = {
                    index: `${t+1}`,
                    invoiceName: `${crntInvoice.invoiceName}`,
                    companyName: `${crntInvoice.payerName}`,
                    timePeriod: `${crntInvoice.period}`,
                    netValue: `${crntInvoice.netValue.toFixed(2)} PLN`,
                    vatValue: `${crntInvoice.vatValue.toFixed(2)} PLN`,
                    gross: `${crntInvoice.grossValue.toFixed(2)} PLN`,
                    paymentDate: `${crntInvoice.paymentDate}`,
                    isPaid: `${!crntInvoice.paid && crntInvoice.paidAmount > 0 ? 'Częściowo' : crntInvoice.paid ? 'Tak' : 'Nie'}`,
                    paidValue: `${crntInvoice.paidAmount.toFixed(2)} PLN`
                }
                statementCSV.push(statementLine)
            }
            const csvMaker = (data) => {
                let csvRows = [];
                for(let i=0;i<data.length; i++)
                {
                    const values = Object.values(data[i]).join(';');
                    csvRows.push(values)
                }
                return csvRows.join('\n')
            };
            const download = (data) => {
                const blob = new Blob([data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('download', `EXCEL-CSV_Zestawienie_faktur.csv`);
                a.click()
            };
            const csvdata = csvMaker(statementCSV);
            download(csvdata);
            setTimeout(() => {
                this.close()
            }, 500);
        }
        else
        {
            alert("Aby wygenerować zestawienie do pliku CSV, musisz wybrać okres rozliczeniowy")
        }
    },
    log(log){
        console.log(log)
    },
    emitClose()
    {
        this.paidAmount = 0;
        this.inputDisabled = false;
        this.fullAmountChecked = false;
        this.$emit("close");
    },
    close()
    {
        this.preventSpam = false;
        this.emitClose();
        this.open = false
    },
    async downloadInvoices(){
        let invoices = await db.collection("Invoices").orderBy("created.unix", "desc").get()
        for (let i = 0; i < invoices.docs.length; i++) {
            let invoice = invoices.docs[i].data()
            // if(invoice.active){
                if(this.invoicePeriods[invoice.period] === undefined){
                this.invoicePeriods[invoice.period] = {
                    invoices: []
                }
                this.invoicePeriods[invoice.period].invoices.push(invoice)
            }else{
                this.invoicePeriods[invoice.period].invoices.push(invoice)
            }
            // }
        }
        let sortable = []
        let invoicePeriodsByName = Object.keys(this.invoicePeriods)
        for (let x = 0; x < invoicePeriodsByName.length; x++) {
            sortable.push([invoicePeriodsByName[x], this.invoicePeriods[invoicePeriodsByName[x]].invoices, this.invoicePeriods[invoicePeriodsByName[x]].invoices[0].created.unix, false])
        }
        sortable.sort(function(a, b) {
            return a[2] - b[2];
        });
        sortable.reverse()
        this.invoicePeriods = sortable
    }
  },
  computed: {
  },
  created(){
    this.downloadInvoices()
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  }
}
</script>

<style scoped>


</style>
