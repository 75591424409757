<template>
    <div v-if="loaded">
        <nav class="flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                <li class="flex">
                    <div class="flex items-center">
                    <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </router-link>
                    </div>
                </li>
                <li class="flex">
                    <router-link to="/dashboard/users" class="flex">
                        <div class="flex items-center">
                            <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                            </svg>
                            <div class="ml-4 text-xs font-medium text-slate-100 hover:text-slate-300 cursor-pointer transition ease-in-out duration-300 select-none" aria-current="page">Użytkownicy</div>
                        </div>                    
                    </router-link>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">{{user.name}} {{user.surname}}</div>
                    </div> 
                </li>
            </ol>
        </nav>
        <div>
            <!-- v-if="!user.permissions.admin" -->
            <div class="overflow-hidden bg-slate-500 shadow sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                <h3 class="text-lg font-medium leading-6 text-slate-200">Dane podstawowe użytkownika</h3>
                <p class="mt-1 max-w-2xl text-sm text-slate-100">Dowiedz się więcej o użytkowniku.</p>
                </div>
                <div class="border-t border-slate-600 text-slate-700 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-slate-600">
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Imię Nazwisko</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.name}} {{user.surname}}</dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Telefon</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.contact.phone}}</dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Email</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.contact.email}}</dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Utworzony</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.meta.created.asText}}</dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Typ konta</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">
                        <span v-for="prem in createPermissionsList(user)" :key="prem">
                            <span class="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800 mr-1">{{prem}}</span>
                        </span>
                    </dd>
                    </div>
                </dl>
                
                </div>
            </div>

            <div class="overflow-hidden bg-slate-500 shadow sm:rounded-lg mt-4" v-if="!user.permissions.admin">
                <div class="px-4 py-5 sm:px-6">
                <h3 class="text-lg font-medium leading-6 text-slate-200">Dane firmy</h3>
                <p class="mt-1 max-w-2xl text-sm text-slate-100">Dowiedz się więcej o firmie.</p>
                </div>
                <div class="border-t border-slate-600 text-slate-700 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-slate-600">
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Nazwa firmy</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.company.name.length > 0 ? user.company.name : '--'}}</dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">NIP</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.company.nip.length > 0 ? user.company.nip : '--'}}</dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Adres</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.company.address.length > 0 ? user.company.address : '--'}}</dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Kod pocztowy</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.company.postCode.length > 0 ? user.company.postCode : '--'}}</dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Miejscowość</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.company.city.length > 0 ? user.company.city : '--'}}</dd>
                    </div>
                </dl>
                
                </div>
            </div>

            <div class="overflow-hidden bg-slate-500 shadow sm:rounded-lg mt-4" v-if="!user.permissions.admin">
                <div class="px-4 py-5 sm:px-6">
                <h3 class="text-lg font-medium leading-6 text-slate-200">Przypisane karty</h3>
                <p class="mt-1 max-w-2xl text-sm text-slate-100">Dowiedz się jakie karty są przypisane do tego użytkownika.</p>
                </div>
                <div class="border-t border-slate-600 text-slate-700 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-slate-600">
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt class="text-sm font-medium text-slate-200">Grupa kart</dt>
                    <dd class="mt-1 text-sm text-slate-100 sm:col-span-2 sm:mt-0">{{user.groupOfCards.length > 0 ? user.groupOfCards : '--'}}</dd>
                    </div>
                </dl>
                
                </div>
            </div>

            <!-- <div v-if="user.permissions.admin">
                <span class="text-xs text-slate-100 select-none">To konto należy administratora. Brak informacji.</span>
            </div> -->
            <div>
                <span class="mt-1 text-xs text-slate-300"><span class="select-none">ID:</span> {{user.id}}</span>
            </div>

        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    //import {db,storage} from "@/firebase/gfbconf.js"
    import { HomeIcon } from '@heroicons/vue/solid'
    export default {
        name: "UserDetails",
        data()
        {
            return {
                loaded: false,
                user: null
            }
        },
        components: {HomeIcon},
        async created()
        {
            if(!this.$store.state.userData.permissions.admin)
            {
                this.forcedClose()
            }
            if(this.$route.query.id !== undefined && this.$route.query.id !== null)
            {
                if(this.$route.query.id.length === 0) this.forcedClose()
                try{
                    const userResult = await axios.post(`${this.$store.state.apiLink}/getUser`, {
                        whoAmI: this.$store.state.userData.id,
                        userId: this.$route.query.id,
                    })
                    if(userResult.data.success === false) this.forcedClose();
                    this.loaded = true;
                    this.user = userResult.data.user;
                    document.title = `Szczegóły konta ${this.user.name} ${this.user.surname} - Lauron Sp. z o.o.`
                }
                catch(error)
                {
                    console.log(error);
                    this.forcedClose();
                }
            }
            else
            {
                this.forcedClose();
            }

        },
        methods:
        {
            createPermissionsList(usr)
            {
                const permissions = Object.entries(usr.permissions);
                let translatedListOfPermissions = [];
                permissions.forEach(element => {
                    if(element[1] === true)
                    {
                        translatedListOfPermissions.push(element[0].toUpperCase());
                    }
                })

                return translatedListOfPermissions
            },
            forcedClose()
            {
                this.$router.push("/hub");
            }
        },
        computed:
        {
        }
    }
</script>
<style lang="scss" scoped>

</style>