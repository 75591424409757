<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
            <div class="overflow-auto h-96">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Okres</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Ilość faktur</th>
                        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                        <span class="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr v-for="(invoicePeriod, invoicePeriodIdx) in invoicePeriods" :key="invoicePeriod" :class="invoicePeriodIdx % 2 === 0 ? undefined : 'bg-gray-50'">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{{ invoicePeriod[0] }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ invoicePeriod[1].length }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button @click.prevent="generateCSV(invoicePeriod[1], invoicePeriod[0])" v-if="invoicePeriod[1][0].payerNIP !== undefined" type="button" class="rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">Generuj CSV</button>
                            <span class="text-gray-200" v-else>Brak danych</span>
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button @click.prevent="generateEDI(invoicePeriod[1], invoicePeriod[0])" :disabled="checkPeriod(invoicePeriod[0])" v-if="invoicePeriod[1][0].payerNIP !== undefined" type="button" class="rounded px-2 py-1 text-sm font-semibold text-white shadow-sm bg-blue-500 hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" :class="checkPeriod(invoicePeriod[0]) ? 'opacity-70' : ''">Generuj EDI</button>
                            <span class="text-gray-200" v-else>Brak danych</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Zamknij</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import {db} from "@/firebase/gfbconf.js";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import moment from 'moment';
import randomstring from 'randomstring';
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: ["modalData"],
  data() {
    return {
        invoicePeriods: {}
    }
  },
  methods: {
    checkPeriod(period) {
        if(moment(period.slice(0, period.indexOf(' - ')), 'DD-MM-YYYY').unix() >= 1712527200 && moment(period.slice(period.indexOf(' - ')+3), 'DD-MM-YYYY').unix() >= 1712527200) {
            return false
        } else {
            return true
        }
    },
    log(log){
        console.log(log)
    },
    async generateEDI(invoicesData, period) {
        let content = `
[Info]
Program=Lauron
Kodowanie=Windows 1250
Nazwa=WB TANK SP. Z O.O.                         UDORPIE, ul. Chełmińska 3                  77-100 BYTÓW                               BDO000386131
Nip=8421761290
Konto=PKO                                     92 1020 4708 0000 7202 0048 0806
Kod=77-100
Miasto=BYTÓW
Data=${moment().format('YY.MM.DD')}
Godz=${moment().format('HH:mm:ss')}

[Okres]
Poczatek=${moment((period.slice(0, period.indexOf(' - '))).replaceAll('-', '.'), 'DD.MM.YYYY').format('YY.MM.DD')}
Koniec=${moment((period.slice(period.indexOf(' - ')+3)).replaceAll('-', '.'), 'DD.MM.YYYY').format('YY.MM.DD')}
        
        `
        
        for(let i = 0; i < invoicesData.length; i++) {
            let invoice = invoicesData[i]
            let invoiceContent = `
[Dokument]
Rejestr=FakturySpr
TypDok=2
DataSprzed=${moment(invoice.invoiceDeliveryDate, 'DD.MM.YYYY').format('YY.MM.DD')}
DataWyst=${moment(invoice.invoiceDeliveryDate, 'DD.MM.YYYY').format('YY.MM.DD')}
Termin=${invoice.paymentDayAmount}
NrFilii=0
StanDok=70
NazwiskoOdbiorcy=
NrWZ=
NrOdbiorcy=0
SymbolOdbiorcy=${invoice.payerNIP}
NazwaOdbiorcy=${invoice.payerName}
AdresOdbiorcy=${invoice.payerAddress == null || invoice.payerAddress.length === 0 ? 'brak' : invoice.payerAddress}
Gotowka=0.00
Karta=0.00
Bon=0.00
Czek=0.00
Godzina=${moment.unix(invoice.created.unix).format('HH:mm:ss')}
WalutaNr=0
WalutaNazwa=
WalutaKurs=1.0000
WalutaTabela=
WalutaData=${moment(invoice.invoiceDeliveryDate, 'DD.MM.YYYY').format('YY.MM.DD')}
RejKsiegowy=0
NrDok=${invoice.invoiceName}
IdentyfikatorDok=
NrKontrahenta=
NrPlatnosci=
SposobPlatnosci=przelew
NazwaKontrahenta=${invoice.payerName}
AdresKontrahenta=${invoice.payerAddress == null || invoice.payerAddress.length === 0 ? 'brak' : invoice.payerAddress}
SymbolKontrahenta=${invoice.payerNIP}
Nip=${invoice.payerNIP}
Netto=${invoice.netValueOfInvoice}
Vat=${invoice.vatValueOfInvoice}
Brutto=${invoice.valueOfInvoice}
Inicjaly=RP
Uwagi=
GTU=GTU_2; 
WartWCenachZakupuNetto=0.00
Vat0_Index=33
Vat0_Procent=23
Vat0_Stawka=23 %
Vat0_Netto=${invoice.netValueOfInvoice}
Vat0_Brutto=${invoice.valueOfInvoice}
Vat0_Vat=${invoice.vatValueOfInvoice}
Vat0_NettoZakup=0.00
Vat0_BruttoZakup=0.00
Vat0_VatZakup=0.00
Magazyny=9
[ZawartoscDokumentu]`
            content += invoiceContent
            for(let y = 0; y < invoice.transactions.length; y++) {
                let transaction = invoice.transactions[y]
                let positionContent =`
[Poz${y+1}]
Nazwa=${transaction.name}
Symbol=${transaction.productCode == null || transaction.productCode.length === 0 ? 'brak' : transaction.productCode}
Jm=${transaction.unit}
Ilosc=${transaction.quantity}
CenaNetto=${transaction.netUnitPrice}
CenaBrutto=${transaction.grossUnitPrice}
WartoscNetto=${transaction.netValue}
WartoscBrutto=${transaction.grossValue}
Vat=${transaction.vatRate}
WartoscVat=${transaction.vatValue}
CenaZakupuNetto=0.00
WartoscZakupuNetto=0.00
WartoscZakupuVat=0.00
Rabat=0.000
Grupa=0
NrCeny=1
PKWIU=
CenaWaluta=${transaction.netUnitPrice}
WartoscWaluta=${transaction.netValue}
CenaPrzedRabatemNetto=${transaction.netUnitPrice}
CenaPrzedRabatemBrutto=${transaction.netValue}
UwagiPoz=
GTU=${transaction.gtu == null || transaction.gtu.length === 0 ? '' : transaction.gtu}
SymbolAktualny=${transaction.productCode == null || transaction.productCode.length === 0 ? 'brak' : transaction.productCode}
SymbolDostawcy=
Nazwa=${transaction.name}
NazwaDlaKasy=
Blokady=0
Opis=
Marza=0.000
Marza2=0.000
Kategoria=
GrupaNazwa=
Indeks=${transaction.productCode == null || transaction.productCode.length === 0 ? 'brak' : transaction.productCode}
Mag=0
`
                content += positionContent
            }
        }
        const key = randomstring.generate({length: 10, charset: 'alphabetic'});
        let filename = `Faktury_${period.replace(' - ', '-')}_${key}.edi`

        const blob = new Blob([content], { type: 'text/edi' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', filename);
        a.click()


    },
    async generateCSV(invoicesData, period)
    {
        let invoiceHeader = {
            index: 'L.p.',
            date: 'Data',
            executionDate: 'Data_wykonania',
            invoiceNumber: 'Numer_faktury',
            nip: 'NIP',
            netValue: 'Wartosc_netto',
            vatValue: 'Wartosc_VAT',
            grossValue: 'Wartosc_brutto',
            paymentDayAmount: 'Termin_platnosci',
            paymentForm: 'Forma_platnosci',
        };

        const invoices = [
            invoiceHeader
        ];

        let invoice = {
            index: null,
            date: '',
            executionDate: '',
            invoiceNumber: '',
            nip: '',
            netValue: null,
            vatValue: null,
            grossValue: null,
            paymentDayAmount: null,
            paymentForm: '',
        };
        
        for(let i=0; i<invoicesData.length; i++) {
            if(invoicesData[i].vatValueOfInvoice === undefined && invoicesData[i].netValueOfInvoice === undefined){
                invoicesData[i].vatValueOfInvoice = 0
                invoicesData[i].netValueOfInvoice = 0
                for (let y = 0; y < invoicesData[i].transactions.length; y++) {
                    invoicesData[i].netValueOfInvoice = invoicesData[i].netValueOfInvoice + invoicesData[i].transactions[y].netValue
                    invoicesData[i].vatValueOfInvoice = invoicesData[i].vatValueOfInvoice + invoicesData[i].transactions[y].vatValue
                }
                invoicesData[i].vatValueOfInvoice = Math.round(invoicesData[i].vatValueOfInvoice * 100) / 100
                invoicesData[i].netValueOfInvoice = Math.round(invoicesData[i].netValueOfInvoice * 100) / 100
            }
            invoice = {
                index: i+1,
                date: `${invoicesData[i].invoiceDeliveryDate}`,
                executionDate: `${invoicesData[i].invoiceDeliveryDate}`,
                invoiceNumber: `${invoicesData[i].invoiceName}`,
                nip: `${invoicesData[i].payerNIP}`,
                netValue: invoicesData[i].netValueOfInvoice.toFixed(2),
                vatValue: invoicesData[i].vatValueOfInvoice.toFixed(2),
                grossValue: invoicesData[i].valueOfInvoice.toFixed(2),
                paymentDayAmount: invoicesData[i].paymentDayAmount,
                paymentForm: 'przelew',
            };
            invoices.push(invoice)
        }
        const csvdata = this.csvmaker(invoices);
        this.download(csvdata, period);
    },
    csvmaker(data)
    {
        let csvRows = [];
        for(let i=0;i<data.length; i++)
        {
            const values = Object.values(data[i]).join(';');
            csvRows.push(values)
        }
        return csvRows.join('\n')
    },
    download(data, period)
    {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let counter = 0;
        while (counter < 8)
        {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
            counter += 1;
        }
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', `Faktury_${period.replace(' - ', '-')}_${result}.csv`);
        a.click()
    },
    emitClose()
    {
        this.$emit("close");
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
    async downloadInvoices(){
        let invoices = await db.collection("Invoices").orderBy("created.unix", "desc").get()
        for (let i = 0; i < invoices.docs.length; i++) {
            let invoice = invoices.docs[i].data()
                if(this.invoicePeriods[invoice.period] === undefined){
                    this.invoicePeriods[invoice.period] = {
                        invoices: []
                    }
                    this.invoicePeriods[invoice.period].invoices.push(invoice)
                } else{
                    this.invoicePeriods[invoice.period].invoices.push(invoice)
                }
        }
        let sortable = []
        let invoicePeriodsByName = Object.keys(this.invoicePeriods)
        for (let x = 0; x < invoicePeriodsByName.length; x++) {
            sortable.push([invoicePeriodsByName[x], this.invoicePeriods[invoicePeriodsByName[x]].invoices, this.invoicePeriods[invoicePeriodsByName[x]].invoices[0].created.unix])
        }
        sortable.sort(function(a, b) {
            return a[2] - b[2];
        });
        sortable.reverse()
        this.invoicePeriods = sortable
    }
  },
  computed: {
  },
  created(){
    this.downloadInvoices()
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  }
}
</script>

<style scoped>


</style>
