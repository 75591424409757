<template>
  <div>
    <div class="flex justify-between">
        <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                <li class="flex">
                    <div class="flex items-center">
                    <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Tłumaczenia produktów</div>
                    </div>
                </li>
            </ol>
            <!-- <div @click.prevent="copyUsers()">Copy users</div> -->
            <!-- <div @click.prevent="createAmountOfInvoicesInMonth()">AmountOfInvoicesInMonth</div> -->
        </nav>
        <div class="flex items-center sm:hidden">
            <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-purple-300 hover:text-purple-400" aria-current="page">&larr; Cofnij</router-link>
        </div>
    </div>
    <!-- Twój kod HTML tutaj -->
    <div class="block mt-4">
    <nav class="isolate flex divide-x divide-slate-700 rounded-t-lg shadow" aria-label="Tabs">
        <div @click="tableType = 'normal'" :class="[tableType === 'normal' ? 'text-slate-100 bg-slate-900' : 'text-slate-300 bg-slate-800 hover:bg-slate-900 hover:text-slate-100', 'transition ease-in-out duration-200 rounded-tl-lg group select-none relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10 cursor-pointer']">
        <span> Lista produktów</span>
        <span aria-hidden="true" :class="[tableType === 'normal' ? 'bg-indigo-300' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
        </div>
        <div @click="tableType = 'fee'" :class="[tableType === 'fee' ? 'text-slate-100 bg-slate-900' : 'text-slate-300 bg-slate-800 hover:bg-slate-900 hover:text-slate-100', 'transition ease-in-out duration-200 rounded-tr-lg group select-none relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10 cursor-pointer']">
        <span> Lista produktów z prowizji </span>
        <span aria-hidden="true" :class="[tableType === 'fee' ? 'bg-indigo-300' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
        </div>
    </nav>
    </div>
    <div v-if="tableType === 'normal'" class="px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden min-h-64 shadow ring-1 ring-black ring-opacity-5 rounded-b-lg">
                        <table class="min-w-full divide-y divide-slate-700">
                            <thead class="bg-slate-700">
                                <tr>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold whitespace-nowrap text-slate-100 sm:pl-6">Kod produktu</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Nazwa Shell</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Nazwa na fakturze</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">GTU</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                        <div class="flex justify-end pr-3">
                                            <Menu as="div" class="relative inline-block text-left">
                                                <div>
                                                <MenuButton class="transition ease-in-out duration-300 inline-flex w-full justify-center gap-x-1.5 font-medium rounded-md bg-slate-600 px-2 py-1 text-sm text-slate-100 shadow-sm ring-1 ring-inset ring-slate-800 hover:bg-slate-500">
                                                    Menu
                                                    <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </MenuButton>
                                                </div>

                                                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                                <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div class="py-1">
                                                    <MenuItem v-slot="{ active }">
                                                        <div @click.prevent="openNewProductModal()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Nowy produkt</div>
                                                    </MenuItem>
                                                    </div>
                                                </MenuItems>
                                                </transition>
                                            </Menu>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-slate-600 bg-slate-500">
                                <tr v-for="product in products" :key="product.id">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-semibold text-slate-100 sm:pl-6">
                                        <span class="inline-flex items-center rounded-md bg-blue-300 bg-opacity-20  px-2 py-1 text-xs font-medium text-blue-300 ring-1 ring-inset ring-blue-400 ring-opacity-30">{{ product.shellCode }}</span>
                                    </td>
                                    <td v-tooltip="`${product.shellName.toString()}`" class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-semibold">{{product.shellName.toString().length > 0 ? product.shellName.toString().length < 40 ? product.shellName.toString() : product.shellName.toString().substring(0,40) + '...' : '--'}}</td>
                                    <td v-tooltip="`${product.wbtankName.toString()}`" class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-semibold">{{product.wbtankName.toString().length > 0 ? product.wbtankName.toString().length < 40 ? product.wbtankName.toString() : product.wbtankName.toString().substring(0,40) + '...' : '--'}}</td>
                                    <td v-if="product.gtu.toString().length > 0" v-tooltip="`${product.gtu}`" class="whitespace-nowrap px-3 py-4 text-xs text-yellow-400 font-semibold">{{product.gtu.toString().length > 0 ? product.gtu.toString().length < 40 ? product.gtu.toString() : product.gtu.toString().substring(0,40) + '...' : '--'}}</td>
                                    <td v-if="product.gtu.length === 0" class="whitespace-nowrap px-3 py-4 text-xs"><div class="text-slate-300 opacity-20 font-normal">Brak</div></td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        <div class="flex justify-end pr-3">
                                            <button v-tooltip="`Zarządzaj`" type="button" @click.prevent="editProduct(product)" class="cursor-pointer">
                                                <PencilAltIcon :class="{'h-6 w-6 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 text-slate-200 hover:text-blue-400': true }" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <EmptyState v-if="products.length === 0"></EmptyState>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="tableType === 'fee'" class="">
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
        </div>
        <div class="flex flex-col mx-4 lg:px-0" >
            <div class="-my-2 -mx-4 overflow-x-auto">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                        <table class="min-w-full divide-y divide-slate-700">
                            <thead class="bg-slate-700">
                                <tr>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Kod kraju</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">ID produktu</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Kod produktu</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Nazwa Shell</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">Nazwa na fakturze</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">GTU</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold whitespace-nowrap text-slate-100">
                                        <div class="flex justify-end pr-3">
                                            <Menu as="div" class="relative inline-block text-left">
                                                <div>
                                                <MenuButton class="transition ease-in-out duration-300 inline-flex w-full justify-center gap-x-1.5 font-medium rounded-md bg-slate-600 px-2 py-1 text-sm text-slate-100 shadow-sm ring-1 ring-inset ring-slate-800 hover:bg-slate-500">
                                                    Menu
                                                    <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </MenuButton>
                                                </div>

                                                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                                <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div class="py-1">
                                                    <MenuItem v-slot="{ active }">
                                                        <div @click.prevent="openNewProductModal()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Nowy produkt</div>
                                                    </MenuItem>
                                                    </div>
                                                </MenuItems>
                                                </transition>
                                            </Menu>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody v-for="feeCategory in Object.values(feeProducts)" class="divide-y divide-slate-700 bg-slate-500">
                                <tr>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        <div class="flex items-center">
                                            {{feeCategory.countryCode}}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 font-medium">
                                        <!-- {{user.name}} {{user.surname}} <span class="text-xs py-1 px-2 bg-red-100 border border-red-300 rounded-lg text-red-500 font-medium" v-if="user.permissions.admin">(ADMINISTRATOR)</span> -->
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 select-none flex justify-end">
                                        <button v-if="loaded" :disabled="Object.values(feeCategory).length === 0" @click="feeCategory.showCategory = !feeCategory.showCategory" :class="Object.values(feeCategory).length === 0 ? 'opacity-30 cursor-not-allowed' : 'hover:bg-slate-700'" class="transition ease-in-out duration-300 bg-slate-600 text-white font-semibold py-2 px-4 rounded w-48 flex justify-center">{{feeCategory.showCategory ? `Ukryj produkty (${Object.values(feeCategory.products).length})` : `Pokaż produkty (${Object.values(feeCategory.products).length})`}}</button>
                                        <button v-if="!loaded" :disabled="true" :class="!loaded ? '' : 'hover:bg-slate-700 cursor-not-allowed'" class="transition ease-in-out duration-300 bg-slate-600 text-white font-semibold py-2 px-4 rounded w-48 flex justify-center">
                                            <div class="spinner"></div>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="feeCategory.showCategory && loaded" v-for="feeProduct in Object.values(feeCategory.products)" class="bg-gray-500">
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        <span class="inline-flex items-center rounded-md bg-blue-300 bg-opacity-20  px-2 py-1 text-xs font-medium text-blue-300 ring-1 ring-inset ring-blue-400 ring-opacity-30">{{ feeProduct.shellId }}</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100 ">
                                        <span v-tooltip="'[ '+feeProduct.shellCode.toString().replaceAll(',', ', ')+' ]'" class="inline-flex items-center rounded-md bg-blue-300 bg-opacity-20  px-2 py-1 text-xs font-medium text-blue-300 ring-1 ring-inset ring-blue-400 ring-opacity-30 mx-0.5">{{ feeProduct.shellCode.length > 10 ? '[ '+feeProduct.shellCode.slice(0, 10).toString().replaceAll(',', ', ')+', ... ]' : feeProduct.shellCode }}</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        <span v-tooltip="feeProduct.shellName">{{feeProduct.shellName.toString().length > 0 ? feeProduct.shellName.toString().length < 40 ? feeProduct.shellName.toString() : feeProduct.shellName.toString().substring(0,40) + '...' : '--'}}</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-slate-100">
                                        <span v-tooltip="feeProduct.wbtankName">{{feeProduct.wbtankName.toString().length > 0 ? feeProduct.wbtankName.toString().length < 40 ? feeProduct.wbtankName.toString() : feeProduct.wbtankName.toString().substring(0,40) + '...' : '--'}}</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs font-semibold" :class="[feeProduct.gtu.length === 0 ? 'text-slate-100' : 'text-yellow-400']">
                                        {{feeProduct.gtu.length === 0 ? 'Brak' : feeProduct.gtu}} 
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 flex justify-end text-sm font-medium sm:pr-6 select-none">
                                        <div class="flex justify-end pr-3">
                                            <button v-tooltip="`Zarządzaj`" type="button" class="cursor-pointer" @click.prevent="editProduct(feeProduct)">
                                                <PencilAltIcon :class="{'h-6 w-6 flex-shrink-0 cursor-pointer transition ease-in-out duration-300 text-slate-200 hover:text-blue-400': true }" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <EmptyState v-if="Object.values(feeProducts).length === 0"></EmptyState>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  </div>
  <TransitionRoot as="template" :show="newProductModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="overflow-auto">
                <div class="border-b border-gray-200 bg-white mb-4">
                    <h3 class="text-base font-semibold leading-6 text-gray-900">{{this.inEdit ? 'Edycja produktu' : 'Dodaj produkt'}}</h3>
                </div>
                <div v-if="tableType === 'fee'" class="w-full mb-2">
                    <label for="countryCode" class="block text-sm font-medium mb-1 text-gray-700">Kod kraju<span class="text-red-500">*</span></label>
                    <div class="w-full text-center text-xxs">
                    <input :disabled="this.inEdit" placeholder="Kod kraju do którego należy fee np. PL" v-model="newProduct.countryCode" ondrop="return false;" type="text" :name="`countryCode`" :id="`countryCode`" :class="['w-full block rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6', this.inEdit ? 'opacity-50 cursor-not-allowed' : '']" />
                    </div>
                    <div v-if="this.errors.countryCode" class="mt-1 text-xxs text-red-500">Kod kraju jest wymagany. Uzupełnij pole z kodem kraju.</div>
                </div>
                <div v-if="tableType === 'fee'" class="w-full mb-2">
                    <label for="shellId" class="block text-sm font-medium mb-1 text-gray-700">ID produktu Shell<span class="text-red-500">*</span></label>
                    <div class="w-full text-center text-xxs">
                        <input :disabled="this.inEdit" placeholder="ID produktu z Shell np. 1" v-model.number="newProduct.shellId" ondrop="return false;" onkeypress='return event.charCode >= 48 && event.charCode <= 57' type="text" :name="`shellId`" :id="`shellId`" :class="['w-full block rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6', this.inEdit ? 'opacity-50 cursor-not-allowed' : '']" />
                    </div>
                    <div v-if="this.errors.shellCode" class="mt-1 text-xxs text-red-500">ID produktu jest wymagane. Uzupełnij pole ID produktu z systemu Shell.</div>
                </div>
                <div v-if="tableType === 'fee'" class="w-full mb-2">
                    <label for="shellCode" class="block text-sm font-medium mb-1 text-gray-700">Kod produktu Shell<span class="text-red-500">*</span></label>
                    <div class="w-full text-center text-xxs">
                        <input placeholder="Kod lub kody produktów z Shell np. 1,2,3,4 lub 1-4" v-model="newProduct.shellCode" ondrop="return false;" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 44 || event.charCode == 45' type="text" :name="`shellCode`" :id="`shellCode`" :class="['w-full block rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']" />
                    </div>
                    <div v-if="this.errors.shellCodeMultiple" class="mt-1 text-xxs text-red-500">Kod produktu jest wymagany. Uzupełnij pole lub sprawdź czy nie zawiera błędów.</div>
                </div>
                <div v-if="tableType === 'normal'" class="w-full mb-2">
                    <label for="shellCode" class="block text-sm font-medium mb-1 text-gray-700">Kod produktu Shell<span class="text-red-500">*</span></label>
                    <div class="w-full text-center text-xxs">
                        <input :disabled="this.inEdit" placeholder="Kod produktu z Shell np. 1" v-model.number="newProduct.shellCode" ondrop="return false;" onkeypress='return event.charCode >= 48 && event.charCode <= 57' type="text" :name="`shellCode`" :id="`shellCode`" :class="['w-full block rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6', this.inEdit ? 'opacity-50 cursor-not-allowed' : '']" />
                    </div>
                    <div v-if="this.errors.shellCode" class="mt-1 text-xxs text-red-500">Kod produktu jest wymagany. Uzupełnij pole numerem produktu z systemu Shell.</div>
                </div>
                <div class="w-full mb-2">
                    <label for="shellName" class="block text-sm font-medium mb-1 text-gray-700">Nazwa produktu Shell<span class="text-red-500">*</span></label>
                    <div class="w-full text-center text-xxs">
                    <input placeholder="Nazwa produktu z Shell np. Diesel Ago" v-model="newProduct.shellName" ondrop="return false;" type="text" :name="`shellName`" :id="`shellName`" class="w-full block rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6" />
                    </div>
                    <div v-if="this.errors.shellName" class="mt-1 text-xxs text-red-500">Nazwa produktu Shell jest wymagana. Uzupełnij pole nazwą produktu z systemu Shell.</div>
                </div>
                <div class="w-full mb-2">
                    <label for="wbtankName" class="block text-sm font-medium mb-1 text-gray-700">Nazwa na fakturze<span class="text-red-500">*</span></label>
                    <div class="w-full text-center text-xxs">
                    <input placeholder="Nazwa produktu na fakturze np. Shell FuelSave Diesel CN 27102011" v-model="newProduct.wbtankName" ondrop="return false;" type="text" :name="`wbtankName`" :id="`wbtankName`" class="w-full block rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6" />
                    </div>
                    <div v-if="this.errors.wbtankName" class="mt-1 text-xxs text-red-500">Nazwa produktu na fakturze jest wymagana. Uzupełnij pole wybraną nazwą produktu.</div>
                </div>
                <div class="w-full mb-2">
                    <label for="gtu" class="block text-sm font-medium mb-1 text-gray-700">GTU <span class="opacity-50 text-xxs">(pole nieobowiązkowe)</span></label>
                    <div class="w-full text-center text-xxs">
                    <input placeholder="GTU produktu np. GTU_2" v-model="newProduct.gtu" ondrop="return false;" type="text" :name="`gtu`" :id="`gtu`" class="w-full block rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6" />
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-x-4">
                <button type="button" class="transition ease-in-out duration-200 mt-3 w-full inline-flex justify-center rounded-md border border-green-500 shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto sm:text-sm" @click="addNewProductToDb()" ref="cancelButtonRef">{{this.inEdit ? 'Edytuj produkt' : 'Dodaj produkt'}}</button>
                <button v-if="this.inEdit" type="button" class="transition ease-in-out duration-200 mt-3 w-full inline-flex justify-center rounded-md border border-red-500 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:w-auto sm:text-sm" @click="this.askForDeleteProduct = true" ref="cancelButtonRef">Usuń produkt</button>
                <button type="button" class="transition ease-in-out duration-200 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="closeNewProductModal()" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <askFor v-if="this.askForDeleteProduct" @accept="removeProduct()" :modalData="this.modalDeleteProduct" @closeModal="this.askForDeleteProduct = false"></askFor>
</template>

<script>
import {db, firebase} from "@/firebase/gfbconf.js";
import EmptyState from '../components/EmptyState.vue'
import { HomeIcon, ChevronDownIcon, DocumentTextIcon, DocumentReportIcon } from '@heroicons/vue/solid'
import { PencilAltIcon } from '@heroicons/vue/outline'
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogOverlay, TransitionChild, TransitionRoot, DialogTitle } from '@headlessui/vue'
import askFor from '@/components/AlertModal.vue';
import randomstring from 'randomstring';

export default {
  name: 'ProductTranslations',
  data() {
    return {
      // Twoje dane tutaj
      modalDeleteProduct: {
            headerText: "Usuwanie tłumaczenia produktu",
            bodyText: `Czy na pewno chcesz usunąć tłumaczenie produktu z systemu?`,
            acceptButtonText: "Usuń"
        },
      askForDeleteProduct: false,
      inEdit: false,
      products: {},
      feeProducts: {},
      tableType: "normal",
      newProductModal: false,
      loaded: false,
      newProduct: {
        id: null,
        shellId: null,
        shellCode: null,
        wbtankIndex: null,
        shellName: '',
        wbtankName: '',
        countryCode: '',
        gtu: ''
      },
      errors: {
        shellId: false,
        shellCode: false,
        shellCodeMultiple: false,
        shellName: false,
        wbtankName: false,
        countryCode: false,
        gtu: false
      }
    }
  },
  components: {
    EmptyState,
    ChevronDownIcon,
    DocumentTextIcon,
    DocumentReportIcon,
    PencilAltIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    DialogTitle,
    askFor,
    HomeIcon
  },
  methods: {
    // Twoje metody tutaj
    async removeProduct(){
        let product = this.newProduct
        // Usuń produkt z bazy danych
        try {
            if(this.tableType === 'normal') {
                await db.collection('ProductTranslations').doc('products').update({
                [product.shellCode]: firebase.firestore.FieldValue.delete()
                });
            } else {
                await db.collection('ProductTranslations').doc('feeProducts').update({
                [`${product.countryCode.toUpperCase()}.${product.id}`]: firebase.firestore.FieldValue.delete()
                });
                let feeProducts = (await db.collection('ProductTranslations').doc('feeProducts').get()).data()
                if(feeProducts != null && feeProducts[product.countryCode.toUpperCase()] != null && Object.values(feeProducts[product.countryCode.toUpperCase()]).length === 0) {
                    await db.collection('ProductTranslations').doc('feeProducts').update({
                        [product.countryCode.toUpperCase()]: firebase.firestore.FieldValue.delete()
                    });
                }
            }
        } catch (error) {
            console.error('Błąd podczas usuwania produktu: ', error);
        }
        this.fetchProductTranslations()
        this.fetchFeeProductTranslactions()
        this.closeNewProductModal()
    },
    editProduct(product){
        this.newProduct = {
            id: product.id,
            shellId: product.shellId != null ? product.shellId : null,
            shellCode: product.shellCode,
            wbtankIndex: product.wbtankIndex,
            shellName: product.shellName,
            wbtankName: product.wbtankName,
            countryCode: product.countryCode != null ? product.countryCode : null,
            gtu: product.gtu
        }
        this.inEdit = true
        this.newProductModal = true
    },
    copyProductTranslations(){
        //utwórz kopie ProductTranslations
        db.collection('ProductTranslationsCopy').doc('products').set(this.products)
    },
    async addNewProductToDb(){
        this.clearErrors()
        let errors = []
        
        this.newProduct.wbtankIndex = this.newProduct.shellCode
        let shellCodes = []
        if(this.tableType === 'fee' && this.newProduct.shellCode !== null && this.newProduct.shellCode.length > 0) {
            if(this.newProduct.shellCode.includes(',') && !this.newProduct.shellCode.includes('-')) {
                shellCodes = this.newProduct.shellCode.split(',')
                for(let i = 0; i < shellCodes.length; i++) {
                    let shellCode = parseInt(shellCodes[i].trim())
                    shellCodes[i] = shellCode
                    if(isNaN(parseInt(shellCode))) {
                        this.errors.shellCodeMultiple = true
                        errors.push('shellCode')
                        break
                    }
                }
            } else if(!this.newProduct.shellCode.includes(',') && this.newProduct.shellCode.includes('-')) {
                if(!isNaN(parseInt(this.newProduct.shellCode.split('-')[0])) && !isNaN(parseInt(this.newProduct.shellCode.split('-')[1]))) {
                    let start = parseInt(this.newProduct.shellCode.split('-')[0])
                    let end = parseInt(this.newProduct.shellCode.split('-')[1])
                    for(let i = start; i <= end; i++) {
                        shellCodes.push(i)
                    }
                } else {
                    this.errors.shellCodeMultiple = true
                    errors.push('shellCode')
                }
            } else if(!this.newProduct.shellCode.includes(',') && !this.newProduct.shellCode.includes('-')) {
                if(!isNaN(parseInt(this.newProduct.shellCode)) && typeof this.newProduct.shellCode === 'object') {
                    for(let i = 0; i < this.newProduct.shellCode.length; i++) {
                        shellCodes.push(parseInt(this.newProduct.shellCode[i]))
                    }
                } else if(!isNaN(parseInt(this.newProduct.shellCode))) {
                    shellCodes.push(parseInt(this.newProduct.shellCode))
                } else {
                    this.errors.shellCodeMultiple = true
                    errors.push('shellCode')
                }
            } else {
                this.errors.shellCodeMultiple = true
                errors.push('shellCode')
            }
        }

        if(this.tableType === 'fee' && (this.newProduct.countryCode === null || this.newProduct.countryCode.length === 0)){
            this.errors.countryCode = true
            errors.push('countryCode')
        }
        if(this.tableType === 'fee' && (this.newProduct.shellId === null || this.newProduct.shellId.length === 0)) {
            this.errors.shellId = true
            errors.push('shellId')
        }
        if(this.newProduct.shellCode === null || this.newProduct.shellCode.length === 0){
            this.errors.shellCode = true
            errors.push('shellCode')
            this.errors.shellCodeMultiple = true
            errors.push('shellCodeMultiple')
        }
        if(this.newProduct.shellName === null || this.newProduct.shellName.length === 0){
            this.errors.shellName = true
            errors.push('shellName')
        }
        if(this.newProduct.wbtankName === null || this.newProduct.wbtankName.length === 0){
            this.errors.wbtankName = true
            errors.push('wbtankName')
        }
        if(errors.length > 0){
            console.log('errors', errors)
            return
        }
        if(this.tableType === 'normal') {
            await db.collection('ProductTranslations').doc('products').set({
                [this.newProduct.shellCode]: {
                    shellName: this.newProduct.shellName,
                    wbtankName: this.newProduct.wbtankName,
                    gtu: this.newProduct.gtu,
                    wbtankIndex: this.newProduct.wbtankIndex,
                    shellCode: this.newProduct.shellCode
                }
            }, { merge: true })
        } else {
            let feeProductsData = (await db.collection('ProductTranslations').doc('feeProducts').get()).data()

            const productId = this.newProduct.id != null ? this.newProduct.id : randomstring.generate({length: 6, charset: 'alphabetic'});
            await db.collection('ProductTranslations').doc('feeProducts').set({
                [this.newProduct.countryCode.toUpperCase()]: {
                    [productId]: {
                        id: productId,
                        shellId: this.newProduct.shellId,
                        shellName: this.newProduct.shellName,
                        wbtankName: this.newProduct.wbtankName,
                        gtu: this.newProduct.gtu,
                        wbtankIndex: this.newProduct.shellId,
                        countryCode:  this.newProduct.countryCode.toUpperCase(),
                        shellCode: shellCodes
                    }
                }
            }, { merge: true })

        }
        this.fetchProductTranslations()
        this.fetchFeeProductTranslactions()
        this.closeNewProductModal()
    },
    clearErrors(){
        this.errors = {
            shellId: false,
            shellCode: false,
            shellCodeMultiple: false,
            shellName: false,
            wbtankName: false,
            countryCode: false,
            gtu: false
        }
    },
    closeNewProductModal(){
        this.newProduct = {
            id: null,
            shellId: null,
            shellCode: null,
            wbtankIndex: null,
            shellName: '',
            wbtankName: '',
            countryCode: '',
            gtu: ''
        }
        this.clearErrors()
        this.newProductModal = false
        if(this.inEdit){
            setTimeout(() => {
                this.inEdit = false
            }, 300)
        }
    },
    openNewProductModal(){
        this.newProductModal = true
    },
    async fetchProductTranslations() {
      try {
        const docRef = db.collection('ProductTranslations').doc('products');
        const doc = await docRef.get();
        if (doc.exists) {
          this.products = {};
          this.products = doc.data();
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFeeProductTranslactions() {
        try {
            const docRef = db.collection('ProductTranslations').doc('feeProducts');
            const doc = await docRef.get();
            if (doc.exists) {
                this.feeProducts = {};
                this.feeProducts = doc.data();
                Object.entries(this.feeProducts).forEach((feeCategory) => {
                    feeCategory[1].countryCode = feeCategory[0]
                    if(feeCategory[1].products == null) {
                        feeCategory[1].products = {}
                    }
                    Object.values(feeCategory[1]).forEach((feeProduct) => {
                        if(feeProduct.shellCode != null) {
                            feeCategory[1].products[feeProduct.id] = feeProduct
                            delete this.feeProducts[feeProduct.countryCode][feeProduct.id]
                        }
                    })
                    feeCategory.showCategory = false
                })
                this.loaded = true
            } else {
                console.log('No such document!');
            }
        } catch (error) {
            console.error(error)
        }
    }
  },
  computed: {
    // Twoje obliczenia tutaj
  },
  async mounted() {
    // Kod, który zostanie wykonany po zamontowaniu komponentu
    await this.fetchProductTranslations();
    await this.fetchFeeProductTranslactions();
    // this.addNewProductToDb()
    // this.copyProductTranslations()
  }
}
</script>

<style scoped>
/* Twoje style CSS tutaj */
  .spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3.8px solid #d6d6d6;
    animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
          spinner-oaa3wk 2.2399999999999998s infinite linear;
  }

  @keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }

  @keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
  }
</style>