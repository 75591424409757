<template>
    <div>
        <div class="flex justify-between">
            <nav class="hidden sm:flex h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-slate-500 px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                        <router-link to="/hub" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span class="sr-only">Home</span>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <router-link to="/dashboard/invoices" class="transition ease-in-out duration-300 text-slate-100 hover:text-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                            <div class="ml-4 text-xs font-medium" aria-current="page">Faktury</div>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-slate-600" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-slate-100 opacity-60 cursor-default select-none" aria-current="page">Utwórz fakture</div>
                        </div>
                    </li>
                </ol>
                <!-- <div @click.prevent="testInvoice()" class="ml-4 cursor-pointer flex items-center justify-center px-4 bg-red-200">TEST FAKTUR</div> -->
            </nav>
            <div class="flex items-center sm:hidden">
                <router-link to="/hub" class="block md:hidden transition ease-in-out duration-300 text-sm font-medium text-slate-300 hover:text-slate-400" aria-current="page">&larr; Cofnij</router-link>
            </div>
        </div>
    <div v-if="!loading" class="flex w-full mt-4 overflow-y-auto">
        <div class="bg-white w-2/3 p-12 flex flex-col">
            <div class="flex w-full mb-4">
                <div class="w-1/3 flex items-center justify-center">
                    <img class="w-full" src="https://firebasestorage.googleapis.com/v0/b/lauron-a8b59.appspot.com/o/assets%2Fpobrane-min.png?alt=media&token=901b0405-351d-4e67-b415-33b1e336baab" />
                </div>
                <div class="w-2/3 flex flex-col pl-16">
                    <div class="flex text-xs w-full justify-between mb-8">
                        <div>Płotowo dn. {{this.documentData.invoiceDeliveryDate}}</div>
                        <div>Data dostawy / wykonania usługi {{this.documentData.invoiceDeliveryDate}}</div>
                    </div>
                    <div :class="['flex justify-end font-bold mb-4', this.documentData.typeOfInvoice === 'standard' ? 'text-2xl' : this.documentData.typeOfInvoice === 'korekta' ? 'text-base flex-col items-end' : 'text-2xl']">FAKTURA {{this.documentData.typeOfInvoice === 'standard' ? 'VAT' : this.documentData.typeOfInvoice === 'korekta' ? 'KORYGUJĄCA' : 'VAT'}} nr {{documentData.invoiceName}}
                        <span class="whitespace-nowrap" v-if="documentData.typeOfInvoice === 'korekta'">do FAKTURY VAT {{documentData.createdFor.invoice.length > 0 ? documentData.createdFor.invoice : '...'}} Z DNIA {{dateFormat()}}</span>
                    </div>
                    <div class="flex flex-col items-end">
                        <div class="flex line">
                            <div class="flex-col text-smPDF text-gray-300 font-semibold">
                                <div class="text-right">
                                    Sposób płatności
                                </div>
                                <div class="text-right">
                                    Termin płatności
                                </div>
                                <div class="text-right">
                                    Bank
                                </div>
                                <div class="text-right">
                                    Numer konta
                                </div>
                            </div>
                            <div class="flex-col text-smPDF text-black font-semibold pl-4">
                                <div class="text-right">
                                    przelew
                                </div>
                                <div class="text-right">
                                    {{this.documentData.paymentDate}} ({{this.documentData.paymentDayAmount}} dni)
                                </div>
                                <div class="text-right">
                                    PKO
                                </div>
                                <div class="text-right">
                                    42 1020 1068 0000 1102 0371 8830
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full font-medium flex mb-4">
                <div class="w-1/2 flex flex-col">
                    <div class="text-base text-gray-300">Sprzedawca</div>
                    <div class="text-smPDF">LAURON SP. Z O.O. </div>
                    <div class="text-smPDF">NIP: 8421772106</div>
                </div>
                <div class="w-1/2">
                    <div class="text-base text-gray-300">Nabywca</div>
                    <div class="text-smPDF">{{this.documentData.payerName}}</div>
                    <div class="text-smPDF">NIP: {{this.documentData.payerNIP}}</div>
                </div>
            </div>
            <div class="w-full font-medium flex mb-4">
                <div class="w-1/2 flex flex-col">
                    <div class="text-base text-gray-300">Adres</div>
                    <div class="text-smPDF">PŁOTOWO 3A </div>
                    <div class="text-smPDF">77-131 REKOWO</div>
                </div>
                <div class="w-1/2">
                    <div class="text-base text-gray-300">Adres</div>
                    <div class="text-smPDF">{{this.pickedUser.company.address}}</div>
                    <div class="text-smPDF">{{this.pickedUser.company.postCode}} {{this.pickedUser.company.city}}</div>
                </div>
            </div>
            <div class="w-full">
                <div class="grid grid-cols-44">
                    <div class="col-span-2 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">L.p.</span>
                        </div>
                    </div>
                    <div class="col-span-11 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Nazwa towaru/usługi (PKWiU)</span>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Ilość</span>
                        </div>
                    </div>
                    <div class="col-span-3 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Jedn. miary</span>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Cena jed. brutto</span>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Wartość brutto</span>
                        </div>
                    </div>
                    <div class="col-span-3 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">proc VAT</span>
                        </div>
                    </div>
                    <div class="col-span-3 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Wartość VAT</span>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Wartość netto</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">Upust (%)</span>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-blue-200 border-t border-b">
                        <div class="h-full py-2 w-full flex items-center bg-blue-50">
                            <span class="w-full text-center text-xxs">Cena jed. netto</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.documentData.typeOfInvoice === 'standard'" class="w-full">
                <div class="grid grid-cols-44 relative" :ref="`position${index}`" v-for="(position, index) in documentData.transactions" :key="index">
                    <div class="col-span-2 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-2 w-full flex items-center bg-white border-gray-200 border-r">
                            <span class="w-full text-right text-xxs">{{position.index}}</span>
                        </div>
                    </div>
           
                    <div class="col-span-11 h-full py-2 px-1 border-gray-200 border-b bg-gray-100 border-r" v-if="position.isSelected">
                        <Listbox as="div" v-model="position.name">
                            <div class="relative">
                            <ListboxButton :class="[errors[index].name ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 sm:text-sm sm:leading-6 transition-all duration-300']">
                                <span class="block truncate text-xs">{{ position.name.length>0 ? position.name : 'Wybierz pozycję...' }}</span>
                                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon :class="['h-5 w-5 text-gray-400']" aria-hidden="true" />
                                </span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions class="absolute z-10 max-h-60 w-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ListboxOption as="template" v-for="prod in products" :key="prod.wbtankIndex" :value="prod.wbtankName" v-slot="{ active, selected }">
                                    <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9 text-xs']">
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ prod.wbtankName }}</span>

                                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ListboxOption>
                                <ListboxOption @click.prevent="outsideList(index, 'standard', 'add')">
                                    <li :class="['hover:bg-indigo-600 hover:text-white bg-gray-100 relative cursor-default select-none py-2 pl-3 pr-9 text-xs']">
                                        <span :class="['block truncate uppercase font-bold']">Dodaj spoza listy...</span>
                                    </li>
                                </ListboxOption>
                                </ListboxOptions>
                            </transition>
                            </div>
                        </Listbox>
                    </div>
                    <div class="col-span-11 h-full py-2 px-1 border-gray-200 border-b bg-gray-100 border-r" v-if="!position.isSelected">
                        <div class="flex rounded-md shadow-sm">
                            <div class="relative flex flex-grow items-stretch focus-within:z-10">
                                <input v-model="position.name" type="text" ondrop="return false;" :name="`name${index}`" :id="`name${index}`" :class="[errors[index].name ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'w-full block border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 min-w-[24rem] ring-inset rounded-none rounded-l-md ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']"/>
                            </div>
                            <button @click="outsideList(index, 'standard', 'return')" v-tooltip="'Dodaj towar/usługę z listy'" type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 transition-all duration-500">
                                <ReplyIcon class="-ml-0.5 h-3 w-3  text-gray-600" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-1 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <div class="w-full text-center text-xxs">
                                <input v-model.number="position.quantity" ondrop="return false;" @blur="checkForEmpty(position)" @input="reCalculate(position)" @keypress='handleKeyPress($event, position, `quantity`)' type="text" :name="`quantity${index}`" :id="`quantity${index}`" :class="[errors[index].quantity ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600', 'w-full disabled:opacity-50 disabled:pointer-events-none block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset text-xs leading-6 transition-all duration-300']" />
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-1 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <div class="w-full text-center text-xxs">
                                <input v-model="position.unit" type="text" ondrop="return false;" :name="`name${index}`" :id="`name${index}`" :class="[errors[index].unit ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600', 'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 min-w-[24rem] ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset text-xs leading-6']"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">{{position.grossUnitPrice.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">{{position.grossValue.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div class="col-span-3 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-1 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <div class="w-full text-center text-xxs">
                                <input v-model.number="position.vatRate" ondrop="return false;" @blur="checkForEmpty(position)" @input="reCalculate(position)" onkeypress='return event.charCode >= 48 && event.charCode <= 57' type="text" :name="`vat${index}`" :id="`vat${index}`" :class="[errors[index].vatRate ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600', 'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  text-xs leading-6']" />
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">{{position.vatValue.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs">{{position.netValue.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex items-center bg-gray-100 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs"></span>
                        </div>
                    </div>
                    <div class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-1 w-full flex items-center bg-gray-100">
                            <div class="w-full text-center text-xxs">
                                <input v-model.number="position.netUnitPrice" ondrop="return false;" @blur="checkForEmpty(position)" @input="reCalculate(position)" @keypress='handleKeyPress($event, position, `netUnitPrice`)' type="text" :name="`netUnitPrice${index}`" :id="`netUnitPrice${index}`" :class="[errors[index].netUnitPrice ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600', 'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset text-xs leading-6']" />
                                <!-- <input v-model.number="position.netUnitPrice" ondrop="return false;" @input="reCalculate(position)" @keypress='$event.charCode === 44 ? position.netUnitPrice.concat(`,`) : log(`x`)' type="text" :name="`netUnitPrice${index}`" :id="`netUnitPrice${index}`" class="w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6" /> -->
                            </div>
                        </div>
                    </div>
                    <div class="absolute -right-6 top-1/2 transform -translate-y-1/2">
                        <XIcon v-tooltip="`Usuń pozycję`" @click.prevent="removePosition(index)" class="transition ease-in-out duration-200 h-5 w-5 ml-1 text-red-500 cursor-pointer hover:text-red-700" aria-hidden="true" />
                    </div>
                </div>
                <EmptyState v-if="documentData.transactions.length === 0"></EmptyState>
                <div class="flex justify-center mt-3">
                    <button @click="addPosition()" type="button" :class="[isPositionAdded ? 'ring-red-500 bg-red-500 text-white hover:bg-red-800' : 'ring-gray-300 hover:bg-gray-50 bg-white text-gray-900 ', 'flex items-center rounded-full px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset transition-all duration-300']">
                        Dodaj pozycję
                        <PlusIcon class="h-3 w-3 ml-1" aria-hidden="true" />
                    </button>
                </div>
            </div>
            <div v-if="this.documentData.typeOfInvoice === 'korekta'" class="w-full">
                <div class="grid grid-cols-44 relative" :ref="`position${index}`" v-for="(position, index) in documentData.transactions" :key="index">
                    <div class="col-span-2 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-2 w-full flex flex-col bg-white border-gray-200 border-r">
                            <div class="text-xxs mb-0.5 text-slate-500 opacity-0 select-none">x</div>
                            <span class="w-full py-2 text-right text-xxs">{{position.index}}</span>
                        </div>
                    </div>
                    <div v-if="position.beforeCorrection !== undefined && position.afterCorrection !== undefined" class="col-span-11 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-1 w-full flex flex-col bg-gray-100 border-gray-200 border-r">
                            <div class="text-xxs mb-0.5 text-slate-500">Przed korektą</div>
                            <!-- przed korekta -->
                            <div class="w-full text-center text-xxs" v-if="!position.beforeCorrection.isSelected">
                                <div class="flex rounded-md shadow-sm">
                                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                                        <input v-model="position.beforeCorrection.name" type="text" ondrop="return false;" :name="`name${index}`" :id="`name${index}`" class="w-full block border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 min-w-[24rem] ring-inset rounded-none rounded-l-md ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6"/>
                                    </div>
                                    <button @click="outsideList(index, 'beforeKorekta', 'return')" v-tooltip="'Dodaj towar/usługę z listy'" type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 transition-all duration-500">
                                        <ReplyIcon class="-ml-0.5 h-3 w-3  text-gray-600" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                            <div class="col-span-11 h-full" v-if="position.beforeCorrection.isSelected">
                                <Listbox as="div" v-model="position.beforeCorrection.name">
                                    <div class="relative">
                                    <ListboxButton :class="[errors[index].beforeCorrection.name ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 sm:text-sm sm:leading-6 transition-all duration-300']">
                                        <span class="block truncate text-xs">{{ position.beforeCorrection.name.length>0 ? position.beforeCorrection.name : 'Wybierz pozycję...' }}</span>
                                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronDownIcon :class="['h-5 w-5 text-gray-400']" aria-hidden="true" />
                                        </span>
                                    </ListboxButton>

                                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions class="absolute z-10 max-h-60 w-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <ListboxOption as="template" v-for="prod in products" :key="prod.wbtankIndex" :value="prod.wbtankName" v-slot="{ active, selected }">
                                            <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9 text-xs']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ prod.wbtankName }}</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                        </ListboxOption>
                                        <ListboxOption @click.prevent="outsideList(index, 'beforeKorekta', 'add')">
                                            <li :class="['hover:bg-indigo-600 hover:text-white bg-gray-100 relative cursor-default select-none py-2 pl-3 pr-9 text-xs']">
                                                <span :class="['block truncate uppercase font-bold']">Dodaj spoza listy...</span>
                                            </li>
                                        </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                    </div>
                                </Listbox>
                            </div>

                            <!-- po korekcie -->
                            <div class="text-xxs mb-0.5 mt-1 text-slate-500">Po korekcie</div>
                            <div class="w-full text-center text-xxs" v-if="!position.afterCorrection.isSelected">
                                <div class="flex rounded-md shadow-sm">
                                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                                        <input v-model="position.afterCorrection.name" type="text" ondrop="return false;" :name="`name${index}`" :id="`name${index}`" :class="[errors[index].afterCorrection.name ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600', 'w-full block border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 min-w-[24rem] ring-inset rounded-none rounded-l-md ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset text-xs leading-6']" />
                                    </div>
                                    <button @click="outsideList(index, 'afterKorekta', 'return')" v-tooltip="'Dodaj towar/usługę z listy'" type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 transition-all duration-500">
                                        <ReplyIcon class="-ml-0.5 h-3 w-3  text-gray-600" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                            <div class="col-span-11 h-full" v-if="position.afterCorrection.isSelected">
                                <Listbox as="div" v-model="position.afterCorrection.name">
                                    <div class="relative">
                                    <ListboxButton :class="[errors[index].afterCorrection.name ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600', 'relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 sm:text-sm sm:leading-6 transition-all duration-300']">
                                        <span class="block truncate text-xs">{{ position.afterCorrection.name.length>0 ? position.afterCorrection.name : 'Wybierz pozycję...' }}</span>
                                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronDownIcon :class="['h-5 w-5 text-gray-400']" aria-hidden="true" />
                                        </span>
                                    </ListboxButton>

                                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions class="absolute z-10 max-h-60 w-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <ListboxOption as="template" v-for="prod in products" :key="prod.wbtankIndex" :value="prod.wbtankName" v-slot="{ active, selected }">
                                            <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9 text-xs']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ prod.wbtankName }}</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                        </ListboxOption>
                                        <ListboxOption @click.prevent="outsideList(index, 'afterKorekta', 'add')">
                                            <li :class="['hover:bg-indigo-600 hover:text-white bg-gray-100 relative cursor-default select-none py-2 pl-3 pr-9 text-xs']">
                                                <span :class="['block truncate uppercase font-bold']">Dodaj spoza listy...</span>
                                            </li>
                                        </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                    </div>
                                </Listbox>
                            </div>
                            <span class="w-full text-slate-400 mt-1 py-2 pr-1 text-right text-xxs">Różnica</span>
                        </div>
                    </div>
                    <div v-if="position.beforeCorrection !== undefined && position.afterCorrection !== undefined" class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-1 w-full flex flex-col bg-gray-100 border-gray-200 border-r">
                            <div class="text-xxs mb-0.5 text-slate-500 opacity-0 select-none">x</div>
                            <div class="w-full text-center text-xxs">
                                <!-- przed korekta -->
                                <input v-model.number="position.beforeCorrection.quantity" ondrop="return false;" @blur="checkForEmpty(position)" @input="reCalculate(position, 'beforeCorrection')" @keypress='handleKeyPress($event, position, `quantity`, `beforeCorrection`)' onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46' type="text" :name="`quantity${index}`" :id="`quantity${index}`" :class="[errors[index].beforeCorrection.quantity ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'w-full disabled:opacity-50 disabled:pointer-events-none block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']" />
                            </div>
                            <div class="text-xxs mb-0.5 mt-1 text-slate-500 opacity-0 select-none">x</div>
                            <div class="w-full text-center text-xxs">
                                <!-- po korekcie -->
                                <input v-model.number="position.afterCorrection.quantity" ondrop="return false;" @blur="checkForEmpty(position)" @input="reCalculate(position, 'afterCorrection')" @keypress='handleKeyPress($event, position, `quantity`, `afterCorrection`)' onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46' type="text" :name="`quantity${index}`" :id="`quantity${index}`" :class="[errors[index].afterCorrection.quantity ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'w-full disabled:opacity-50 disabled:pointer-events-none block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']" />
                            </div>
                            <div class="w-full mt-1 text-center text-xxs">
                                <!-- roznica -->
                                <input disabled v-model.number="position.quantity" ondrop="return false;" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46' type="text" :name="`quantity${index}`" :id="`quantity${index}`" class="w-full opacity-50 cursor-not-allowed pointer-events-none block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6" />
                            </div>
                        </div>
                    </div>
                    <div v-if="position.beforeCorrection !== undefined && position.afterCorrection !== undefined" class="col-span-3 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-1 w-full flex flex-col bg-gray-100 border-gray-200 border-r">
                            <div class="text-xxs mb-0.5 text-slate-500 opacity-0 select-none">x</div>
                            <div class="w-full text-center text-xxs">
                                <!-- przed korekta -->
                                <input v-model="position.beforeCorrection.unit" type="text" ondrop="return false;" :name="`name${index}`" :id="`name${index}`" :class="[errors[index].beforeCorrection.unit ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 min-w-[24rem] ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']"/>
                            </div>
                            <div class="text-xxs mb-0.5 mt-1 text-slate-500 opacity-0 select-none">x</div>
                            <div class="w-full text-center text-xxs">
                                <!-- po korekcie -->
                                <input v-model="position.afterCorrection.unit" type="text" ondrop="return false;" :name="`name${index}`" :id="`name${index}`" :class="[errors[index].afterCorrection.unit ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 min-w-[24rem] ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']"/>
                            </div>
                            <div class="w-full mt-1 text-center text-xxs">
                                <!-- roznica -->
                                <input v-model="position.unit" type="text" ondrop="return false;" :name="`name${index}`" :id="`name${index}`" :class="[errors[index].unit ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ',  'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 min-w-[24rem] ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']" />
                            </div>
                        </div>
                    </div>
                    <div v-if="position.beforeCorrection !== undefined && position.afterCorrection !== undefined" class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex flex-col bg-gray-100 border-gray-200 border-r">
                            <div class="text-xxs mb-0.5 text-slate-500 opacity-0 select-none">x</div>
                            <!-- przed korekta -->
                            <span class="w-full py-2 text-center text-xxs">{{position.beforeCorrection.grossUnitPrice.toFixed(2)}}</span>
                            <div class="text-xxs mb-0.5 mt-1 text-slate-500 opacity-0 select-none">x</div>
                            <!-- po korekcie -->
                            <span class="w-full py-2 text-center text-xxs">{{position.afterCorrection.grossUnitPrice.toFixed(2)}}</span>
                            <!-- roznica -->
                            <span class="w-full text-slate-400 mt-1 py-2 text-center text-xxs">{{position.grossUnitPrice.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div v-if="position.beforeCorrection !== undefined && position.afterCorrection !== undefined" class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex flex-col bg-gray-100 border-gray-200 border-r">
                            <div class="text-xxs mb-0.5 text-slate-500 opacity-0 select-none">x</div>
                            <!-- przed korekta -->
                            <span class="w-full py-2 text-center text-xxs">{{position.beforeCorrection.grossValue.toFixed(2)}}</span>
                            <div class="text-xxs mb-0.5 mt-1 text-slate-500 opacity-0 select-none">x</div>
                            <!-- po korekcie -->
                            <span class="w-full py-2 text-center text-xxs">{{position.afterCorrection.grossValue.toFixed(2)}}</span>
                            <!-- roznica -->
                            <span class="w-full text-slate-400 mt-1 py-2 text-center text-xxs">{{position.grossValue.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div v-if="position.beforeCorrection !== undefined && position.afterCorrection !== undefined" class="col-span-3 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-1 w-full flex flex-col bg-gray-100">
                            <div class="text-xxs mb-0.5 text-slate-500 opacity-0 select-none">x</div>
                            <div class="w-full text-center text-xxs">
                                <!-- przed korekta -->
                                <input v-model.number="position.beforeCorrection.vatRate" ondrop="return false;" @blur="checkForEmpty(position)" @input="reCalculate(position, 'beforeCorrection')" onkeypress='return event.charCode >= 48 && event.charCode <= 57' type="text" :name="`vat${index}`" :id="`vat${index}`" :class="[errors[index].beforeCorrection.vatRate ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']" />
                            </div>
                            <div class="text-xxs mb-0.5 mt-1 text-slate-500 opacity-0 select-none">x</div>
                            <div class="w-full text-center text-xxs">
                                <!-- po korekcie -->
                                <input v-model.number="position.afterCorrection.vatRate" ondrop="return false;" @blur="checkForEmpty(position)" @input="reCalculate(position, 'afterCorrection')" onkeypress='return event.charCode >= 48 && event.charCode <= 57' type="text" :name="`vat${index}`" :id="`vat${index}`" :class="[errors[index].afterCorrection.vatRate ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']" />
                            </div>
                        </div>
                    </div>
                    <div v-if="position.beforeCorrection !== undefined && position.afterCorrection !== undefined" class="col-span-3 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex flex-col bg-gray-100 border-gray-200 border-r">
                            <div class="text-xxs mb-0.5 text-slate-500 opacity-0 select-none">x</div>
                            <!-- przed korekta -->
                            <span class="w-full py-2 text-center text-xxs">{{position.beforeCorrection.vatValue.toFixed(2)}}</span>
                            <div class="text-xxs mb-0.5 mt-1 text-slate-500 opacity-0 select-none">x</div>
                            <!-- po korekcie -->
                            <span class="w-full py-2 text-center text-xxs">{{position.afterCorrection.vatValue.toFixed(2)}}</span>
                            <!-- roznica -->
                            <span class="w-full text-slate-400 mt-1 py-2 text-center text-xxs">{{position.vatValue.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div v-if="position.beforeCorrection !== undefined && position.afterCorrection !== undefined" class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex flex-col bg-gray-100 border-gray-200 border-r">
                            <div class="text-xxs mb-0.5 text-slate-500 opacity-0 select-none">x</div>
                            <!-- przed korekta -->
                            <span class="w-full py-2 text-center text-xxs">{{position.beforeCorrection.netValue.toFixed(2)}}</span>
                            <div class="text-xxs mb-0.5 mt-1 text-slate-500 opacity-0 select-none">x</div>
                            <!-- po korekcie -->
                            <span class="w-full py-2 text-center text-xxs">{{position.afterCorrection.netValue.toFixed(2)}}</span>
                            <!-- roznica -->
                            <span class="w-full text-slate-400 mt-1 py-2 text-center text-xxs">{{position.netValue.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div class="col-span-2 h-full border-gray-200 border-b">
                        <div class="h-full py-2 w-full flex flex-col bg-gray-100 border-gray-200 border-r">
                            <span class="w-full text-center text-xxs"></span>
                        </div>
                    </div>
                    <div v-if="position.beforeCorrection !== undefined && position.afterCorrection !== undefined" class="col-span-4 h-full border-gray-200 border-b">
                        <div class="h-full py-2 px-1 w-full flex flex-col bg-gray-100">
                            <div class="text-xxs mb-0.5 text-slate-500 opacity-0 select-none">x</div>
                            <div class="w-full text-center text-xxs">
                                <!-- przed korekta -->
                                <input v-model.number="position.beforeCorrection.netUnitPrice" ondrop="return false;" @blur="checkForEmpty(position)" @input="reCalculate(position, 'beforeCorrection')" @keypress='handleKeyPress($event, position, `netUnitPrice`, `beforeCorrection`)' onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46' type="text" :name="`netUnitPrice${index}`" :id="`netUnitPrice${index}`" :class="[errors[index].beforeCorrection.netUnitPrice ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']" />
                            </div>
                            <div class="text-xxs mb-0.5 mt-1 text-slate-500 opacity-0 select-none">x</div>
                            <div class="w-full text-center text-xxs">
                                <!-- po korekcie -->
                                <input v-model.number="position.afterCorrection.netUnitPrice" ondrop="return false;" @blur="checkForEmpty(position)" @input="reCalculate(position, 'afterCorrection')" @keypress='handleKeyPress($event, position, `netUnitPrice`, `afterCorrection`)' onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46' type="text" :name="`netUnitPrice${index}`" :id="`netUnitPrice${index}`" :class="[errors[index].afterCorrection.netUnitPrice ? 'ring-red-600 focus:ring-red-600' : 'focus:ring-indigo-600 ', 'w-full block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6']" />
                            </div>
                            <div class="w-full mt-1 text-center text-xxs">
                                <!-- roznica -->
                                <input disabled v-model.number="position.netUnitPrice" ondrop="return false;" onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46' type="text" :name="`netUnitPrice${index}`" :id="`netUnitPrice${index}`" class="w-full pointer-events-none cursor-not-allowed opacity-50 block rounded-md border-0 py-0.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3eba83] text-xs leading-6" />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="absolute rounded-md mt-0.5 -right-1.5 top-6 transition ease-in-out duration-200 bg-red-500 hover:bg-red-600 border border-red-600">
                        <XIcon @click.prevent="removePosition(index)" class="transition ease-in-out duration-200 h-4 w-4 text-white cursor-pointer hover:text-red-100" aria-hidden="true" />
                    </div> -->
                    <div class="absolute -right-6 top-9 transform -translate-y-1/2">
                        <XIcon v-tooltip="`Usuń pozycję`" @click.prevent="removePosition(index)" class="transition ease-in-out duration-200 h-5 w-5 ml-1 text-red-500 cursor-pointer hover:text-red-700" aria-hidden="true" />
                    </div>
                </div>
                <EmptyState v-if="documentData.transactions.length === 0"></EmptyState>
                <div class="flex justify-center mt-3">
                    <button @click="addPosition()" type="button" :class="[isPositionAdded ? 'ring-red-500 bg-red-500 text-white hover:bg-red-800' : 'ring-gray-300 hover:bg-gray-50 bg-white text-gray-900 ', 'flex items-center rounded-full px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset transition-all duration-300']">
                        Dodaj pozycję
                        <PlusIcon class="h-3 w-3 ml-1" aria-hidden="true" />
                    </button>
                </div>
            </div>
            <div class="w-full mt-8 flex justify-between">
                <div class="flex flex-col justify-end text-smPDF w-2/3 pb-2 pr-2">
                    <span class="font-semibold text-gray-700">Uwagi:</span>
                    <p class="break-all" :class="[documentData.note.length > 0 ? '' : 'font-bold', 'transition-all duration-600']">{{documentData.note.length > 0 ? documentData.note : 'Brak uwag.'}}</p>
                </div>
                <div class="flex flex-col w-1/3">
                    <div class="flex border-blue-200 border-t border-b">
                        <div class="w-1/4 text-xxs flex justify-center bg-blue-50 py-2 border-gray-200">Stawka VAT</div>
                        <div class="w-1/4 text-xxs flex justify-center bg-blue-50 py-2 border-gray-200 border-l ">NETTO</div>
                        <div class="w-1/4 text-xxs flex justify-center bg-blue-50 py-2 border-gray-200 border-l ">VAT</div>
                        <div class="w-1/4 text-xxs flex justify-center bg-blue-50 py-2 border-gray-200 border-l">BRUTTO</div>
                    </div>
                    <div v-for="vatRate in Object.keys(vatRates)" :key="vatRate" class="flex border-gray-200 border-t border-b">
                        <div class="w-1/4 text-xxs flex justify-center bg-gray-100 py-2 border-gray-200">{{vatRate}}%</div>
                        <div class="w-1/4 text-xxs flex justify-center bg-gray-100 py-2 border-gray-200 border-l ">{{vatRates[vatRate].rateNetValue.toFixed(2)}}</div>
                        <div class="w-1/4 text-xxs flex justify-center bg-gray-100 py-2 border-gray-200 border-l ">{{vatRates[vatRate].rateVatValue.toFixed(2)}}</div>
                        <div class="w-1/4 text-xxs flex justify-center bg-gray-100 py-2 border-gray-200 border-l ">{{vatRates[vatRate].rateGrossValue.toFixed(2)}}</div>
                    </div>
                    <div v-if="Object.keys(vatRates).length === 0" class="flex border-gray-200 border-t border-b">
                        <EmptyState />
                    </div>
                </div>
            </div>
            <div class="w-full mb-2 flex flex-col items-end">
                <div class="w-full flex">
                    <div class="w-2/3 flex text-base justify-end pr-4 py-2 border-gray-200 border-t">
                        Wartość netto
                    </div>
                    <div class="w-1/3 flex border-gray-200 border-t">
                        <div class="w-full text-base flex justify-end pr-4 bg-gray-100 py-2">{{documentData.netValueOfInvoice.toFixed(2)}} PLN</div>
                    </div>
                </div>
                <div class="w-full flex">
                    <div class="w-2/3 flex text-base justify-end pr-4 py-2 border-gray-200 border-t">
                        Wartość VAT
                    </div>
                    <div class="w-1/3 flex border-gray-200 border-t">
                        <div class="w-full text-base flex justify-end pr-4 bg-gray-100 py-2">{{documentData.vatValueOfInvoice.toFixed(2)}} PLN</div>
                    </div>
                </div>
                <div class="w-full flex">
                    <div class="w-2/3 flex text-base font-semibold justify-end pr-4 py-2 border-gray-200 border-t border-b">
                        Razem do zapłaty
                    </div>
                    <div class="w-1/3 flex border-gray-200 border-t border-b">
                        <div class="w-full text-base font-semibold flex justify-end pr-4 bg-gray-100 py-2">{{documentData.valueOfInvoice.toFixed(2)}} PLN</div>
                    </div>
                </div>
            </div>
            <div class="w-full mb-2 text-xxs text-right"> <span class="text-gray-300">Słownie: </span>{{this.priceInPolish}}</div>
            <div class="flex flex-col items-end mb-2">
                <div class="flex line">
                    <div class="flex-col text-xs text-gray-300 font-semibold">
                        <div class="text-right">
                            Zapłacono
                        </div>
                        <div class="text-right">
                            Pozostało do zapłaty
                        </div>
                    </div>
                    <div class="flex-col text-xs text-black font-semibold pl-4">
                        <div class="text-right">
                            0.00 PLN
                        </div>
                        <div class="text-right">
                            {{documentData.valueOfInvoice.toFixed(2)}} PLN
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full text-xxs text-right">Faktury o wartości powyżej 15 tyś brutto należy obowiązkowo przelewać w mechaniźmie podzielonej płatności.</div>
        </div>
        <div class="p-4 w-1/3 bg-slate-500">
            <div class="w-full" v-if="pickedUser !== null">
                <div class="block text-sm font-medium leading-6 text-slate-200">Typ faktury:</div>
                <select v-model="documentData.typeOfInvoice" @change="changeTypeOfInvoice()" placeholder="---" id="typeOfInvoice" name="typeOfInvoice" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                    <option value="standard">Faktura sprzedaży</option>
                    <option value="korekta">Faktura korekta</option>
                </select>
                <div class="block text-sm font-medium leading-6 text-slate-200 mt-2">Numer faktury:</div>
                <input type="text" v-model="documentData.invoiceName" disabled class="block w-full rounded-md opacity-60 border-slate-300 cursor-not-allowed shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Jan" />
                <div class="block text-sm font-medium leading-6 text-slate-200 mt-2">Data wystawienia faktury:</div>
                <VueDatePicker placeholder="Wybierz datę wystawienia faktury..." :format="documentData.invoiceDeliveryDate" auto-apply locale="pl" v-model="date" :enable-time-picker="false" :clearable="false" @open="checkDate('open', 'delivery')" @closed="checkDate('closed', 'delivery')" @cleared="checkDate('cleared', 'delivery')" />
                <div class="block text-sm font-medium leading-6 text-slate-200 mt-2 ring-red-500">Okres:</div>
                <VueDatePicker placeholder="Wybierz okres faktury..." :format="periodDateFormat" auto-apply locale="pl" v-model="periodDate" :enable-time-picker="false" :clearable="false" range @open="checkDate('open', 'period')" @closed="checkDate('closed', 'period')" @cleared="checkDate('cleared', 'period')" :class="datePickerClass" />
                <div v-if="documentData.typeOfInvoice === 'korekta'" class="py-4">
                    <div class="relative">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                        </div>
                    </div>
                    <div>
                    <span class="block text-sm font-medium leading-6 text-slate-200 mt-2">Numer faktury sprzedaży:</span>
                    <input type="text" v-model="documentData.createdFor.invoice" :class="[documentData.createdFor.errors.invoice ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'focus:border-indigo-500 focus:ring-indigo-500 border-slate-300', 'block w-full rounded-md  shadow-sm sm:text-sm']" placeholder="np.: FA001/LA/ES/24" />
                    <span class="block text-sm font-medium leading-6 text-slate-200 mt-2">Data faktury:</span>
                    <VueDatePicker placeholder="Wybierz datę faktury..." :format="dateFormat" auto-apply locale="pl" v-model="documentData.createdFor.date" :enable-time-picker="false" :clearable="false" @open="checkDate('open', 'delivery')" @closed="checkDate('closed', 'delivery')" @cleared="checkDate('cleared', 'delivery')" />
                    </div>
                    <div class="relative mt-6">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                        </div>
                    </div>
                </div>
                <div :class="[documentData.typeOfInvoice === 'korekta' ? '' : 'mt-2', 'block text-sm font-medium leading-6 text-slate-200']">Czas płatności faktury:</div>
                <select v-model="documentData.paymentDayAmount" @change="changePayentDate()" placeholder="---" id="invoicePaymentTime" name="invoicePaymentTime" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm">
                    <option v-for="days in 40" :key="days" :value="days">{{days}} dni</option>
                </select>
                <Listbox class="mt-2" as="div" v-model="pickedUser">
                    <div class="block text-sm font-medium text-slate-200">Firma:</div>
                    <div class="relative">
                    <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span class="flex items-center">
                        <span :aria-label="pickedUser.activeInvoices ? 'Aktywny' : 'Nieaktywny'" :class="[pickedUser.activeInvoices ? 'bg-green-400' : 'bg-gray-200', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" />
                        <span class="ml-3 block truncate">{{ pickedUser.company.name }}</span>
                        </span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>
                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ListboxOption @click.stop="changeCompanyOnInvoice(pickedUser)" as="template" v-for="person in usersList" :key="person.id" :value="person" v-slot="{ active, pickedUser }">
                                <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', !person.activeInvoices ? 'opacity-30 pointer-events-none' : '', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <div :class="['flex items-center']">
                                        <span :class="[person.activeInvoices ? 'bg-green-400' : 'bg-gray-200', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']" aria-hidden="true" />
                                        <span :class="[pickedUser ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                                        {{ person.company.name }}
                                        <span class="sr-only"> is {{ person.activeInvoices ? 'Aktywny' : 'Nieaktywny' }}</span>
                                        </span>
                                    </div>
                                    <span v-if="pickedUser" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                    </div>
                </Listbox>
                <div class="relative mt-4 mb-6">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                    </div>
                </div>
                <div>
                    <label for="comment" class="block text-sm font-medium leading-6 text-slate-200">Uwagi:</label>
                    <div v-tooltip="'Maksymalna ilość znaków: 120'">
                        <textarea v-model="documentData.note" name="note" id="note" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" maxlength="120" />
                    </div>
                </div>
                <button @click.prevent="invoiceGenerate(documentData.typeOfInvoice)" type="button" :disabled="documentData.transactions.length === 0" :class="[documentData.transactions.length === 0 ? 'pointer-events-none opacity-40 select-none' : '', 'w-full flex justify-center relative items-center gap-x-1.5 trasition-all duration-500 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md border border-indigo-700 hover:border-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm mt-4']">
                    <DocumentAddIcon class="-ml-0.5 h-5 w-5 text-white" aria-hidden="true" />
                    Utwórz {{documentData.typeOfInvoice === 'korekta' ? 'korektę' : 'fakturę'}} 
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import EmptyState from '../components/EmptyState.vue'
import { XIcon, PlusIcon, ReplyIcon } from '@heroicons/vue/outline'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { HomeIcon, CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid'
import { DocumentAddIcon } from '@heroicons/vue/outline'
import {db} from "@/firebase/gfbconf.js";
// import axios from 'axios';
import moment from 'moment'
import currency from 'currency.js'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios from 'axios';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: "createInvoice",
    components: {
        ReplyIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, CheckIcon, ChevronDownIcon, HomeIcon, DocumentAddIcon, XIcon, PlusIcon, EmptyState, VueDatePicker
    },
    data() {
        return {
            priceInPolish: "",
            loading: true,
            date: new Date(),
            periodDate: [],
            usersList: [],
            pickedUser: null,
            vatRates: {},
            products: [],
            errors: [],
            isPositionAdded: false,
            dbListener: null,
            documentData: {
                createdFor: {
                    invoice: '',
                    date: new Date(),
                    errors: {
                        invoice: false,
                        date: false
                    }
                },
                note: '',
                typeOfInvoice: 'standard',
                active: true,
                created: null,
                firstDownload: null,
                invoiceDeliveryDate: "",
                invoiceName: "",
                netValueOfInvoice: 0,
                overviewPdf: {
                    link: null,
                    ref: null
                },
                paid: false,
                paidAmount: 0,
                payerId: "",
                payerNIP: "",
                payerName: "",
                paymentDate: "",
                paymentDayAmount: "",
                pdf: {
                    link: null,
                    ref: null
                },
                period: 'Brak',
                receiver: "",
                testInvoice: false,
                transactions: [],
                valueOfInvoice: 0,
                vatValueOfInvoice: 0
            }
        }
    },
    methods: {
        periodDateFormat()
        {
            let date = '';
            let dateForFile = '';
            if(this.periodDate.length > 0)
            {
                date = `${moment(this.periodDate[0]).format('DD.MM.YYYY')} - ${moment(this.periodDate[1]).format('DD.MM.YYYY')}`
                dateForFile = `${moment(this.periodDate[0]).format('DD-MM-YYYY')} - ${moment(this.periodDate[1]).format('DD-MM-YYYY')}`
                this.documentData.period = dateForFile;
            }
            return date;
        },
        dateFormat()
        {
            let date = ''
            date = `${moment(this.documentData.createdFor.date).format('DD.MM.YYYY')}`
            return date;
        },
        async fetchProductTranslations()
        {
            try {
                this.products = [];
                const docRef = db.collection('ProductTranslations').doc('products');
                const doc = await docRef.get();
                if (doc.exists) {
                    const res = Object.entries(doc.data());
                    for(let i=0; i<res.length; i++){
                        this.products.push(res[i][1]);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        outsideList(indx, invoiceType, method)
        {
            if(invoiceType === 'standard')
            {
                if(method === 'add')
                {
                    this.documentData.transactions[indx].isSelected = false
                    this.documentData.transactions[indx].name = ''
                }
                else if(method === 'return')
                {
                    this.documentData.transactions[indx].isSelected = true
                    this.documentData.transactions[indx].name = ''
                }
            }
            else if(invoiceType === 'beforeKorekta')
            {
                if(method === 'add')
                {
                    this.documentData.transactions[indx].beforeCorrection.isSelected = false
                    this.documentData.transactions[indx].beforeCorrection.name = ''
                }
                else if(method === 'return')
                {
                    this.documentData.transactions[indx].beforeCorrection.isSelected = true
                    this.documentData.transactions[indx].beforeCorrection.name = ''
                }
            }
            else if(invoiceType === 'afterKorekta')
            {
                if(method === 'add')
                {
                    this.documentData.transactions[indx].afterCorrection.isSelected = false
                    this.documentData.transactions[indx].afterCorrection.name = ''
                }
                else if(method === 'return')
                {
                    this.documentData.transactions[indx].afterCorrection.isSelected = true
                    this.documentData.transactions[indx].afterCorrection.name = ''
                }
            };
        },
        calculatePositionValues(position) {
            if (this.documentData.typeOfInvoice === 'korekta') {
                position.grossUnitPrice = currency(currency((parseFloat(position.afterCorrection.grossUnitPrice) - parseFloat(position.beforeCorrection.grossUnitPrice)), {precision: 4}).value, {precision: 2}).value;
                position.grossValue = currency(currency((parseFloat(position.afterCorrection.grossValue) - parseFloat(position.beforeCorrection.grossValue)), {precision: 4}).value, {precision: 2}).value;
                position.netValue = currency(currency((parseFloat(position.afterCorrection.netValue) - parseFloat(position.beforeCorrection.netValue)), {precision: 4}).value, {precision: 2}).value;
                position.netUnitPrice = currency(currency((parseFloat(position.afterCorrection.netUnitPrice) - parseFloat(position.beforeCorrection.netUnitPrice)), {precision: 4}).value, {precision: 2}).value;
                position.vatValue = currency(currency((parseFloat(position.afterCorrection.vatValue) - parseFloat(position.beforeCorrection.vatValue)), {precision: 4}).value, {precision: 2}).value;
                position.quantity = parseFloat(position.afterCorrection.quantity) - parseFloat(position.beforeCorrection.quantity);
            }
        },
        checkForEmpty(position){
            if(this.documentData.typeOfInvoice === 'korekta'){
                if(position.afterCorrection.quantity.length === 0){
                    position.afterCorrection.quantity = 1
                    this.reCalculate(position, 'afterCorrection')
                }
                if(position.afterCorrection.netUnitPrice.length === 0){
                    position.afterCorrection.netUnitPrice = 0
                    this.reCalculate(position, 'afterCorrection')
                }
                if(position.afterCorrection.vatRate.length === 0){
                    position.afterCorrection.vatRate = 23
                    this.reCalculate(position, 'afterCorrection')
                }
                if(position.beforeCorrection.quantity.length === 0){
                    position.beforeCorrection.quantity = 1
                    this.reCalculate(position, 'beforeCorrection')
                }
                if(position.beforeCorrection.netUnitPrice.length === 0){
                    position.beforeCorrection.netUnitPrice = 0
                    this.reCalculate(position, 'beforeCorrection')
                }
                if(position.beforeCorrection.vatRate.length === 0){
                    position.beforeCorrection.vatRate = 23
                    this.reCalculate(position, 'beforeCorrection')
                }
            }
            if(this.documentData.typeOfInvoice === 'standard'){
                if(position.quantity.length === 0){
                    position.quantity = 1
                    this.reCalculate(position)
                }
                if(position.netUnitPrice.length === 0){
                    position.netUnitPrice = 0
                    this.reCalculate(position)
                }
                if(position.vatRate.length === 0){
                    position.vatRate = 23
                    this.reCalculate(position)
                }
            }
        },
        async generateStatement(type)
        {
            try {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Generowanie faktury...",
                    subheader: `Nie zamykaj okna.`,
                });
                if(this.documentData.transactions.length === 0)
                {
                    throw "Nie dodano żadnej pozycji do faktury.";
                };
                if(this.documentData.note.length === 0)
                {
                   this.documentData.note = "Brak uwag.";
                };
                let statementData = {
                    type: type,
                    documentData: this.documentData,
                    vatRates: this.vatRates,
                    priceInPolish: this.priceInPolish,
                    user: this.pickedUser,
                };
                if(this.$store.state.transactions.uninvoiced != null && this.$store.state.transactions.uninvoiced.length > 0)
                {
                    statementData.transactionData = this.$store.state.transactions.uninvoiced;
                };
                if(this.documentData.createdFor.invoice.length > 0)
                {
                    statementData.documentData.createdFor = {
                        invoice: this.documentData.createdFor.invoice,
                        date: moment(this.documentData.createdFor.date).unix()
                    }
                };
                const generate = await axios.post(`${this.$store.state.apiLink}/generateSingleStatement`, statementData);
                if(generate.data.success)
                {
                    this.periodDate = [];
                    this.documentData.createdFor.invoice = ""
                    this.documentData.createdFor.date = new Date()
                    this.vatRates = {}
                    this.priceInPolish = ""
                    this.documentData.netValueOfInvoice = 0
                    this.documentData.paidAmount = 0
                    this.documentData.valueOfInvoice = 0
                    this.documentData.vatValueOfInvoice = 0
                    this.documentData.transactions = []
                    this.isPositionAdded = false
                    this.errors = [];
                    setTimeout(() => {
                        this.$router.push('/dashboard/invoices')
                    }, 500);
                    setTimeout(() => {
                        this.$store.commit('resetGlobalLoader');
                    }, 1000);
                    setTimeout(() => {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Faktura została wygenerowana.",
                            subheader: "Plik PDF został zapisany na serwerze.",
                            success: true
                        }); 
                    }, 1500);
                }
                else
                {
                    throw "Wystąpił błąd podczas generowania faktury.";
                }
            } catch (error)
            {
                console.log(error);
                setTimeout(() => {
                    this.$store.commit('resetGlobalLoader');
                }, 1000);
                setTimeout(() => {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: error,
                        subheader: "Spróbuj ponownie.",
                        success: false
                    }); 
                }, 1500);
            }
        },
        async invoiceGenerate(type)
        {
            const errors = this.positionValidator(type);
            if(errors > 0)
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił błąd podczas generowania faktury.",
                    subheader: "Sprawdź czy wszystkie pola są wypełnione poprawnie.",
                    success: false
                });
                return;
            }
            await this.generateStatement(type)
        },
        positionValidator(type)
        {
            let errorsAmount = 0;
            this.resetErrors(type);

            if(this.documentData.transactions.length === 0)
            {
                this.isPositionAdded = true;
                errorsAmount++;
            }
            if(this.periodDate.length === 0)
            {
                this.documentData.createdFor.errors.date = true;
                errorsAmount++;
            };

            if(type === 'standard')
            {
                for(let i=0; i<this.documentData.transactions.length; i++)
                {
                    if(this.documentData.transactions[i].name.length === 0)
                    {
                        this.errors[i].name = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].quantity === 0)
                    {
                        this.errors[i].quantity = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].unit.length === 0)
                    {
                        this.errors[i].unit = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].vatRate < 0)
                    {
                        this.errors[i].vatRate = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].netUnitPrice <= 0)
                    {
                        this.errors[i].netUnitPrice = true;
                        errorsAmount++;
                    }
                }
            }
            else if(type === 'korekta')
            {
                if(this.documentData.createdFor.invoice.length === 0)
                {
                    this.documentData.createdFor.errors.invoice = true;
                    errorsAmount++;
                };
                for(let i=0; i<this.documentData.transactions.length; i++)
                {
                    if(this.documentData.transactions[i].unit.length === 0)
                    {
                        this.errors[i].unit = true;
                        errorsAmount++;
                    }
                    // beforeCorrection
                    if(this.documentData.transactions[i].beforeCorrection.name.length === 0)
                    {
                        this.errors[i].beforeCorrection.name = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].beforeCorrection.quantity === 0)
                    {
                        this.errors[i].beforeCorrection.quantity = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].beforeCorrection.unit.length === 0)
                    {
                        this.errors[i].beforeCorrection.unit = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].beforeCorrection.vatRate < 0)
                    {
                        this.errors[i].beforeCorrection.vatRate = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].beforeCorrection.netUnitPrice <= 0)
                    {
                        this.errors[i].beforeCorrection.netUnitPrice = true;
                        errorsAmount++;
                    }
                    // afterCorrection
                    if(this.documentData.transactions[i].afterCorrection.name.length === 0)
                    {
                        this.errors[i].afterCorrection.name = true;
                        errorsAmount++;
                    }
                    // if(this.documentData.transactions[i].afterCorrection.quantity === 0)
                    // {
                    //     this.errors[i].afterCorrection.quantity = true;
                    //     errorsAmount++;
                    // }
                    if(this.documentData.transactions[i].afterCorrection.unit.length === 0)
                    {
                        this.errors[i].afterCorrection.unit = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].afterCorrection.vatRate < 0)
                    {
                        this.errors[i].afterCorrection.vatRate = true;
                        errorsAmount++;
                    }
                    if(this.documentData.transactions[i].afterCorrection.netUnitPrice <= 0)
                    {
                        this.errors[i].afterCorrection.netUnitPrice = true;
                        errorsAmount++;
                    }
                }
            }

            return errorsAmount;
        },
        resetErrors(type)
        {
            this.isPositionAdded = false;
            for(let i=0; i<this.documentData.transactions.length; i++)
            {
                if(type === 'standard')
                {
                    this.errors[i].name = false;
                    this.errors[i].quantity = false;
                    this.errors[i].unit = false;
                    this.errors[i].vatRate = false;
                    this.errors[i].netUnitPrice = false;
                }
                else if(type === 'korekta')
                {
                    this.errors[i].unit = false;
                    this.errors[i].beforeCorrection.name = false;
                    this.errors[i].beforeCorrection.quantity = false;
                    this.errors[i].beforeCorrection.unit = false;
                    this.errors[i].beforeCorrection.vatRate = false;
                    this.errors[i].beforeCorrection.netUnitPrice = false;
                    this.errors[i].afterCorrection.name = false;
                    this.errors[i].afterCorrection.quantity = false;
                    this.errors[i].afterCorrection.unit = false;
                    this.errors[i].afterCorrection.vatRate = false;
                    this.errors[i].afterCorrection.netUnitPrice = false;
                }
            }
            this.documentData.createdFor.errors.invoice = false;
            this.documentData.createdFor.errors.date = false;
        },
        handleKeyPress(event, position, field, type) {
            if(type === undefined){
                if(field === 'netUnitPrice'){
                    if ((event.charCode >= 48 && event.charCode <= 57)) {
                    return true;
                    } else if (event.charCode == 44 || event.charCode == 46) {
                        event.preventDefault();
                        if (!String(position[field]).includes('.')) {
                            position.netUnitPrice = (position.netUnitPrice + '.').replace('..', '.');
                        }
                    } else {
                    event.preventDefault();
                    }
                }
                if(field === 'quantity'){
                    if ((event.charCode >= 48 && event.charCode <= 57)) {
                    return true;
                    } else if (event.charCode == 44 || event.charCode == 46) {
                        event.preventDefault();
                        if (!String(position[field]).includes('.')) {
                            position.quantity = (position.quantity + '.').replace('..', '.');
                        }
                    } else {
                    event.preventDefault();
                    }
                }
            }else{
                if(field === 'netUnitPrice'){
                    if ((event.charCode >= 48 && event.charCode <= 57)) {
                    return true;
                    } else if (event.charCode == 44 || event.charCode == 46) {
                        event.preventDefault();
                        if (!String(position[type][field]).includes('.')) {
                            position[type][field] = (position[type][field] + '.').replace('..', '.');
                        }
                    } else {
                    event.preventDefault();
                    }
                }
                if(field === 'quantity'){
                    if ((event.charCode >= 48 && event.charCode <= 57)) {
                    return true;
                    } else if (event.charCode == 44 || event.charCode == 46) {
                        event.preventDefault();
                        if (!String(position[type][field]).includes('.')) {
                            position[type][field] = (position[type][field] + '.').replace('..', '.');
                        }
                    } else {
                    event.preventDefault();
                    }
                }
            }
        },
        log(log){
            console.log(log)
        },
        grossPriceInWord() {
            let grossPrice = Math.abs(this.documentData.valueOfInvoice); // Use Math.abs to get the absolute value
            let zloty = Math.floor(grossPrice);
            let grosze = currency(currency((grossPrice - zloty) * 100, {precision: 4}).value, {precision: 2}).value;

            let wynikZloty = this.convertToWords(zloty);
            let wynikGrosze = this.convertToWords(grosze);

            if (zloty === 0) {
                wynikZloty = "zero złotych";
            } else if (zloty === 1) {
                wynikZloty += ' złoty';
            } else if (zloty > 1 && zloty < 5) {
                wynikZloty += ' złote';
            } else {
                wynikZloty += ' złotych';
            }

            if (grosze === 0) {
                wynikGrosze = "zero groszy";
            } else if (grosze === 1) {
                wynikGrosze += ' grosz';
            } else if (grosze > 1 && grosze < 5) {
                wynikGrosze += ' grosze';
            } else {
                wynikGrosze += ' groszy';
            }

            if (zloty % 10 > 1 && zloty % 10 < 5 && (zloty % 100 < 10 || zloty % 100 > 20)) {
                wynikZloty = wynikZloty.replace('złotych', 'złote');
            }

            if (grosze % 10 > 1 && grosze % 10 < 5 && (grosze % 100 < 10 || grosze % 100 > 20)) {
                wynikGrosze = wynikGrosze.replace('groszy', 'grosze');
            }

            this.priceInPolish = (this.documentData.valueOfInvoice < 0 ? 'minus ' : '') + wynikZloty + ' ' + wynikGrosze;
        },
        convertToWords(amount) {
            let grossPrice = Math.abs(amount); // Use Math.abs to get the absolute value
            const jednosci = ['', 'jeden', 'dwa', 'trzy', 'cztery', 'pięć', 'sześć', 'siedem', 'osiem', 'dziewięć'];
            const nastki = ['', 'jedenaście', 'dwanaście', 'trzynaście', 'czternaście', 'piętnaście', 'szesnaście', 'siedemnaście', 'osiemnaście', 'dziewiętnaście'];
            const dziesiatki = ['', 'dziesięć', 'dwadzieścia', 'trzydzieści', 'czterdzieści', 'pięćdziesiąt', 'sześćdziesiąt', 'siedemdziesiąt', 'osiemdziesiąt', 'dziewięćdziesiąt'];
            const setki = ['', 'sto', 'dwieście', 'trzysta', 'czterysta', 'pięćset', 'sześćset', 'siedemset', 'osiemset', 'dziewięćset'];
            const tysiac = ['tysiąc', 'tysiące', 'tysięcy'];
            const milion = ['milion', 'miliony', 'milionów'];

            let wynik = '';
            let j = Math.floor(grossPrice % 10);
            let n = Math.floor((grossPrice % 100 - j) / 10);
            let d = Math.floor((grossPrice % 1000 - 10 * n - j) / 100);
            let t = Math.floor((grossPrice % 10000 - 100 * d - 10 * n - j) / 1000);
            let tt = Math.floor((grossPrice % 100000 - 1000 * t - 100 * d - 10 * n - j) / 10000);
            let tm = Math.floor((grossPrice % 1000000 - 10000 * tt - 1000 * t - 100 * d - 10 * n - j) / 100000);
            let tmn = Math.floor(grossPrice / 1000000);

            if (n === 1 && j > 0) {
                wynik = nastki[j];
            } else {
                wynik = dziesiatki[n] + ' ' + jednosci[j];
            }

            wynik = setki[d] + ' ' + wynik;

            if (tt === 1 && t > 0) {
                wynik = nastki[t] + ' ' + tysiac[2] + ' ' + wynik;
            } else {
                if (tt > 0) {
                    if (t > 0) {
                        if (t > 1 && t < 5) {
                            wynik = dziesiatki[tt] + ' ' + jednosci[t] + ' ' + tysiac[1] + ' ' + wynik;
                        } else {
                            wynik = dziesiatki[tt] + ' ' + jednosci[t] + ' ' + tysiac[2] + ' ' + wynik;
                        }
                    } else {
                        wynik = dziesiatki[tt] + ' ' + tysiac[2] + ' ' + wynik;
                    }
                } else if (t > 0) {
                    if (t === 1) {
                        wynik = jednosci[t] + ' ' + tysiac[0] + ' ' + wynik;
                    } else if (t > 1 && t < 5) {
                        wynik = jednosci[t] + ' ' + tysiac[1] + ' ' + wynik;
                    } else {
                        wynik = jednosci[t] + ' ' + tysiac[2] + ' ' + wynik;
                    }
                }
            }

            if (tm > 0) {
                wynik = setki[tm] + ' ' + wynik;
            }

            if (tmn > 0) {
                if (tmn === 1) {
                    wynik = jednosci[tmn] + ' ' + milion[0] + ' ' + wynik;
                } else if (tmn > 1 && tmn < 5) {
                    wynik = jednosci[tmn] + ' ' + milion[1] + ' ' + wynik;
                } else {
                    wynik = jednosci[tmn] + ' ' + milion[2] + ' ' + wynik;
                }
            }

            return wynik.trim();
        },
        async changeTypeOfInvoice(){
            this.$store.commit('setGlobalLoader',{
                show: true,
                head: "Zmieniam typ fakury..",
                subheader: `Nie zamykaj okna.`,
                success: false
            });
            this.isPositionAdded = false
            this.errors = [];
            this.periodDate = [];
            this.documentData.createdFor.invoice = "";
            this.documentData.createdFor.date = new Date();
            this.documentData.invoiceName = ""
            this.documentData.transactions = []
            await this.pickInvoiceNumber()
            await this.calcTotal()
            setTimeout(() => {
                this.$store.commit('resetGlobalLoader');
            }, 1000);
        },
        async pickInvoiceNumber(){
            let currentYear = moment().format("YYYY")

            if(this.dbListener !== null){
                this.dbListener()
            };
            this.dbListener = db.collection('InvoiceGenerator').doc('invoiceNumber')
            .onSnapshot((querySnapshot) => {
                let numbersData = querySnapshot.data()
                if(this.documentData.typeOfInvoice === 'standard'){
                    let lastInvoiceNumber = null
                    if(numbersData[`${currentYear}`] !== undefined){
                        lastInvoiceNumber = numbersData[`${currentYear}`]
                    }else{
                        lastInvoiceNumber = 1
                    }
                    let currentInvoiceNumber = lastInvoiceNumber
                    let invoiceNumberToName = ""
                    if(currentInvoiceNumber.toString().length === 1){
                        invoiceNumberToName = "00" + currentInvoiceNumber.toString()
                    }
                    else if(currentInvoiceNumber.toString().length === 2){
                        invoiceNumberToName = "0" + currentInvoiceNumber.toString()
                    }
                    else{
                        invoiceNumberToName = currentInvoiceNumber.toString()
                    }
                    let currentYearForInvoiceName = moment().format("YY")
                    this.documentData.invoiceName = `FA${invoiceNumberToName}/LA/ES/${currentYearForInvoiceName}`
                } else if(this.documentData.typeOfInvoice === "korekta"){
                    let lastCorrectionNumber = null
                    if(numbersData[`K${currentYear}`] !== undefined){
                        lastCorrectionNumber = numbersData[`K${currentYear}`]
                    }else{
                        lastCorrectionNumber = 1
                    }
                    let currentCorrectionNumber = lastCorrectionNumber
                    let correctionNumberToName = ""
                    if(currentCorrectionNumber.toString().length === 1){
                        correctionNumberToName = "00" + currentCorrectionNumber.toString()
                    }
                    else if(currentCorrectionNumber.toString().length === 2){
                        correctionNumberToName = "0" + currentCorrectionNumber.toString()
                    }
                    else{
                        correctionNumberToName = currentCorrectionNumber.toString()
                    }
                    let currentYearForCorrectionName = moment().format("YY")
                    this.documentData.invoiceName = `KOR${correctionNumberToName}/LA/ES/${currentYearForCorrectionName}`
                }
            });
        },
        changePayentDate(){
            this.documentData.paymentDate = moment(this.documentData.invoiceDeliveryDate, "DD.MM.YYYY").add(this.documentData.paymentDayAmount, 'days').format("DD.MM.YYYY")
        },
        checkDate(val, type)
        {
            this.documentData.invoiceDeliveryDate = moment(this.date).format("DD.MM.YYYY")
        },
        changeCompanyOnInvoice(user){
            this.documentData.payerId = user.id
            this.documentData.receiver = user.id
            this.documentData.payerNIP = user.company.nip
            this.documentData.payerName = user.company.name
            this.documentData.paymentDayAmount = user.invoicePaymentTime
            this.documentData.paymentDate = moment(this.documentData.invoiceDeliveryDate, "DD.MM.YYYY").add(this.documentData.paymentDayAmount, 'days').format("DD.MM.YYYY")
        },
        async downloadUsers(){
            let usersDocs = await db.collection("UsersData").orderBy("company.name", 'asc').get()
            for (let i = 0; i < usersDocs.docs.length; i++) {
                const element = usersDocs.docs[i];
                this.usersList.push(element.data())
            }
            if(this.$store.state.transactions.user == null)
            {
                this.pickedUser = this.usersList[0]
                this.changeCompanyOnInvoice(this.pickedUser)
            }
            else
            {
                this.pickedUser = this.$store.state.transactions.user
                this.changeCompanyOnInvoice(this.pickedUser)
            }
        },
        createVatTable(){
            this.vatRates = {}
            for(let i = 0; i < this.documentData.transactions.length; i++) {
                let position = this.documentData.transactions[i]
                if(this.vatRates[parseFloat(position.vatRate)] === undefined){
                    this.vatRates[parseFloat(position.vatRate)] = {
                        rateGrossValue: parseFloat(position.grossValue),
                        rateNetValue: parseFloat(position.netValue),
                        rateVatValue : parseFloat(position.vatValue)
                    }
                }else{
                    this.vatRates[parseFloat(position.vatRate)].rateGrossValue += parseFloat(position.grossValue)
                    currency(currency(this.vatRates[parseFloat(position.vatRate)].rateGrossValue, {precision: 4}).value, {precision: 2}).value
                    this.vatRates[parseFloat(position.vatRate)].rateNetValue += parseFloat(position.netValue)
                    currency(currency(this.vatRates[parseFloat(position.vatRate)].rateNetValue, {precision: 4}).value, {precision: 2}).value
                    this.vatRates[parseFloat(position.vatRate)].rateVatValue += parseFloat(position.vatValue)
                    currency(currency(this.vatRates[parseFloat(position.vatRate)].rateVatValue, {precision: 4}).value, {precision: 2}).value
                }
            }
        },
        async calcTotal(){
            this.documentData.valueOfInvoice = this.bruttoTotal()
            this.documentData.vatValueOfInvoice = this.vatTotal()
            this.documentData.netValueOfInvoice = this.nettoTotal()
            this.createVatTable()
            this.grossPriceInWord()
        },
        nettoTotal() {
        let nettoTotal = 0
        for(let i = 0; i < this.documentData.transactions.length; i++) {
            let position = this.documentData.transactions[i]
            nettoTotal += position.netValue
        }
        return nettoTotal
        },
        vatTotal() {
        let vatTotal = 0
        for(let i = 0; i < this.documentData.transactions.length; i++) {
            let position = this.documentData.transactions[i]
            vatTotal += position.vatValue
        }
        return vatTotal
        },
        bruttoTotal() {
        let bruttoTotal = 0
        for(let i = 0; i < this.documentData.transactions.length; i++) {
            let position = this.documentData.transactions[i]
            bruttoTotal += position.grossValue
        }
        return bruttoTotal
        },
        reCalculate(position, field){
            if(field === undefined){
                if(position.quantity.length === 0 || position.quantity === 0){
                    position.grossUnitPrice = 0
                    position.grossValue = 0
                    position.netValue = 0
                    position.vatValue = 0
                }else if(position.netUnitPrice.length === 0){
                    position.grossUnitPrice = 0
                    position.grossValue = 0
                    position.netValue = 0
                    position.vatValue = 0
                }else if(position.vatRate.length === 0){
                    position.grossUnitPrice = 0
                    position.grossValue = 0
                    position.netValue = 0
                    position.vatValue = 0
                }else{
                    position.grossUnitPrice = currency(currency((parseFloat(position.netUnitPrice) * (parseFloat(position.vatRate/100) + 1)), {precision: 4}).value, {precision: 2}).value;
                    position.grossValue = currency(currency((position.grossUnitPrice * parseFloat(position.quantity)), {precision: 4}).value, {precision: 2}).value
                    position.netValue = currency(currency((position.grossValue / (parseFloat(position.vatRate/100) * 100 + 100) * 100), {precision: 4}).value, {precision: 2}).value
                    position.netUnitPrice = currency(currency((position.netValue / parseFloat(position.quantity)), {precision: 4}).value, {precision: 2}).value
                    position.vatValue = currency(currency((position.grossValue - position.netValue), {precision: 4}).value, {precision: 2}).value
                }
            }else{
                if(position[field].quantity.length === 0 || position[field].quantity === 0){
                    position[field].grossUnitPrice = 0
                    position[field].grossValue = 0
                    position[field].netValue = 0
                    position[field].vatValue = 0
                }else if(position[field].netUnitPrice.length === 0){
                    position[field].grossUnitPrice = 0
                    position[field].grossValue = 0
                    position[field].netValue = 0
                    position[field].vatValue = 0
                }else if(position[field].vatRate.length === 0){
                    position[field].grossUnitPrice = 0
                    position[field].grossValue = 0
                    position[field].netValue = 0
                    position[field].vatValue = 0
                }else{
                    position[field].grossUnitPrice = currency(currency((parseFloat(position[field].netUnitPrice) * (parseFloat(position[field].vatRate/100) + 1)), {precision: 4}).value, {precision: 2}).value
                    position[field].grossValue = currency(currency((position[field].grossUnitPrice * parseFloat(position[field].quantity)), {precision: 4}).value, {precision: 2}).value
                    position[field].netValue = currency(currency((position[field].grossValue / (parseFloat(position[field].vatRate/100) * 100 + 100) * 100), {precision: 4}).value, {precision: 2}).value
                    position[field].netUnitPrice = currency(currency((position[field].netValue / parseFloat(position[field].quantity)), {precision: 4}).value, {precision: 2}).value
                    position[field].vatValue = currency(currency((position[field].grossValue - position[field].netValue), {precision: 4}).value, {precision: 2}).value
                }
                this.calculatePositionValues(position)
            }
            this.calcTotal()
        },
        addPosition() {
            this.isPositionAdded = false;
            let position = {
                isSelected: true,
                discount: '',
                grossUnitPrice: 0,
                grossValue: 0,
                gtu: "",
                index: this.documentData.transactions.length+1,
                name: "",
                netUnitPrice: 0,
                uniqueTransactionId: '',
                productCode: '',
                productGroupId: '',
                netValue: 0,
                quantity: 1,
                transactionDateUnix: 0,
                unit: "",
                vatRate: 23,
                vatValue: 0,
            }
            let error = {
                name: false,
                quantity: false,
                unit: false,
                vatRate: false,
                netUnitPrice: false,
            };
            if(this.documentData.typeOfInvoice === "korekta"){
                error.beforeCorrection = {
                    name: false,
                    quantity: false,
                    unit: false,
                    vatRate: false,
                    netUnitPrice: false,
                }
                error.afterCorrection = {
                    name: false,
                    quantity: false,
                    unit: false,
                    vatRate: false,
                    netUnitPrice: false,
                }
                position.beforeCorrection = {
                    isSelected: true,
                    discount: '',
                    grossUnitPrice: 0,
                    grossValue: 0,
                    gtu: "",
                    name: "",
                    uniqueTransactionId: '',
                    productCode: '',
                    productGroupId: '',
                    netUnitPrice: 0,
                    netValue: 0,
                    quantity: 1,
                    unit: "",
                    vatRate: 23,
                    vatValue: 0,
                }
                position.afterCorrection = {
                    isSelected: true,
                    discount: '',
                    grossUnitPrice: 0,
                    grossValue: 0,
                    gtu: "",
                    name: "",
                    uniqueTransactionId: '',
                    productCode: '',
                    productGroupId: '',
                    netUnitPrice: 0,
                    netValue: 0,
                    quantity: 1,
                    unit: "",
                    vatRate: 23,
                    vatValue: 0,
                }
            }
            this.errors.push(error)
            this.documentData.transactions.push(position)
            this.calcTotal()
        },
        addUninvoicedPositions()
        {
            let transactions = this.$store.state.transactions.uninvoiced;
            for(let i=0; i<transactions.length; i++)
            {
                let position = {
                    isSelected: false,
                    discount: '',
                    grossUnitPrice: transactions[i].WbTankGrossUnitPrice,
                    grossValue: transactions[i].WbTankGrossValue,
                    gtu: "",
                    index: i+1,
                    name: transactions[i].ProductName,
                    netUnitPrice: transactions[i].WbTankNetUnitPrice,
                    netValue: transactions[i].WbTankNetValue,
                    quantity: transactions[i].Quantity,
                    UnitPriceInInvoiceCurrency: transactions[i].UnitPriceInInvoiceCurrency,
                    transactionDateUnix: moment(transactions[i].TransactionDate + ' ' + transactions[i].TransactionTime, "YYYYMMDD h:mm:ss").unix(),
                    uniqueTransactionId: transactions[i].uniqueTransactionId,
                    SiteGroupName: transactions[i].SiteGroupName,
                    unit: transactions[i].ProductCode === '20' || transactions[i].ProductCode === '22' || transactions[i].ProductCode === '24' || transactions[i].ProductCode === '30' || transactions[i].ProductCode === '33' || transactions[i].ProductCode === '38'  ? 'L' : '',
                    vatRate: transactions[i].VATRate * 100,
                    vatValue: transactions[i].WbTankVATValue,
                    SiteCode: transactions[i].SiteCode,
                    SiteName: transactions[i].SiteName,
                    productCode: transactions[i].ProductCode,
                    productGroupId: transactions[i].ProductGroupId,
                };
                this.documentData.transactions.push(position);
                this.errors.push({
                    name: false,
                    quantity: false,
                    unit: false,
                    vatRate: false,
                    netUnitPrice: false,
                });
            }
            this.calcTotal()
        },
        removePosition(positionIndex, type) {
            this.documentData.transactions.splice(positionIndex, 1)
            this.errors.splice(positionIndex, 1)
            // create for loop to change index of all positions
            for(let i = 0; i < this.documentData.transactions.length; i++) {
                if(this.documentData.transactions[i].index > positionIndex){
                    this.documentData.transactions[i].index -= 1
                }
            }
            this.calcTotal()
        },
    },
    async mounted(){
        if(this.$store.state.transactions != null && this.$store.state.transactions.uninvoiced.length > 0)
        {
            this.addUninvoicedPositions();
        }
        this.loading = true
        // this.date = new Date();
        this.documentData.invoiceDeliveryDate = moment(this.date).format("DD.MM.YYYY")
        this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Pobieram dane użytkowników..",
            subheader: `Nie zamykaj okna.`,
            success: false
        });
        await this.fetchProductTranslations()
        await this.downloadUsers()
        this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Pobieram numer faktury..",
            subheader: `Nie zamykaj okna.`,
            success: false
        });
        await this.pickInvoiceNumber()
        // this.addPosition()
        this.$store.commit('resetGlobalLoader');
        this.loading = false
    },
    computed: {
        datePickerClass() {
            return this.documentData.createdFor.errors.date ? 'date-picker-border-while-error' : '';
        },
    },
    
    beforeUnmount() { 
        if(this.dbListener !== null){
            this.dbListener()
        }
    }
}

</script>
<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

    .date-picker-border-while-error {
        border: 2px solid;
        border-color: rgba(239, 68, 68);
        border-radius: 5px;
    }
</style>